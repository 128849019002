import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import RBContext from './../RBContext';
import { useTranslation } from "react-i18next";

const TableRow = (battleInfo, user, index) => {
  if(user){
    if(battleInfo){
      return (
      <div className="table-row medium" key={index}>
        <div className="table-column">
          <span className="mobile-title">No. </span>{index}
        </div>
        <div className="table-column">
          <div className="user-address">
            <p><span className="mobile-title">Battle: </span>#{battleInfo.get("Rooster1TokenId")} vs #{battleInfo.get("Rooster2TokenId")}</p>
          </div>
        </div>
        <div className="table-column centered padded-medium">
          <p className="battle-win"><span className="mobile-title">Winner: </span>{battleInfo.get("Rooster2Win") ? "#"+battleInfo.get("Rooster2TokenId") : "#"+battleInfo.get("Rooster1TokenId")}</p>
        </div>
        <div className="table-column centered padded-medium">
          <p><span className="mobile-title">Fight at: </span>{new Date(battleInfo.get("BattleTimestamp")).toLocaleDateString() + " " + new Date(battleInfo.get("BattleTimestamp")).toLocaleTimeString()}</p>
        </div>
      </div>);
    }
  }
}

function LeaderboardHistories(props){
  const rbContext = useContext(RBContext);

  let [histories, setHistories] = useState([]);

  let [loading, setLoading] = useState(true);
  const { Moralis, user } = useMoralis();
  let { wallet } = useParams();
  const { t, i18n } = useTranslation();

  const getHistories = async () => {
      const params =  {
        user: wallet,
        lang: i18n.language
      };

      try {
          var results = await Moralis.Cloud.run('admin_getTournamentHistories', params);
          console.log(results);
          setHistories(results);
          setLoading(false);

      } catch (error) {
          const code = error.code;
          const message = error.message;
          console.log(code + ':' + message);
      }
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          getHistories();
        }
        fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { web3 } = rbContext;

  return(
    <>
      <h2 id="leaderboard-heading">Battle Histories of: {wallet}</h2>
      <h3 id="leaderboard-updated">Last 50 battles of this wallet</h3>
      <p className="align-left block link"><a href="https://roosterbattle.substack.com/p/rooster-battle-heavyweight-championship" target="_blank">Check out rewards for this tournament</a></p>
      <div className="table table-leaderboard tournament-1">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">Index</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">Battle Info</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">Winner</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">DateTime</p>
          </div>
        </div>
        <div className="table-body">
          {(histories) ?
            histories.map((item, index) => TableRow(item, user, index + 1)) : ''
          }
          {!histories &&
            <div className="centered paragraph white no-battles">The battle histories for this wallet is not available</div>
          }
        </div>
    </div>
    { loading &&

      <div className="centered paragraph white no-battles">loading data...</div>
    }
    </>
  )
}

export default LeaderboardHistories;