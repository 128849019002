import React, { useState, useEffect, useContext } from 'react';
import Extra from './Extra';
import RBContext from './RBContext';
import { useMoralis } from "react-moralis";
import ClaimBattles from './components/superadmin/ClaimBattles';
import Dashboard from './components/superadmin/Dashboard';
import DashboardGold from './components/superadmin/DashboardGold';

import ResetReward from './components/superadmin/ResetReward';
import Fix6000 from './components/superadmin/Fix6000';

import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";

function SuperAdmin(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [roosterId, setRoosterId] = useState(0);
  let [roosterData, setRoosterData] = useState(false);
  let [userInfo, setUserinfo] = useState(false);
  let [loading, setLoading] = useState(false);
  let [activeStake5, setActiveStakes5] = useState([]);

  const syncData = async () => {
    if(roosterId){
      var params = {ids: roosterId};
      
      try{
        setLoading(true);
        var message =  await Moralis.Cloud.run('sync_rooster_data', params);
        alert(message);
        setLoading(false);
      } catch(err){
        setLoading(false);
        alert(err.message);
      }      
    }
  }

  const fetchData = async () => {
    if(roosterId){
      try{
        setLoading(true);
        var obj = await Moralis.Cloud.run('admin_getRoosterData', {id: roosterId});
        console.log(obj);
        setRoosterData(obj);
        setLoading(false);
      } catch(err){
        alert(err.message);
        setLoading(false);
      }      
    }
  }

  const reviveRooster = async() => {
    if(roosterId){
      var params = {id: roosterId};
      
      try{
        setLoading(true);
        var message =  await Moralis.Cloud.run('admin_reviveRooster', params);
        alert("New stamina: " + message);
        setLoading(false);
      } catch(err){
        setLoading(false);
        alert(err.message);
      }      
    }
  }

  const getUserInfo = async() => {
    if(roosterId){
      try{
        setLoading(true);
        var obj = await Moralis.Cloud.run('admin_getUserinfo', {wallet: roosterId});
        console.log(obj);
        obj.total = await nftContract.methods.balanceOf(roosterId).call();
        setUserinfo(obj);
        setLoading(false);
        console.log(obj);
      } catch(err){
        alert(err.message);
        setLoading(false);
      }      
    }
  }

  const getPool5Info = async(evt)=>{
    if(roosterId){
      try{
        setLoading(true);
        

        var stakes = await RBPool5Contract.methods.getUserStakingIndexes(roosterId).call();
        var activeStake5 = [];
        if(stakes.length){
          for(var i = 0; i < stakes.length; i++){
            var theStake = await RBPool5Contract.methods.getUserStakingByIndex(stakes[i]).call();
            console.log(theStake);
            if(theStake[2] == "0"){
                var obj = {};
                obj.id = stakes[i];
                obj.amount =  theStake[0] / 1000000000000000000;
                obj.timestamp = parseInt(theStake[1]);
                obj.date = new Date(obj.timestamp * 1000);
                activeStake5.push(obj);
            }
          }
        }
        console.log(activeStake5);
        setActiveStakes5(activeStake5);
        setLoading(false);
        evt.stopPropagation();
        evt.preventDefault();
        return false;
      } catch(err){
        alert(err.message);
        setLoading(false);
      }      
    }
  }

  useEffect(()=>{
    Extra.updateContentGridPos();
    
  }, [user, rbContext]);

  if (!rbContext) {
    return null;
}

let { nftContract, RBPool5Contract } = rbContext;

let adminWallets = ['0x198DEdd8A9B67716A90e67D6D9570f64088A3377',
'0x3509C2252Dac59cf311bCFA7aD9d77281c3A290e',
'0x6dF6A26E15Fb01bBf9B7D9ffaE32109b9FF369e1',
'0x929AEab6BC18ab3EB7b34DDbB74D9130460eA393',    
'0xE55735Dda7358f63bf2d7cF33812F034518Ad600',
'0xD8fcC79Ed7D268D43B6Cc162953fe3252AE1bDc8',
'0x68829B0Ad9B54591E30e8AD6298a1b7964ad475F',
'0xd8d38692e85f923b470293b2b1D0c1fd2c06C6a6',
'0x4c88C367F18a0E558C64d0293b8D9BE86f762BeD',
'0x28fa36453e8421440D786B26eEe42722E5e4fa44',
'0xA683bFc75A088aa5DCAFa673beE8d3d8e903C639',
'0xC99Cd8Ab5257657e4b9CB971632170c85B436821'];

adminWallets = adminWallets.map(wallet => wallet.toLowerCase());

if(!user || !adminWallets.includes(user.get('ethAddress'))){
  return '';
}

  return (
      <div className="content-grid" id="superadminpanel">
        <div className="section-banner">
          <p className="section-banner-title">Super Admin Area</p>
          <p className="section-banner-text"></p>
        </div>
          <Routes>
            <Route path="/fix" element={<Fix6000/>} />
            <Route path="/resetreward" element={<ResetReward/>} />
            <Route path="/dashboardgold" element={<DashboardGold/>} />
            <Route path="/battles" element={<ClaimBattles/>} />
            <Route path="*" element={<><h3>Enter Rooster ID or Wallet address</h3>
              <input id="roosterID" type="text" onChange={event => setRoosterId(event.target.value)}/>
              
                    <div className="form-row split">
                      <div className="form-item">
                        <button className="button btn-block" onClick={syncData}>{loading ? "Loading..." : "Sync NFT Data"}</button>
                      </div>
                      <div className="form-item">
                        <button className="button btn-block" onClick={fetchData}>{loading ? "Loading..." : "Fetch NFT Data"}</button>
                      </div>
                      <div className="form-item">
                        <button className="button btn-block" onClick={reviveRooster}>{loading ? "Loading..." : "Revive Rooster"}</button>
                      </div>
                      <div className="form-item">
                        <button className="button btn-block" onClick={getUserInfo}>{loading ? "Loading..." : "Fetch Wallet Data"}</button>
                      </div>
                      <div className="form-item">
                        <button className="button btn-block" onClick={getPool5Info}>{loading ? "Loading..." : "RICE-BNB Pool Info"}</button>
                      </div>
                    </div>
              
              {roosterData && 
              <>
                <div className="adminPanel">
                    <h4>Server Data</h4>
                    <p>Attack: {roosterData.server.attack.join(',')}</p>
                    <p>Defense: {roosterData.server.defense.join(',')}</p>
                    <p>Type: {roosterData.server.type}</p>
                    <p>Dojo Status: {roosterData.server.dojo ? "In Dojo" : "No"}</p>
                    <p>Camping Status: {roosterData.server.camping ? "Camping" : "No"}</p>                
                    <p>Lastfeed: {roosterData.server.lastfeed} / Stamina: {parseInt(roosterData.server.lastfeed) < (Date.now() / 1000 - 3600*60) ? 0 : ((1 - ((Date.now() / 1000) - roosterData.server.lastfeed) / 3600 / 60))}</p>                
                    <div>Last feeding: {roosterData.server.feedings.map((feed,index)=>{return <p key={"feed-"+index}><br/>Item: {feed.get("ItemType")}, quantity: {feed.get("ItemQuantity")}, timestamp: {feed.get("createdAt").toLocaleDateString() + " " + feed.get("createdAt").toLocaleTimeString()}</p>})}</div>
                </div>
                <div className="adminPanel">
                  <h4>Contract Data</h4>
                  <p>Attack: {roosterData.contract.attack.join(',')}</p>
                  <p>Defense: {roosterData.contract.defense.join(',')}</p>
                  <p>Type: {roosterData.contract.type}</p>
                  <p>Dojo Status: {roosterData.contract.dojo != "0" ? "In Dojo" : "No"}</p>             
                </div>
              </>
            }
            {
              userInfo && 
              <div className="adminPanel">
                  <h4>User Data</h4>
                  <p>Number of Rooster: {userInfo.roosters.length}</p>
                  <p>Rooster IDs: {userInfo.roosters.map((item)=>(item.id + ","))}</p>
                  <p>Number of Skills: {userInfo.skills.length}</p>
                  <p>Skill IDs: {userInfo.skills.map((item)=>(item.id + ","))}</p>
                  <p>Total NFTs (on BSC): {userInfo.total}</p> 
                  <p>On-chain Items: {userInfo.gameitems.map((item, index) => ("Item #" + (index+1) + ": " + item +", "))}</p>  
                  <p>InGame Items: {userInfo.ingames.map((item, index) => ("Item #" + (item.itemType) + ": " + item.remaining +", "))}</p>  
                      
                  <p>View F12 (console) to see detail NFT status</p>        
                </div>
            }
            {activeStake5.length && 
            <div className='adminPanel'>
              <h4>Active Staking Data</h4>
              {
                activeStake5.map((stake, index) => {
                  return <>
                    <p key={"stake-"+index}>Date: {stake.date.toLocaleDateString()} - Amount: {stake.amount} BNB-RICE</p>
                  </>
                })
              }
            </div>
            }</>} />
          </Routes>
      </div>
    )
}
export default SuperAdmin;