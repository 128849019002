import React, { useEffect, useState, Suspense } from 'react';
import './Main.css';

import MainPage from './MainPage';
import Extra from './Extra';
import RBContext from './RBContext';
import { useMoralis } from 'react-moralis';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Moralis from 'moralis-v1';
import Web3 from 'web3';
import Config from './Config';
	
function Main() {
	let [RBContextValue, setRBContext] = useState();
	let { isAuthenticated, user, isWeb3Enabled } = useMoralis();

	useEffect(() => {
		async function setupRBContextValue() {
			if (window.ethereum != null) {
				if(!isWeb3Enabled){
					await Moralis.enableWeb3({ provider: 'metamask' });
				}
				
				const web3 = new Web3(Web3.givenProvider);
				
				let isCorrectNetwork = web3.utils.hexToNumber(window.ethereum.chainId) == process.env.REACT_APP_WEB3_PROVIDER_CHAIN_ID;
			
				setRBContext({
					web3,
					isCorrectNetwork,
					riceContract: new web3.eth.Contract(Config.REACT_APP_RICETOKEN_ABI, process.env.REACT_APP_RICETOKEN_ADDRESS),
					RBGOLD_CONTRACT: new web3.eth.Contract(Config.REACT_APP_IERC20_ABI, process.env.REACT_APP_RB_GOLD_ADDRESS),
					BUSD_CONTRACT: new web3.eth.Contract(Config.REACT_APP_IERC20_ABI, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'),
					nftContract: process.env.REACT_APP_NFTCONTRACT_ADDRESS == "__NA__" ? false : new web3.eth.Contract(Config.REACT_APP_NFTCONTRACT_ABI, process.env.REACT_APP_NFTCONTRACT_ADDRESS),
					marketContract: new web3.eth.Contract(Config.REACT_APP_MARKETCONTRACT_ABI, process.env.REACT_APP_MARKETCONTRACT_ADDRESS),
					RBMARKETGAMEITEM_CONTRACT:new web3.eth.Contract(Config.REACT_APP_MARKETGAMEITEM_CONTRACT_ABI, process.env.REACT_APP_MARKETGAMEITEM_CONTRACT_ADDRESS),
					RBMARKETHEN_CONTRACT:new web3.eth.Contract(Config.REACT_APP_RBMARKETPLACE_HEN_ABI, process.env.REACT_APP_RBMARKETPLACE_HEN_ADDRESS),
					RBMARKETEGG_CONTRACT:new web3.eth.Contract(Config.REACT_APP_RB_MARKETEGG_ABI, process.env.REACT_APP_RB_MARKETEGG_ADDRESS),
					battlefieldContract: new web3.eth.Contract(Config.REACT_APP_BATTLE_FIELDCONTRACT_ABI, process.env.REACT_APP_BATTLEFIELDCONTRACT_ADDRESS),
					gameitemContract: process.env.REACT_APP_GAMEITEMCONTRACT_ADDRESS == "__NA__" ? false : new web3.eth.Contract(Config.REACT_APP_GAMEITEMCONTRACT_ABI, process.env.REACT_APP_GAMEITEMCONTRACT_ADDRESS),
					RBEGG_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_EGG_ABI, process.env.REACT_APP_RB_EGG_ADDRESS),
					presaleTicketContract: new web3.eth.Contract(Config.REACT_APP_RBPRESALETICKET_ABI, process.env.REACT_APP_RBPRESALETICKET_ADDRESS),
					airdropCNContract: new web3.eth.Contract(Config.REACT_APP_AIRDROPCHINESECONTRACT_ABI, process.env.REACT_APP_AIRDROPCHINESECONTRACT_ADDRESS),
					minterContract: new web3.eth.Contract(Config.REACT_APP_RB_MINTERV4_ABI, process.env.REACT_APP_RB_MINTERV4_ADDRESS),
					dojoContract: process.env.REACT_APP_DOJO_ADDRESS == "__NA__" ? false : new web3.eth.Contract(Config.REACT_APP_DOJO_ABI, process.env.REACT_APP_DOJO_ADDRESS),
					RBPool4Contract:new web3.eth.Contract(Config.REACT_APP_POOL4_ABI, process.env.REACT_APP_POOL4_ADDRESS),
					RBPool5Contract:new web3.eth.Contract(Config.REACT_APP_POOL5_ABI, process.env.REACT_APP_POOL5_ADDRESS),
					RBPool6Contract:new web3.eth.Contract(Config.REACT_APP_POOL6_ABI, process.env.REACT_APP_POOL6_ADDRESS),
					RBPool7Contract:new web3.eth.Contract(Config.REACT_APP_POOL7_ABI, process.env.REACT_APP_POOL7_ADDRESS),
					RBPool8Contract:new web3.eth.Contract(Config.REACT_APP_POOL8_ABI, process.env.REACT_APP_POOL8_ADDRESS),
					RBPool9Contract:new web3.eth.Contract(Config.REACT_APP_POOL9_ABI, process.env.REACT_APP_POOL9_ADDRESS),
					RBPool10Contract:new web3.eth.Contract(Config.REACT_APP_POOL10_ABI, process.env.REACT_APP_POOL10_ADDRESS),
					RBPool11Contract:new web3.eth.Contract(Config.REACT_APP_POOL11_ABI, process.env.REACT_APP_POOL11_ADDRESS),					
					LPCakeContract: new web3.eth.Contract(Config.REACT_APP_IERC20_ABI, process.env.REACT_APP_LPCAKE_ADDRESS),
					RBRewardContract: new web3.eth.Contract(Config.REACT_APP_RBREWARD_ABI, process.env.REACT_APP_RBREWARD_ADDRESS),
					RBRewardRiceContract: new web3.eth.Contract(Config.REACT_APP_RBREWARDRICE_ABI, process.env.REACT_APP_RBREWARDRICE_ADDRESS),
					RBGOLDREWARD_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_GOLD_REWARD_ABI, process.env.REACT_APP_RB_GOLD_REWARD_ADDRESS),
					RBGOLD_VIPREWARD_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_GOLD_REWARD_ABI, process.env.REACT_APP_RB_GOLD_VIPREWARD_ADDRESS),
					RBHenMinterContract: new web3.eth.Contract(Config.REACT_APP_RBHEN_MINTER_ABI, process.env.REACT_APP_RBHEN_MINTER_ADDRESS),
					RBHenContract: new web3.eth.Contract(Config.REACT_APP_RBHEN_ABI, process.env.REACT_APP_RBHEN_ADDRESS),
					RBItemRewardContract: new web3.eth.Contract(Config.REACT_APP_RBBATTLE_ITEM_REWARD_ABI, process.env.REACT_APP_RBBATTLE_ITEM_REWARD_ADDRESS),
					RBBREEDING_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RBBREEDING_ABI, process.env.REACT_APP_RBBREEDING_ADDRESS),
					RBBREEDINGF1_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_BREEDINGF1_ABI, process.env.REACT_APP_RB_BREEDINGF1_ADDRESS),
					RBBANK_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_BANK_ABI, process.env.REACT_APP_RB_BANK_ADDRESS),
					RBCOLLECTOR_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_COLLECTOR_ABI, process.env.REACT_APP_RB_COLLECTOR_ADDRESS),
					RBBLACKSMITH_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_BLACKSMITHV4_ABI, process.env.REACT_APP_RB_BLACKSMITHV4_ADDRESS),
					RBGOLD_INVENTORY_CONTRACT: new web3.eth.Contract(Config.REACT_APP_RB_GOLD_INVENTORY_ABI, process.env.REACT_APP_RB_GOLD_INVENTORY_ADDRESS),
					RBGAMEITEM_SHOP: new web3.eth.Contract(Config.REACT_APP_RB_GAMEITEM_SHOP_ABI, process.env.REACT_APP_RB_GAMEITEM_SHOP_ADDRESS)
				});
				
			}
		}

		setupRBContextValue();

	}, [user, isAuthenticated])

	useEffect(()=>{
		Extra.updateContentGridPos();
	});

	return (
			<RBContext.Provider value={RBContextValue}>
				<I18nextProvider i18n={ i18n }>
					<Suspense fallback={null}>
						<MainPage />
					</Suspense>
				</I18nextProvider>
			</RBContext.Provider>
	);
}

export default Main;