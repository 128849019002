import React, {useEffect, useContext, useState} from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";
import { getGameItemName } from "./../../utils/RBUtils";

function NFTEnhancementItem(props){
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation();

  let [loading, setLoading] = useState(true);
  let [packageItem, setPackage] = useState([]);

  const { user } = useMoralis();

  const buyNow = async () => {
    setLoading(true);

    const currentUser = user.get('ethAddress');
    try{
      var price = parseInt(packageItem.RicePrice);

      const allowance = await riceContract.methods.allowance(currentUser, process.env.REACT_APP_RB_GAMEITEM_SHOP_ADDRESS).call();

      const balanceOfUser = await riceContract.methods.balanceOf(currentUser).call();

      let okToGo = false;

      if(allowance == 0 || (parseInt(allowance.substring(0, allowance.length - 18)) < price)) {
        okToGo = false;

        alert(t("error.rice_approval"));

        var result = await riceContract.methods.approve(process.env.REACT_APP_RB_GAMEITEM_SHOP_ADDRESS, new web3.utils.BN(web3.utils.toWei((price * 100) + ""))).send({from: currentUser});

        if(result.status){
          okToGo = true;
        }
      } else {
        okToGo = true;
      }

      if(okToGo){
        if(balanceOfUser > 0 && parseInt(balanceOfUser.substring(0, balanceOfUser.length - 18)) > price){
          
          await RBGAMEITEM_SHOP.methods.buyGameItem(props.count, props.package, 1).send({from: currentUser});
          alert(t("error.offer_bought"));
        } else {
          alert(t("error.not_enough_rice"));
        }
      }
      setLoading(false);
    } catch(err){
      alert(err.message);
      setLoading(false);
      return false;
    }

  }

  const buyButton = () => {
    if(!user){
      return <><a className="button loading btn-block btn-buyPresale centered" href="#">{t("error.please_connect_wallet")}</a></>;
    }
    if(loading){
      return (<a className="button loading btn-block btn-buyPresale centered" href="#">{t("general.processing")}</a>);
    }

    return (<a className="button btn-block btn-buyPresale" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();buyNow();}}>{t("general.button_purchase")}</a>);
  }

  useEffect( () => {
    async function fetchData(){
      if(user){
        var pack = await RBGAMEITEM_SHOP.methods.ItemPackages(props.package).call();
        setPackage(pack);
        setLoading(false);
      }
    }

    fetchData();    
  }, [rbContext, user]);

  if (!rbContext) {
      return null;
  }

  let { web3, riceContract, RBGOLD_CONTRACT, RBGAMEITEM_SHOP } = rbContext;

  return (
    (packageItem && packageItem.Count > 0) &&
      <>
        <div className="product-preview">
          <div>
            <img src={process.env.REACT_APP_HOME_URL + "assets/gameitems/gameitem_" + props.package + ".png"} width="186px" alt={getGameItemName(props.package)}/>
          </div>
          <div className="product-preview-info">
            <p className="text-sticker"><span className="highlighted">RICE</span> {packageItem.RicePrice * parseInt(props.count)}</p>
            <p className="product-preview-title">{t("shop.gameitem_count", {count: parseInt(packageItem.Count) * props.count, name: getGameItemName(props.package)})}</p>
            <p className="product-preview-category">{t("gameitem.effect_enhancement")}</p>
            <p className="product-preview-text">{t("shop.enhancement_item_desc")}</p>
          </div>
          <div className="product-preview-meta">{buyButton()}</div>
        </div>
      </>
    );
}
export default NFTEnhancementItem;
