import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";
import ProfileSidebar from './ProfileSidebar';
import { getBalanceNumber, showError, getRoosterName, getGameItemName } from './../../utils/RBUtils';
import axios from 'axios';

function GameManagement(props){
  const { t, i18n } = useTranslation();  
  const rbContext = useContext(RBContext);
  const { Moralis, user, web3 } = useMoralis();
  let [loading, setLoading] = useState(false);
  let [item1Count, setItem1Count] = useState(0);
  let [item2Count, setItem2Count] = useState(0);
  let [item3Count, setItem3Count] = useState(0);
  let [item4Count, setItem4Count] = useState(0);
  let [item5Count, setItem5Count] = useState(0);
  let [item6Count, setItem6Count] = useState(0);
  let [item7Count, setItem7Count] = useState(0);
  let [item8Count, setItem8Count] = useState(0);
  let [item9Count, setItem9Count] = useState(0);
  let [rgoldbalance, setRgoldBalance] = useState(0);

  let [itemLoader1Count, setItemLoader1Count] = useState(0);
  let [itemLoader2Count, setItemLoader2Count] = useState(0);
  let [itemLoader3Count, setItemLoader3Count] = useState(0);
  let [itemLoader4Count, setItemLoader4Count] = useState(0);

  let [rgoldload, setRGOLDsLoader] = useState(0);

  let [legendary80IDs, setLegendary80IDs] = useState("");
  let [legendaryId, setLegendaryId] = useState('');

  let [standardRoosters, setStandardRoosters] = useState({
    type1: [],
    type2: [],
    type3: [],
    type4: [],
    type5: [],
    type6: []
  });
  let [standardRoostersLoading, setStandardRoostersLoading] = useState(true);

  const loadAssets = async () => {
    if(user){
      var wallet = user.get("ethAddress");
      var NFTs = [];

      
        const options = {params: {'nftAddress':process.env.REACT_APP_GAMEITEMCONTRACT_ADDRESS, 'owner': wallet}};
        NFTs = await axios.get("https://coming.io/api/nft.php", options).catch(function(error){
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      
      for(var i = 0; i < NFTs.data.length; i++){    
        if(NFTs.data[i].token_id == "1"){
          setItem1Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "2"){
          setItem2Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "3"){
          setItem3Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "4"){
          setItem4Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "5"){
          setItem5Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "6"){
          setItem6Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "7"){
          setItem7Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "8"){
          setItem8Count(parseInt(NFTs.data[i].amount));
        }else if(NFTs.data[i].token_id == "9"){
          setItem9Count(parseInt(NFTs.data[i].amount));
        }
      }

      var balance = await RBGOLD_CONTRACT.methods.balanceOf(wallet).call();
      balance = getBalanceNumber(balance);
      setRgoldBalance(balance);
    }  
  }

  async function loadStandardRoosters(){
    var currentUserAddress = user.get("ethAddress");
    
    var myNFTs = [];
        
    let NFTs = [];

    try{
      
        const options = {params: {'nftAddress':process.env.REACT_APP_NFTCONTRACT_ADDRESS, 'owner': currentUserAddress}};
        NFTs = await axios.get("https://coming.io/api/nft.php", options).catch(function(error){
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);

          setStandardRoostersLoading(false);
          return false;
        });

    } catch(err){
      console.log(err);
      if(err.code == 141){
        // too many requests
        // do nothing. Let query try again
      } else {
        alert(err.error);
      }
      setStandardRoostersLoading(false);
      return false;
    }
                                        
    for(var i = 0; i < NFTs.data.length; i++) {
      myNFTs.push(parseInt(NFTs.data[i].token_id));
    }

    if(myNFTs.length > 0){
      var roosterData = await Moralis.Cloud.run('getRBDetailByIds', {ids: myNFTs,lang: i18n.language});
      
      var types = {
        type1: [],
        type2: [],
        type3: [],
        type4: [],
        type5: [],
        type6: []
      };
      for(var i = 0; i < roosterData.length; i++){
        if(roosterData[i].status == 'free' && roosterData[i].armors[0] == 0){
          switch(roosterData[i].type){
            case 1:
              types.type1.push(roosterData[i].id);
              break;
            case 2:
              types.type2.push(roosterData[i].id);
              break;
            case 3:
              types.type3.push(roosterData[i].id);
                break;
            case 4:
              types.type4.push(roosterData[i].id);
              break;
            case 5:
              types.type5.push(roosterData[i].id);
              break;
            case 6:
              types.type6.push(roosterData[i].id);
              break;      
          }
        }
      }

      setStandardRoosters(types);
      setStandardRoostersLoading(false);
    } else {
      setStandardRoostersLoading(false);
    }
  }

  useEffect( () => {
    async function init() {
      await loadAssets(); 
      loadStandardRoosters();
    }
    
    init();
  }, [rbContext, user]);

  const onTextChange = (id, value) => {
    if(id == "gameitem_loader_1"){
      setItemLoader1Count(parseInt(value));
    } else if(id == "gameitem_loader_2"){
      setItemLoader2Count(parseInt(value));
    } else if(id == "gameitem_loader_3"){
      setItemLoader3Count(parseInt(value));
    } else if(id == "gameitem_loader_4"){
      setItemLoader4Count(parseInt(value));
    } else if(id == "rgold_loader"){
      setRGOLDsLoader(parseInt(value));
    } else if(id == "legendary_burnt_ids"){
      setLegendary80IDs(value);
    } else if(id == "legendary_id"){
      setLegendaryId(parseInt(value));
    }
  }

  const loadIntoGame = async (type) => {
    if(!loading){

    
    if(itemLoader1Count > item1Count || itemLoader2Count > item2Count || itemLoader3Count > item3Count || itemLoader4Count > item4Count){
      alert(t("error.cannot_load_more_items"));
      return;
    }

    if(itemLoader1Count + itemLoader2Count + itemLoader3Count + itemLoader4Count == 0){
      alert(t("error.please_choose_at_least_1"));
      return;
    }
    try{
      setLoading(true);
      var userAddress = user.get("ethAddress");
      
      // get approval
      var approval = await gameitemContract.methods.isApprovedForAll(userAddress, process.env.REACT_APP_RB_MINTERV4_ADDRESS).call();
      if(!approval){
        alert(t("profile.gameitems_inventory_approval"));
        await gameitemContract.methods.setApprovalForAll(process.env.REACT_APP_RB_MINTERV4_ADDRESS, true).send({from: userAddress});
      }

      var items = [];
      var counts = [];

      if(itemLoader1Count > 0){
        items.push(1);
        counts.push(itemLoader1Count);
      }

      if(itemLoader2Count > 0){
        items.push(2);
        counts.push(itemLoader2Count);
      }

      if(itemLoader3Count > 0){
        items.push(3);
        counts.push(itemLoader3Count);
      }

      if(itemLoader4Count > 0){
        items.push(4);
        counts.push(itemLoader4Count);
      }
      
      var result = await minterContract.methods.loadGameitemtoInventory(items, counts, 0).send({from: userAddress});
      loadAssets();
      setLoading(false);
    } catch(err){
      alert(err.message);
      setLoading(false);
    }
  }
    
  }

  const loadRGOLDs = async() => {
    if(rgoldload && !loading){
      console.log("load", rgoldload);
      try{
        setLoading(true);
        var userAddress = user.get("ethAddress");

        var allowance = await RBGOLD_CONTRACT.methods.allowance(userAddress, process.env.REACT_APP_RB_GOLD_INVENTORY_ADDRESS).call();
        allowance = getBalanceNumber(allowance);
        console.log("Allowance", allowance);
        if(allowance < rgoldload){
          alert(t("error.rgold_approval_burn"));
          var result = await RBGOLD_CONTRACT.methods.approve(process.env.REACT_APP_RB_GOLD_INVENTORY_ADDRESS, new web3.utils.BN( (rgoldload * 100) + "000000000000000000")).send({from: userAddress});
        }

        if(rgoldload <= rgoldbalance){
          var result = await RBGOLD_INVENTORY_CONTRACT.methods.loadRGolds(rgoldload).send({from: userAddress});
          if(result.status){
            rgoldbalance -= rgoldload;
            setRgoldBalance(rgoldbalance);

            var result = await Moralis.Cloud.run("sync_rgold_inventory");
            console.log("Sync RGOLDs", result);
          }
        } else {
          alert(t("error.not_enough_rgolds"));
        }
          
        setLoading(false);
      } catch(err){
        showError(err);
        setLoading(false);
      }
    }
  }

  const goLegendary = async () => {
    if(!loading && legendaryId != 0){
      var wallet = user.get("ethAddress");

      setLoading(true);
      try{
        // check for valid ID
        var ids = legendary80IDs.split(",");

        if(ids.length != 80){
          alert(t("profile.gamemanagement_alert_need_80roosters"));
          setLoading(false);
          return;
        }

        var main_type = await nftContract.methods.getRBType(legendaryId).call();

        main_type = parseInt(main_type);

        ids.unshift(legendaryId);

        for(var i = 0; i < ids.length; i++){
          // is it really a rooster?
          var id = parseInt(ids[i]);
          var type = await nftContract.methods.getRBType(id).call();
          type = parseInt(type);
          if(type < 65){
            // is it of the same type?
            if(type != main_type){
              alert(t("profile.gamemanagement_alert_rooster_must_be_same_race", {id: id, race: getRoosterName(main_type)}));
              setLoading(false);
              return;
            }

            // is it standard rooster
            var armors = await nftContract.methods.getRBArmors(id).call();
            if(parseInt(armors[0]) != 0){
              alert(t("profile.gamemanagement_alert_rooster_is_not_standard", {id: id}));
              setLoading(false);
              return;
            }

            // is it your rooster
            
            var owner = await nftContract.methods.ownerOf(id).call();
            
            if(owner.toLowerCase() != wallet.toLowerCase()){
              alert(t("profile.gamemanagement_alert_rooster_is_not_yours", {id: id}));
              setLoading(false);
              return;
            }
          } else {
            alert(t("profile.gamemanagement_not_a_rooster", {id: id}));
            setLoading(false);
            return;
          }
        }

        // now check for RICE, RGOLD and Game Item
        
        var rice = await riceContract.methods.balanceOf(wallet).call();
        rice = getBalanceNumber(rice);
        if(rice < 2000){
          alert(t("profile.gamemanagement_need_rice_for_this_feature", {amount: 2000}));
            setLoading(false);
            return;
        }

        var allowance = await riceContract.methods.allowance(wallet, process.env.REACT_APP_RB_BLACKSMITHV4_ADDRESS).call();
        allowance = getBalanceNumber(allowance);
        if(allowance < 2000){
          alert(t("error.approval_rice"));
          var result = await riceContract.methods.approve(process.env.REACT_APP_RB_BLACKSMITHV4_ADDRESS, "20000000000000000000000").send({from: wallet});
          if(!result.status){
            setLoading(false);
            return;
          }
        }

        var gold = await RBGOLD_CONTRACT.methods.balanceOf(wallet).call();
        
        gold = getBalanceNumber(gold);
        if(gold < 40000){
          alert(t("profile.gamemanagement_need_gold_for_this_feature", {amount: 40000}));
            setLoading(false);
            return;
        }

        var allowance = await RBGOLD_CONTRACT.methods.allowance(wallet, process.env.REACT_APP_RB_BLACKSMITHV4_ADDRESS).call();
        allowance = getBalanceNumber(allowance);
        if(allowance < 40000){
          alert(t("error.rgold_approval_burn"));
          var result = await RBGOLD_CONTRACT.methods.approve(process.env.REACT_APP_RB_BLACKSMITHV4_ADDRESS, "400000000000000000000000").send({from: wallet});
          if(!result.status){
            setLoading(false);
            return;
          }
        }

        var gameitemType = 0;
        if(main_type == 2 || main_type == 3){
          gameitemType = 9;
        } else if(main_type == 5 || main_type == 6){
          gameitemType = 8;
        } else if(main_type == 1 || main_type == 4){
          gameitemType = 7;
        }

        if(gameitemType != 0){
          
          var gameitem = await gameitemContract.methods.balanceOf(wallet, gameitemType).call();
          if(gameitem < 1000){
            alert(t("profile.gamemanagement_need_items_for_this_feature", {amount: 1000, name: getGameItemName(gameitemType)}));
              setLoading(false);
              return;
          }

          if(window.confirm(t("profile.gamemanagement_golegend_confirmation", {ids: legendary80IDs, rooster: legendaryId, gameitem: getGameItemName(gameitemType)}))){
            
            var result = await RBBLACKSMITH_CONTRACT.methods.goLegendary(ids, 1).send({from: wallet});
            if(result.status){
              try{
                const newType = await Moralis.Cloud.run("syncRoosterType", {roosterId: legendaryId});

                alert(t("profile.gamemanagement_golegend_thanks", {id: legendaryId, type: getRoosterName(newType)}));

                setLegendary80IDs("");
                setLegendaryId(0);

                setLoading(false);
              } catch(err){
                alert(t("profile.gamemanagement_golegend_convert_synced_failed", {id: legendaryId}));
                console.log(err);
              }
            } else {
              alert(t("profile.alert_write_to_contract_failed"));
            }
          }

          
        } else {
          alert(t("profile.gamemanagement_golegend_invalid_rooster_race"));
              setLoading(false);
              return;
        }
      } catch(err) {
        showError(err);
        setLoading(false);
      }
    }
  }

  if (!rbContext) {
    return null;
  }

  let { gameitemContract, minterContract, RBGOLD_CONTRACT, RBGOLD_INVENTORY_CONTRACT, nftContract, riceContract, RBBLACKSMITH_CONTRACT } = rbContext;

  return (
      <div id="profileGame" className="grid grid-3-9 small-space">
          <div className="profileGame-sidebar">
            <ProfileSidebar />
          </div>
          <div className="account-hub-content">
            <div className="grid-column">
              <div className="widget-box">
                <p className="widget-box-title">{t("profile.gamemanagement_label_onchain_items")}</p>
                <div className="widget-box-content">
                  <form className="form">
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_1">{t("profile.gamemanagement_label_potion")}</label>
                          <input type="text" disabled="disabled" id="gameitem_1" value={item1Count} name="gameitem_1" onChange={()=>{}}/>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_2">{t("profile.gamemanagement_label_cricket")}</label>
                          <input type="text" disabled="disabled" id="gameitem_2" name="gameitem_2" value={item2Count} onChange={()=>{}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_3">{t("profile.gamemanagement_label_grasshopper")}</label>
                          <input type="text" disabled="disabled" id="gameitem_3" name="gameitem_3" value={item3Count} onChange={()=>{}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_4">{t("profile.gamemanagement_label_lizard")}</label>
                          <input type="text" disabled="disabled" id="gameitem_4" name="gameitem_4" value={item4Count} onChange={()=>{}} />
                        </div>
                      </div>
                      
                    </div>
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_5">{t("profile.gamemanagement_label_golden_order")}</label>
                          <input type="text" disabled="disabled" id="gameitem_5" value={item5Count} name="gameitem_5" onChange={()=>{}}/>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_6">{t("profile.gamemanagement_label_apricot_flowers")}</label>
                          <input type="text" disabled="disabled" id="gameitem_6" name="gameitem_6" value={item6Count} onChange={()=>{}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_7">{t("profile.gamemanagement_label_golden_feather")}</label>
                          <input type="text" disabled="disabled" id="gameitem_7" name="gameitem_7" value={item7Count} onChange={()=>{}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_8">{t("profile.gamemanagement_label_snow_ball")}</label>
                          <input type="text" disabled="disabled" id="gameitem_8" name="gameitem_8" value={item8Count} onChange={()=>{}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_9">{t("profile.gamemanagement_label_blackstone")}</label>
                          <input type="text" disabled="disabled" id="gameitem_9" name="gameitem_9" value={item9Count} onChange={()=>{}} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="widget-box-title">{t("profile.gamemanagement_label_rgolds")}: {t("profile.number_rgolds", {gold: rgoldbalance.toLocaleString()})}</p>
              </div>
            <div className="widget-box">
                <p className="widget-box-title">{t("profile.gamemanagement_description")}</p>
                <div className="widget-box-content">
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_loader_1">{t("profile.gamemanagement_label_potion")}</label>
                          <input className='numberbox' type="number" min="0" id="gameitem_loader_1" name="gameitem_loader_1" defaultValue="0" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_loader_2">{t("profile.gamemanagement_label_cricket")}</label>
                          <input className='numberbox' type="number" min="0" id="gameitem_loader_2" name="gameitem_loader_2" defaultValue="0" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}}/>
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_loader_3">{t("profile.gamemanagement_label_grasshopper")}</label>
                          <input className='numberbox' type="number" min="0" id="gameitem_loader_3" name="gameitem_loader_3" defaultValue="0" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}} />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="gameitem_loader_4">{t("profile.gamemanagement_label_lizard")}</label>
                          <input className='numberbox' type="number" min="0" id="gameitem_loader_4" name="gameitem_loader_4" defaultValue="0" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}}/>
                        </div>
                      </div>
                    </div>
                    <div id="footerAction" className="form-row split">
                      <button className="button primary" onClick={(evt)=> {evt.stopPropagation(); evt.preventDefault();loadIntoGame(0)}}>{loading ? t("general.loading") : t("profile.gamemanagement_load_into_game")}</button> 
                    </div>   
              </div>
              <hr className='separator light'></hr>
              <p className="widget-box-title">{t("profile.gamemanagement_loadrgolds_heading")}</p>
              <p>{t("profile.gamemanagement_loadrgolds_description")}</p>
                <div className="widget-box-content">
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="rgold_loader">{t("profile.gamemanagement_label_amount")}</label>
                          <input className='numberbox' style={{width:'150px'}} type="number" min="0" max={rgoldbalance} id="rgold_loader" name="rgold_loader" defaultValue="0" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}} />
                        </div>
                      </div>
                    </div>   
                    <div className='form-row'>
                      <button className="button primary btn-block" onClick={(evt)=> {evt.stopPropagation(); evt.preventDefault();loadRGOLDs()}}>{loading ? t("general.loading") : t("profile.gamemanagement_load_into_game")}</button>
                    </div>
              </div>
            </div>
            <div className="widget-box">
                <p className="widget-box-title">{t("profile.gamemanagement_golegend_heading")}</p>
                <p>{t("profile.gamemanagement_golegend_desc_1")}</p>
                <p>{t("profile.gamemanagement_golegend_desc_2")} <a href="https://roosterbattle.substack.com/p/introducing-the-enhancement-system">{t("profile.enhancement_system")}</a></p>
                <h6 style={{marginBottom:'20px'}}>{t("profile.gamemanagement_your_roosters")}</h6>
                {
                  standardRoostersLoading &&
                  <>{t("general.loading")}</>
                }
                {
                  !standardRoostersLoading && 
                  <>
                    <div id="game-standardroosters" className="grid grid-2-2-2-2-2-2">
                      <div >
                        <h6>{getRoosterName(1)}</h6>
                        { standardRoosters.type1.length > 0 && 
                          standardRoosters.type1.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type1.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                      <div >
                        <h6>{getRoosterName(2)}</h6>
                        { 
                          standardRoosters.type2.length > 0 && standardRoosters.type2.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type2.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                      <div >
                        <h6>{getRoosterName(3)}</h6>
                        {
                          standardRoosters.type3.length > 0 && standardRoosters.type3.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type3.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                      <div >
                        <h6>{getRoosterName(4)}</h6>
                        {
                          standardRoosters.type4.length > 0 && standardRoosters.type4.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type4.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                      <div >
                        <h6>{getRoosterName(5)}</h6>
                        {
                          standardRoosters.type5.length > 0 && standardRoosters.type5.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type5.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                      <div >
                        <h6>{getRoosterName(6)}</h6>
                        {
                          standardRoosters.type6.length > 0 && standardRoosters.type6.map((id)=>{
                            return <p key={id}>#{id}</p>;
                          })
                        }
                        {
                          standardRoosters.type6.length == 0 &&
                          <>
                          <p>--{t("general.none")}--</p>
                          </>
                        }
                      </div>
                    </div>
                  </>
                }
                <div className="widget-box-content">
                  <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="rgold_loader">{t("profile.gamemanagement_label_1")}</label>
                          <input style={{width:'100%'}} type="text" id="legendary_burnt_ids" name="legendary_burnt_ids" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}} value={legendary80IDs}/>
                          <p className="form-input-description">{t("profile.gamemanagement_desc_1")}</p>
                        </div>
                        <div className="form-input small active">
                          <label htmlFor="rgold_loader">{t("profile.gamemanagement_label_2")}</label>
                          <input style={{width:'100%'}} type="text" id="legendary_id" name="legendary_id" onChange={(evt)=>{onTextChange(evt.target.id, evt.target.value);}} value={legendaryId}/>
                          <p className="form-input-description">{t("profile.gamemanagement_desc_2")}</p>
                        </div>
                      </div>
                    </div>   
                    <div className='form-row'>
                      <button className="button primary btn-block" onClick={(evt)=> {evt.stopPropagation(); evt.preventDefault();goLegendary()}}>{loading ? t("general.loading") : t("profile.gamemanagement_golegend_button")}</button>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default GameManagement;