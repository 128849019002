import React, { useEffect } from 'react';
import Extra from './Extra';
import NFTListing from './components/marketplace/NFTListing';
import NFTItemDetail from './components/marketplace/NFTItemDetail';
import {  Routes,  Route } from "react-router-dom";
  import { useTranslation } from "react-i18next";

function Marketplace(props) {
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    Extra.updateContentGridPos();
  }); 

  return (
      <div className="content-grid">
        <div className="section-banner">
          <p className="section-banner-title">{t("marketplace.title")}</p>
          <p className="section-banner-text">{t("marketplace.subheading")}</p>
        </div>
        
        <Routes>
          <Route path=":tokenType/:tokenId" element={<><NFTItemDetail /></>} />
          <Route path="*" element={<><NFTListing /></>} />
        </Routes>
      </div>
    )
}
export default Marketplace;
