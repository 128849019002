import React, { useState } from 'react';
import { useMoralis } from "react-moralis";
import Moralis from 'moralis-v1';
import { useTranslation } from "react-i18next";

function ConnectBtn() {
    const { t, i18n } = useTranslation(); 
    //const { Moralis, isInitialized, isAuthenticated, isAuthenticating, authenticate, logout, user } = useMoralis();
    const { authenticate, enableWeb3, logout, isAuthenticated } = useMoralis();
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    /**
     * 1) Connect to a Evm
     * 2) Request message to sign using the Moralis Auth Api of moralis (handled on server)
     * 3) Login via parse using the signed message (verification handled on server via Moralis Auth Api)
     */
    const handleAuth = async (provider) => {
        try {
            setIsAuthenticating(true);

            // Enable web3 to get user address and chain
            await enableWeb3({ throwOnError: true, provider });
            
            const { account, chainId } = Moralis;

            if (!account) {
                throw new Error('Connecting to chain failed, as no connected account was found');
            }
            if (!chainId) {
                throw new Error('Connecting to chain failed, as no connected chain was found');
            }

            // Get message to sign from the auth api
            const { message } = await Moralis.Cloud.run('requestMessage', {
                address: account,
                chain: parseInt(chainId, 16),
                networkType: 'evm',
            });

            // Authenticate and login via parse
            await authenticate({
                signingMessage: message,
                throwOnError: true,
            });
        
        } catch (error) {
            console.log(error);
        } finally {
        setIsAuthenticating(false);
        }
    };

    if (!isAuthenticated) {
        if (!isAuthenticating) {
            return <div id="connectbtn" className="connect" onClick={() => handleAuth('metamask')}>
            {t("general.connect_wallet")}
        </div>;
        } else {
            return <div id="connectbtn" className="connecting">{t("general.connecting")}</div>
        }
    } else {
        return <div id="connectbtn" className="disconnect" onClick={() => logout()}>
          {t("general.disconnect_wallet")}
        </div>
    }
}

export default ConnectBtn;