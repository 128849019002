import React from 'react';
import PageLoader from './PageLoader';
import ChatWidget from './ChatWidget';
import NavigationWidget from './NavigationWidget';
import {
  Link, useLocation
} from "react-router-dom";
import ConnectBtn from './ConnectBtn';
import { useTranslation } from "react-i18next";

function Header(props) {
  let location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <>
      <NavigationWidget />
      <ChatWidget />
      <header className="header">
        <div className="header-actions">
          <div className="header-brand">
            <div className="logo">
              <img src={process.env.REACT_APP_HOME_URL + "img/logo_.png"} alt={t("general.title")}/>
            </div>
          </div>
        </div>
        <div className="header-actions" id="mobileburger">
          <div className="mobilemenu-trigger navigation-widget-mobile-trigger">
            <div className="burger-icon inverted">
              <div className="burger-icon-bar"></div>
              <div className="burger-icon-bar"></div>
              <div className="burger-icon-bar"></div>
            </div>
          </div>
          <nav className="navigation">
            <ul className="menu-main">
              <li className="menu-main-item">
                <a className="menu-main-item-link play" href="https://finance.roosterbattle.net/play/">{t("menu.play")}</a>
              </li>
              <li className="menu-main-item">
                <Link className={location.pathname == "/" ? "menu-main-item-link active" : "menu-main-item-link"} to="/">{t("menu.marketplace")}</Link>
              </li>
              <li className="menu-main-item">
                <Link className={location.pathname == "/shop" ? "menu-main-item-link active" : "menu-main-item-link"} to="/shop">{t("menu.shop")}</Link>
              </li>              
              <li className="menu-main-item">
                  <Link className={location.pathname == "/leaderboard" ? "menu-main-item-link active" : "menu-main-item-link"} to="/leaderboard">{t("menu.leaderboard")}</Link>
                </li>
              <li className="menu-main-item">
                <Link className={location.pathname == "/staking" ? "menu-main-item-link active" : "menu-main-item-link"} to="/staking">{t("menu.staking")}</Link>
              </li>
              
              <li className="menu-main-item">
                <Link className={location.pathname == "/profile" ? "menu-main-item-link active" : "menu-main-item-link"} to="/profile">{t("menu.profile")}</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-actions" id="networkconnect">
          <div className="action-item-wrap">

            <div className="action-item dark header-settings-dropdown-trigger">
              {window.ethereum != null && <ConnectBtn/>}
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default Header;
