import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { showError, getBalanceNumber } from './../../utils/RBUtils.js';
import { useTranslation } from "react-i18next";

function NFTTransfer(props){
    const rbContext = useContext(RBContext);
    const { t, i18n } = useTranslation();
    let [nftTransferWallet, setNftTransferWallet] = useState('');
    let [nftTransferIds, setNftTransferIds] = useState('');
    let [transferLoading, setTransferLoading] = useState(false);

    const { web3, user, Moralis } = useMoralis();

    const setInput = (val, input) => {
        if(input == "wallet"){
          setNftTransferWallet(val);
        } else if(input == "ids"){
          setNftTransferIds(val);
        }
      }
    
      const transfer = async () => {
        var currentUser = user.get("ethAddress");
    
        setTransferLoading(true);
    
        if(nftTransferWallet && nftTransferIds){
          var ids = nftTransferIds.split(",");
          ids.map((id)=>{id.trim()});

          if(ids.length < 3 || ids.length > 50){
            alert(t("profile.nft_transfer_min_max"));
          } else {
            var balanceOf = await nftContract.methods.balanceOf(currentUser).call();
            if(balanceOf - ids.length < 3){
              alert(t("profile.nft_transfer_requirement_3_nfts"));
            } else {
              var balance = await riceContract.methods.balanceOf(currentUser).call();
              balance = getBalanceNumber(balance);
              if(balance < 1000){
                alert(t("profile.nft_transfer_requirement_rice_holder"));
              } else {
                balance = await riceContract.methods.balanceOf(nftTransferWallet).call();
                balance = getBalanceNumber(balance);
                if(balance < 1000){
                  alert(t("profile.nft_transfer_requirement_rice_holder_2"));
                } else {
                    // BUSD balance
                    balance = await BUSD_CONTRACT.methods.balanceOf(currentUser).call();
                    balance = getBalanceNumber(balance);
                    if(balance < 10){
                        alert(t("profile.nft_transfer_fee"));
                    } else {
                        try{
                            var result = await RBCOLLECTOR_CONTRACT.methods.sendNFT(ids, nftTransferWallet).send({from: currentUser});
                            alert(t("profile.nft_transfer_ok"));
                          }catch(err){
                            showError(err);
                          }
                    }
                  
                }
              }
            }
          }
        } else {
          alert(t("profile.nft_transfer_missing_input"));
        }
    
        setTransferLoading(false);
      }

      const approveNFT = async ()=>{
        try{
            setTransferLoading(true);

            var currentAddress = user.get("ethAddress");

            var result = await nftContract.methods.setApprovalForAll(process.env.REACT_APP_RB_COLLECTOR_ADDRESS, true).send({from: currentAddress});
            
            if(result.status){
                setNFTApproved(true);
            }
            setTransferLoading(false);

        } catch(err){
            showError(err);
        }

        setTransferLoading(false);
      }

      const approveBUSD = async () => {
        try{
            setTransferLoading(true);

            var currentAddress = user.get("ethAddress");
            
            var result = await BUSD_CONTRACT.methods.approve(process.env.REACT_APP_RB_COLLECTOR_ADDRESS, web3.utils.toWei("1000")).send({from: currentAddress});
            
            if(result.status){
                setBUSDApproved(true);
            }

            setTransferLoading(false);

        } catch(err){
            showError(err);
        }

        setTransferLoading(false);
      }
    
    let [NFTApproved, setNFTApproved] = useState(false);
    let [BUSDApproved, setBUSDApproved] = useState(false);
    
    useEffect( () => {
        async function fetchData(){
            var currentAddress = user.get("ethAddress");
            var isApproved = await nftContract.methods.isApprovedForAll(currentAddress, process.env.REACT_APP_RB_COLLECTOR_ADDRESS).call();
            setNFTApproved(isApproved);
            
            var allowance = await BUSD_CONTRACT.methods.allowance(currentAddress, process.env.REACT_APP_RB_COLLECTOR_ADDRESS).call();
            allowance = getBalanceNumber(allowance);
            console.log("Allowance", allowance);
            if(allowance >= 10){
              setBUSDApproved(true);
            }
        }
        fetchData();
      }, [user, rbContext]
    );
  
    if (!rbContext) {
        return null;
    }

let { nftContract, riceContract, RBCOLLECTOR_CONTRACT, BUSD_CONTRACT} = rbContext;

    return (
        <div className='transfer form'>
            <h4>{t("profile.nft_transfer_note_heading")}</h4>
            <ul>
                <li>{t("profile.nft_transfer_note_1")}</li>
                <li>{t("profile.nft_transfer_note_2")}</li>
                <li>{t("profile.nft_transfer_note_3")}</li>
                <li>{t("profile.nft_transfer_note_4")}<a href={"https://bscscan.com/token/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"} target="_blank" style={{fontWeight:'bold'}}>10 BUSD</a>. {t("profile.nft_transfer_note_5")}</li>
            </ul>
            <div className="form-row split">
                <div className="form-item">
                <div className="form-input small active">
                    <label>{t("profile.nft_transfer_ids_to_transfer")}</label>
                    <input type="text" id="nfttransferids" onChange={event => setInput(event.target.value, "ids")}/>
                </div>
                </div>
                <div className="form-item">
                <div className="form-input small active"> 
                    <label>{t("profile.nft_transfer_wallet_to_receive")}</label>
                    <input type="text" id="nfttransfer-wallet" onChange={event => setInput(event.target.value, "wallet")}/>
                </div>
                </div>
            </div>
            <div className="form-row split">
                <div className="form-item">
                
                </div>
                <div className="form-item">
                    <p className='centered block'>
                        {
                            !NFTApproved &&
                            <span className="button btn-primary" style={{width: '160px', margin:'5px'}} onClick={approveNFT}>{(transferLoading ? t("general.processing") : t("profile.nft_transfer_approve_for_NFTs"))}</span>
                        }
                        {
                            !BUSDApproved &&
                            <span className="button btn-primary" style={{width: '160px', margin:'5px'}} onClick={approveBUSD}>{(transferLoading ? t("general.processing") : t("profile.nft_transfer_approve_for_BUSD"))}</span>
                        }
                        {
                            NFTApproved && BUSDApproved &&
                            <span className="button btn-primary" onClick={transfer}>{(transferLoading ? t("general.processing") : t("profile.nft_transfer_button"))}</span>
                        }   
                        
                    </p>
                </div>
                <div className="form-item">

                </div>
            </div>
            </div>
    );
}

export default NFTTransfer;