import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { useTranslation, Trans } from "react-i18next";

function UserStakes45(props) {
	const { t, i18n } = useTranslation(); 
  let [isLoading, setIsLoading] = useState(true);
  let [stakes4GameItems, setStakes4GameItems] = useState(0);
  let [stakes4TotalGameItems, setStakes4TotalGameItems] = useState(0);
  let [stakes4, setStakes4] = useState([]);

  let [stakes5GameItems, setStakes5GameItems] = useState(0);
  let [stakes5TotalGameItems, setStakes5TotalGameItems] = useState(0);
  let [stakes5, setStakes5] = useState([]);

  let [stakes6GameItems, setStakes6GameItems] = useState(0);
  let [stakes6TotalGameItems, setStakes6TotalGameItems] = useState(0);
  let [stakes6, setStakes6] = useState([]);

  let [stakes7GameItems, setStakes7GameItems] = useState(0);
  let [stakes7TotalGameItems, setStakes7TotalGameItems] = useState(0);
  let [stakes7, setStakes7] = useState([]);

  const { user } = useMoralis();
  
  const rbContext = useContext(RBContext);

  useEffect(() => {
	  async function initData(){
		if(user){
			var userAddr = user.get('ethAddress');
	
			setStakes4(props.stakes4);
			setStakes4GameItems(props.stakes4GameItems);
			if(props.stakes4.length){
				stakes4TotalGameItems = await RBPool4Contract.methods.getWithdrawableGameItems(userAddr).call();
				setStakes4TotalGameItems(stakes4TotalGameItems);
			}
			
			setStakes5(props.stakes5);
	
			setStakes5GameItems(props.stakes5GameItems);
			if(props.stakes5.length){
				stakes5TotalGameItems = await RBPool5Contract.methods.getWithdrawableGameItems(userAddr).call();
				setStakes5TotalGameItems(stakes5TotalGameItems);
			}
	
			setStakes6(props.stakes6);
	
			setStakes6GameItems(props.stakes6GameItems);
			if(props.stakes6.length){
				stakes6TotalGameItems = await RBPool6Contract.methods.getWithdrawableGameItems(userAddr).call();
				setStakes6TotalGameItems(stakes6TotalGameItems);
			}
	
			setStakes7(props.stakes7);
	
			setStakes7GameItems(props.stakes7GameItems);
			if(props.stakes7.length){
				stakes7TotalGameItems = await RBPool7Contract.methods.getWithdrawableGameItems(userAddr).call();
				setStakes7TotalGameItems(stakes7TotalGameItems);
			}
			
			setIsLoading(false);
		}
	  }

	  initData();
    
  }, [user, props, rbContext])

  const doRiceRedeem = async (stakeIndex) => {
	try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool4Contract.methods.redeem(stakeIndex).send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		var idx = -1;
		for(var i = 0; i < stakes4.length; i++){
			if(stakes4[i].id == stakeIndex){
				idx = i;
				break;
			}
		}

		if(idx >= 0){
			stakes4.splice(idx,1);
			setStakes4(stakes4);
		}
		
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doRice6Redeem = async (stakeIndex) => {
	try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool6Contract.methods.redeem(stakeIndex).send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		var idx = -1;
		for(var i = 0; i < stakes6.length; i++){
			if(stakes6[i].id == stakeIndex){
				idx = i;
				break;
			}
		}

		if(idx >= 0){
			stakes6.splice(idx,1);
			setStakes4(stakes6);
		}
		
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doGameItemRedeem = async () => {
	  try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool4Contract.methods.redeemGameItems().send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		setStakes4GameItems(0);
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doLPRedeem = async (stakeIndex) => {
	try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool5Contract.methods.redeem(stakeIndex).send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		var idx = -1;
		for(var i = 0; i < stakes5.length; i++){
			if(stakes5[i].id == stakeIndex){
				idx = i;
				break;
			}
		}

		if(idx >= 0){
			stakes5.splice(idx,1);
			setStakes5(stakes5);
		}
		
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doLP7Redeem = async (stakeIndex) => {
	try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool7Contract.methods.redeem(stakeIndex).send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		var idx = -1;
		for(var i = 0; i < stakes7.length; i++){
			if(stakes7[i].id == stakeIndex){
				idx = i;
				break;
			}
		}

		if(idx >= 0){
			stakes7.splice(idx,1);
			setStakes7(stakes7);
		}
		
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doGameItem5Redeem = async () => {
	  try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool5Contract.methods.redeemGameItems().send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		setStakes5GameItems(0);
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doGameItem6Redeem = async () => {
	  try{
		setIsLoading(true);
		var userAddr = user.get('ethAddress');
		var result = await RBPool6Contract.methods.redeemGameItems().send({from: userAddr});

		alert(t("staking.alert_redeem_ok"));
		setStakes6GameItems(0);
		setIsLoading(false);
	  } catch(err){
		alert(err.message);
		setIsLoading(false);
	  }
  }

  const doGameItem7Redeem = async () => {
	try{
	  setIsLoading(true);
	  var userAddr = user.get('ethAddress');
	  var result = await RBPool7Contract.methods.redeemGameItems().send({from: userAddr});

	  alert(t("staking.alert_redeem_ok"));
	  setStakes7GameItems(0);
	  setIsLoading(false);
	} catch(err){
	  alert(err.message);
	  setIsLoading(false);
	}
}
  
  if (!rbContext) {
    return;
  }


  let { RBPool4Contract, RBPool5Contract, RBPool6Contract, RBPool7Contract } = rbContext;

  return (
    <>
		{
			stakes4GameItems > 0 && 

			<div className="badge-item-stat">
								<p className="text-sticker">
									<svg className="text-sticker-icon icon-plus-small">
										<use xlinkHref="#svg-plus-small"></use>
									</svg>
									{t("staking.pool_45_user_1")}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_2")}</p>
								<p className="badge-item-stat-text">{t("staking.pool_45_user_3")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.pool_45_user_4")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{t("staking.pool_45_user_5", {withdrawed: stakes4TotalGameItems - stakes4GameItems, total: stakes4TotalGameItems})}
												</p>
											</div>
										</div>
									</div>
								</div>
								
								{
									!isLoading && stakes4GameItems > 0 && 
									<p className="badge-item-stat-text">
										<button className="button"  onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doGameItemRedeem();}}>{t("staking.pool_45_button_harvest", {remaining:stakes4GameItems,total:stakes4TotalGameItems})}</button>
									</p>
								}
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>

		}

		{
			stakes5GameItems > 0 && 

			<div className="badge-item-stat">
								<p className="text-sticker">
									<svg className="text-sticker-icon icon-plus-small">
										<use xlinkHref="#svg-plus-small"></use>
									</svg>
									{t("staking.pool_45_user_6")}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_7")}</p>
								<p className="badge-item-stat-text">{t("staking.pool_45_user_8")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.pool_45_user_4")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
												{t("staking.pool_45_user_12", {withdrawed: stakes5TotalGameItems - stakes5GameItems, total: stakes5TotalGameItems})}
												</p>
											</div>
										</div>
									</div>
								</div>
								
								{
									!isLoading && stakes5GameItems > 0 && 
									<p className="badge-item-stat-text">
										<button className="button"  onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doGameItem5Redeem();}}>{t("staking.pool_45_button_harvest", {remaining: stakes5GameItems, total: stakes5TotalGameItems})}</button>
									</p>
								}
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>

		}

		{
			stakes6GameItems > 0 && 

			<div className="badge-item-stat">
								<p className="text-sticker">
									<svg className="text-sticker-icon icon-plus-small">
										<use xlinkHref="#svg-plus-small"></use>
									</svg>
									{t("staking.pool_45_user_13")}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_14")}</p>
								<p className="badge-item-stat-text">{t("staking.pool_45_user_15")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.pool_45_user_4")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{t("staking.pool_45_user_5", {withdrawed: stakes6TotalGameItems - stakes6GameItems, total: stakes6TotalGameItems})}
												</p>
											</div>
										</div>
									</div>
								</div>
								
								{
									!isLoading && stakes6GameItems > 0 && 
									<p className="badge-item-stat-text">
										<button className="button"  onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doGameItem6Redeem();}}>{t("staking.pool_45_button_harvest", {remaining: stakes6GameItems, total: stakes6TotalGameItems})}</button>
									</p>
								}
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>

		}

{
			stakes7GameItems > 0 && 

			<div className="badge-item-stat">
								<p className="text-sticker">
										<svg className="text-sticker-icon icon-plus-small">
											<use xlinkHref="#svg-plus-small"></use>
										</svg>
										{t("staking.pool_7_user_1")}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_7_user_2")}</p>
								<p className="badge-item-stat-text">{t("staking.pool_7_user_3")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.pool_45_user_4")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{t("staking.pool_45_user_12", {withdrawed: stakes7TotalGameItems - stakes7GameItems, total: stakes7TotalGameItems})}
												</p>
											</div>
										</div>
									</div>
								</div>
								
								{
									!isLoading && stakes7GameItems > 0 && 
									<p className="badge-item-stat-text">
										<button className="button"  onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doGameItem7Redeem();}}>{t("staking.pool_45_button_harvest", {remaining: stakes7GameItems, total: stakes7TotalGameItems})}</button>
									</p>
								}
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>

		}

		{
			stakes4 && stakes4.map(
				(stake) => 
					 (
						<div key={stake.id} className="badge-item-stat">
								<p className="text-sticker">
										<svg className="text-sticker-icon icon-plus-small">
											<use xlinkHref="#svg-plus-small"></use>
										</svg>
										{t("staking.pool_45_user_16", {rice: parseInt(stake.amount.substring(0, stake.amount.length - 18)).toLocaleString()})}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_17")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.staked_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{stake.date.toLocaleDateString() + " " + stake.date.toLocaleTimeString()}
												</p>
											</div>
										</div>
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.redeem_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{new Date(stake.timestamp * 1000 + 7 * 86400000).toLocaleDateString() + " " + new Date(stake.timestamp * 1000 + 7 * 86400000).toLocaleTimeString()}
												</p>
											</div>
										</div>
									</div>
								</div>
								{
									// redeemable
									(!isLoading && (stake.timestamp + 7 * 86400) <= (Date.now() / 1000) ) && 
								<p className="badge-item-stat-text">
									<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doRiceRedeem(stake.id);}}>{t("staking.redeem_rices_")}</button>
								</p>	
								}
								
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>
					)				
			)
		}
		
		{
			stakes5 && stakes5.map(
				(stake) => 
					 (
						<div key={stake.id} className="badge-item-stat">
								<p className="text-sticker">
										<svg className="text-sticker-icon icon-plus-small">
											<use xlinkHref="#svg-plus-small"></use>
										</svg>
										{t("staking.pool_45_user_19",{amount: parseInt(stake.amount.substring(0, stake.amount.length - 18)).toLocaleString()})}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_20")}</p>
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.staked_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{stake.date.toLocaleDateString() + " " + stake.date.toLocaleTimeString()}
												</p>
											</div>
										</div>
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.redeem_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{new Date(stake.timestamp * 1000 + 30 * 86400000).toLocaleDateString() + " " + new Date(stake.timestamp + 7 * 86400).toLocaleTimeString()}
												</p>
											</div>
										</div>
									</div>
								</div>
								{
									// redeemable
									(!isLoading && (stake.timestamp + 30 * 86400) <= (Date.now() / 1000) ) && 
								<p className="badge-item-stat-text">
									<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doLPRedeem(stake.id);}}>{t("staking.redeem_cakelp_")}</button>
								</p>	
								}
								
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>
					)				
			)
		}

		{
			stakes6 && stakes6.map(
				(stake) => 
					 (
						<div key={stake.id} className="badge-item-stat">
								<p className="text-sticker">
										<svg className="text-sticker-icon icon-plus-small">
											<use xlinkHref="#svg-plus-small"></use>
										</svg>
										{t("staking.pool_45_user_16", {rice: parseInt(stake.amount.substring(0, stake.amount.length - 18)).toLocaleString()})}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_21")}</p>
						
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.staked_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{stake.date.toLocaleDateString() + " " + stake.date.toLocaleTimeString()}
												</p>
											</div>
										</div>
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.redeem_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{new Date(stake.timestamp * 1000 + 7 * 86400000).toLocaleDateString() + " " + new Date(stake.timestamp * 1000 + 7 * 86400000).toLocaleTimeString()}
												</p>
											</div>
										</div>
									</div>
								</div>
								{
									// redeemable
									(!isLoading && (stake.timestamp + 7 * 86400) <= (Date.now() / 1000) ) && 
								<p className="badge-item-stat-text">
									<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doRice6Redeem(stake.id);}}>{t("staking.redeem_rices_")}</button>
								</p>	
								}
								
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>
					)				
			)
		}

		{
			stakes7 && stakes7.map(
				(stake) => 
					 (
						<div key={stake.id} className="badge-item-stat">
								<p className="text-sticker">
										<svg className="text-sticker-icon icon-plus-small">
											<use xlinkHref="#svg-plus-small"></use>
										</svg>
										{t("staking.pool_45_user_19",{amount: parseInt(stake.amount.substring(0, stake.amount.length - 18)).toLocaleString()})}
								</p>
								<img className="badge-item-stat-image" width="90px" src="" alt="" />
								<p className="badge-item-stat-title">{t("staking.pool_45_user_22")}</p>
								<div className="badge-item-stat-text table table-quests split-rows">
									<div className="table-body same-color-rows">
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.staked_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{stake.date.toLocaleDateString() + " " + stake.date.toLocaleTimeString()}
												</p>
											</div>
										</div>
										<div className="table-row small">
											<div className="table-column">
												<div className="table-information">
													<p className="table-title">{t("staking.redeem_at")}</p>
												</div>
											</div>
											<div className="table-column">
												<p className="table-text align-right">
													{new Date(stake.timestamp * 1000 + 30 * 86400000).toLocaleDateString() + " " + new Date(stake.timestamp + 7 * 86400).toLocaleTimeString()}
												</p>
											</div>
										</div>
									</div>
								</div>
								{
									// redeemable
									(!isLoading && (stake.timestamp + 30 * 86400) <= (Date.now() / 1000) ) && 
								<p className="badge-item-stat-text">
									<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doLP7Redeem(stake.id);}}>{t("staking.redeem_cakelp_")}</button>
								</p>	
								}
								
								{/* LOADING BUTTON */}
								{ isLoading && 
								<p className="badge-item-stat-text">
									<button className="button loading">{t("general.loading")}</button>
								</p>
								}	
						</div>
					)				
			)
		}
    </>
  );
}
export default UserStakes45;
