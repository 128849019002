import React, { useState, useEffect, useContext } from 'react';
import Extra from './../../Extra';
import RBContext from './../../RBContext';
import { useMoralis } from "react-moralis";

function DashboardGold(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [batches, setBatches] = useState([]);
  let [rewards, setRewards] = useState([]);
  let [totalRice, setTotalRice] = useState(0);
  let [totalVIPRice, setTotalVIPRice] = useState(0);
  let [totalItem, setTotalItem] = useState(0);
  let [loading, setLoading] = useState(false);
  let [contractPlayers, setContractPlayers] = useState([]);
  let [contractRices, setContractRices] = useState([]);
  let [contractVIPPlayers, setContractVIPPlayers] = useState([]);
  let [contractVIPRices, setContractVIPRices] = useState([]);
  let [contractRealRICEPlayers, setContractRealRICEPlayers] = useState([]);
  let [contractRealRICEValues, setContractRealRICEValues] = useState([]);
  let [totalRealRICE, setTotalRealRICE] = useState(0);
  let [offset, setOffset] = useState(0);
  let [totalSpending, setTotalSpending] = useState(0);
  let [adminActionCount, setAdminActionCount] = useState(0);

  const recalculateRewards = (newrewards) => {
    for(var i = 0; i < newrewards.length; i++){
        var found = false;
        for(var j = 0; j < rewards.length; j++){
            if(rewards[j].owner == newrewards[i].owner){
                // add up
                rewards[j].rice += newrewards[i].rice;
                rewards[j].realRICE += newrewards[i].realRICE;

                found = true;
                break;
            }
        }

        if(!found){
            rewards.push(newrewards[i]);
        }
    }

    setRewards(rewards);
    return rewards;
  }

  const getTotalBattles = () => {
    var totalBattle = 0;
    for(var i = 0; i < batches.length; i++){
        totalBattle += batches[i].battles.length;
    }

    return totalBattle;
  }

  const fetchUnclaimedRewards = async (limit, poffset = -1, today = false, onlyPriority = false) => {
      try{
          setLoading(true);
          if(poffset == -1){
              poffset = offset;
          }
            var result = await Moralis.Cloud.run('admin_countUnclaimedBattles', {offset: poffset, limit, today, onlyPriority});
            console.log(result);
            var stop = false;
            var contractPlayers = [];
            var contractRices = [];
            if(result){
                // calculate for each batch
                var totalRice = 0; // rgold
                var totalRealRice = 0;
                for(var i = 0; i < result.Rewards.length; i++){
                    totalRice += result.Rewards[i].rice; // rgold
                    totalRealRice += result.Rewards[i].realRICE;
                }

                batches.push({battles: result.Battles, totalRice: totalRice, totalRealRice, totalItem: 0, startTimestamp: result.StartTimestamp, endTimestamp: result.EndTimestamp});

                var mongoCommand = 'db.RBBattles2.updateMany({$and:[{BattleTimestamp:{$gte:' + batches[0].startTimestamp + '}},{BattleTimestamp:{$lte:' + batches[batches.length - 1].endTimestamp + '}}]},{$set:{Rooster1ClaimedStatus:true,Rooster2ClaimedStatus:true,AdminClaimedOwner1: ' + Date.now() + ',AdminClaimedOwner2: '+ Date.now() + '}})';

                console.log("MongoDB Save Logs Command", mongoCommand);

                var newrewards = recalculateRewards(result.Rewards);
                
                // calculate for all batches
                var totalRice = 0;
                var playersVIP = [];
                var ricesVIP = [];
                var totalVIPRice = 0;
                var totalRealRice = 0;
                var contractLegendPlayers = [];
                var contractLegendRices = [];

                for(var i = 0; i < newrewards.length; i++){
                    totalRice += newrewards[i].rice;
                    totalRealRice += newrewards[i].realRICE;

                    if(newrewards[i].isVIP){
                        playersVIP.push(newrewards[i].owner);
                        ricesVIP.push(newrewards[i].rice);
                        totalVIPRice += newrewards[i].rice;
                    } else {
                        contractPlayers.push(newrewards[i].owner);
                        contractRices.push(newrewards[i].rice);
                    }
                    
                    if(newrewards[i].realRICE > 0){
                        contractLegendPlayers.push(newrewards[i].owner);
                        contractLegendRices.push(newrewards[i].realRICE);
                    }
                }
                
                setTotalRice(totalRice);
                setTotalVIPRice(totalVIPRice);
                setTotalRealRICE(totalRealRice);
                setTotalItem(0);

                setContractPlayers(contractPlayers);
                setContractRices(contractRices);

                setContractVIPPlayers(playersVIP);
                setContractVIPRices(ricesVIP);

                setContractRealRICEPlayers(contractLegendPlayers);
                setContractRealRICEValues(contractLegendRices);
            } else {
                stop = true;
            }

            setOffset(poffset + 1);
            setLoading(false);

            return {contractPlayers, contractRices, stop};
      } catch(err){
          alert(err.message);
          setLoading(false);
      }

  }

  const fetchAll = async (count)=>{
      for(var i = 0; i < (count == 1 ? 50 : 100); i++){
          var result = await fetchUnclaimedRewards(2000, i);
          console.log("Batch " + (i + 1) + " done!");
      }
      
     // calculate total spending this batch.
     // only correct if number of users is less than 1000
      var userSpendings = await Moralis.Cloud.run('getPlayersSpending', {players: result.contractPlayers});
      
      var totalSpending = 0;

      var players = result.contractPlayers.map((player) => player.toLowerCase());

      var new_golds = result.contractRices.map((x) => x);

      for(var i = 0; i < players.length; i++){
          var found = -1;
          var player = players[i];

          for(var j = 0; j < userSpendings.length; j++){
              if(userSpendings[j].objectId == player){
                  found = j;
                  break;
              }
          }

          if(found > -1){
                if(userSpendings[found].total > 0){
                    if(new_golds[i] > userSpendings[found].total){
                        new_golds[i] -= userSpendings[found].total;
                        totalSpending += userSpendings[found].total;
                    } else {
                        totalSpending += new_golds[i];
                        
                        new_golds[i] = 0;
                    }
                }
          }
      }

      setTotalSpending(totalSpending);
  }

  const fetchUntilToday = async ()=>{
    var i = 0;
    var result = await fetchUnclaimedRewards(2000, i, true);
    console.log("Batch " + (i + 1) + " done!");
    while(!result.stop){
        i++;
        result = await fetchUnclaimedRewards(2000, i, true);
        console.log("Batch " + (i + 1) + " done!");
    }

  }

  const resetData = () => {
    setBatches([]);
    setRewards([]);

    setTotalRice(0);
    setTotalItem(0);
    setTotalRealRICE(0);
    setContractRealRICEPlayers([]);
    setContractRealRICEValues([]);
    setContractPlayers([]);
    setContractRices([]);
    setContractVIPPlayers([]);
    setContractVIPRices([]);
  }

  const lockIngameFood = async() => {
    try{
      var result = await Moralis.Cloud.run('admin_updateSetting', {name:'ingamefood', value:'0'});
      if(result){
          alert('Locked succesfully');
      } else {
          alert('Failed to lock. Try again');
      }
    }catch(err){
        alert(err.message);
    }
}

const unlockIngameFood = async() => {
  try{
  var result = await Moralis.Cloud.run('admin_updateSetting', {name:'ingamefood', value:'1'});
  if(result){
      alert('Unlocked succesfully');
  } else {
      alert('Failed to unlock. Try again');
  }
  }catch(err){
      alert(err.message);
  }
}

const TestWallets = [
    '0x6df6a26e15fb01bbf9b7d9ffae32109b9ff369e1', // Rita
    '0x4c88C367F18a0E558C64d0293b8D9BE86f762BeD', // Jade
    //'0xd8d38692e85f923b470293b2b1D0c1fd2c06C6a6', // Josephine
    //'0x68829b0ad9b54591e30e8ad6298a1b7964ad475f', // Tony
    '0x28fa36453e8421440d786b26eee42722e5e4fa44', // DuongNK
    '0xC99Cd8Ab5257657e4b9CB971632170c85B436821', // Jade
];

  const approveGoldRewards = async (chunkIndex) => {
    try{
      var admin = user.get('ethAddress');

      setLoading(true);

      var players = contractPlayers.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var golds = contractRices.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);

      // write to contract
      console.log("Players", players);
      console.log("RGOLDs", golds);

      var userSpendings = await Moralis.Cloud.run('getPlayersSpending', {players});
      console.log("User Spendings", userSpendings);

      totalSpending = 0;
      /*
      for(var i = 0; i < userSpendings.length; i++){
        totalSpending += userSpendings[i].total;
      }
      */      

      players = players.map((player) => player.toLowerCase());

      var golds_for_verify = golds.map((x) => x);
      var new_golds = golds.map((x) => x);

      for(var i = 0; i < players.length; i++){
          var found = -1;
          var player = players[i];

          for(var j = 0; j < userSpendings.length; j++){
              if(userSpendings[j].objectId == player){
                  found = j;
                  break;
              }
          }

          if(found > -1){
                if(userSpendings[found].total > 0){
                    if(new_golds[i] > userSpendings[found].total){
                        new_golds[i] -= userSpendings[found].total;
                        golds_for_verify[i] = userSpendings[found].total;

                        totalSpending += userSpendings[found].total;
                    } else {
                        totalSpending += new_golds[i];
                        
                        new_golds[i] = 0;
                    }
                } else {
                    golds_for_verify[i] = 0;
                }
          } else {
              golds_for_verify[i] = 0;
          }
      }

      setTotalSpending(totalSpending);

        // make sure gold is an integer
        new_golds = new_golds.map((gold) => Math.ceil(gold));

        console.log("Write to contract", {players, new_golds});
        console.log("Verify Spending Golds", golds_for_verify);

        await RBGOLDREWARD_CONTRACT.methods.addReward(players, new_golds).send({from: admin});
      
        alert("Saved to contract");

        for(var i = 0; i < TestWallets.length; i++){
            var found = false;
            for(var j = 0; j < players.length; j++){
                if(players[j].toLowerCase() == TestWallets[i].toLowerCase()){
                    TestRGOLDs.push(new_golds[j]);
                    found = true;
                    break;
                }
            }
            if(!found){
               TestRGOLDs.push(0); 
            }
        }

        setTestRGOLDs(TestRGOLDs);

        var verifyGold = await Moralis.Cloud.run('admin_verifyUserSpending', {players, golds_for_verify});
        console.log("Verify User Spending", verifyGold);

        alert("Saved unverified Gold spendings");

        setAdminActionCount(adminActionCount + 1);

        setLoading(false);
    } catch(err){
        console.log(err);
        alert(err.message);
        setLoading(false);
    }
  }

  const approveRiceRewards = async (chunkIndex = 0) => {
    try{
      var admin = user.get('ethAddress');

      setLoading(true);

      var players = contractRealRICEPlayers.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var rices = contractRealRICEValues.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);

      //var players = riceRewardPlayers;
      //var rices = riceRewardValues;

      var items = players.map((item) => 0);

      if(players.length){
        // write to contract
        console.log("Players", players);
        console.log("RICEs", rices);

        rices = rices.map((rice) => Math.ceil(rice));

        await RBRewardContract.methods.addReward(players, rices, items).send({from: admin});

        setAdminActionCount(adminActionCount + 1);
    
        alert("Saved to contract");
      }

      setLoading(false);
    } catch(err){
        console.log(err);
        alert(err.message);
        setLoading(false);
    }
  }

  const approveRiceRewards2 = async (chunkIndex = 1) => {
    try{
      var admin = user.get('ethAddress');

      setLoading(true);

      var players = riceRewardPlayers;
      var rices = riceRewardValues;
      var items = players.map((item) => 0);

      if(players.length){
        // write to contract
        console.log("Players", players);
        console.log("RICEs", rices);

        rices = rices.map((rice) => Math.ceil(rice));

        var result = await RBRewardContract.methods.addReward(players, rices, items).send({from: admin});
        if(result.status){
            // reset rice rewards;
            var result = await Moralis.Cloud.run('admin_resetRiceRewards', {players});
            console.log("Reset Rice Rewards", result);

            setAdminActionCount(adminActionCount + 1);
        
            alert("Saved to contract");
        } else {
            alert("Failed save to contract");
        }
      }

      setLoading(false);
    } catch(err){
        console.log(err);
        alert(err.message);
        setLoading(false);
    }
  }

  const saveLogToDB = async () => {
    if(!window.confirm('Save to DB? Sure?')){
      return;
    }

    setLoading(true);
    for(var i = 0; i < batches.length; i++){
        var params = {battles: [batches[i].battles[0],batches[i].battles[batches[i].battles.length - 1]], totalRice: batches[i].totalRice, totalItem: batches[i].totalItem};

        console.log(params);

        // update admin
        var result = await Moralis.Cloud.run('admin_claimBattlesBoth', params);

        console.log(result);
    }

    alert('OK');

    resetData();

    setLoading(false);
  }

  const removeDuplicateBattlesAll = async() => {
    if(!loading){
        var month = window.prompt("Month? (from 1 to 12)");
        var date = window.prompt("Date? (1 to 31)");

        if(month && date){
            month = parseInt(month);
            date = parseInt(date);
            var totalRemoved = 0;
            setLoading(true);
              for(var owner = 1; owner <= 2; owner++){
                  for(var batch = 1; batch <= 5; batch++){
                    var startBatch = (batch - 1) * 2;
                    var endBatch = startBatch + 2;
                    try{
                        var result = await Moralis.Cloud.run("admin_validateBattles", {owner: owner, month: month, date: date, start: startBatch, end: endBatch});
                        console.log(result);
                        totalRemoved += result.count;

                    } catch(err){
                        alert(err.message);
                        setLoading(false);
                        alert("Battle removed: " + totalRemoved);
                        return;
                    }
                  }
              }

              setLoading(false);
              alert("Battle removed: " + totalRemoved);
        }

      }
  }

  const removeDuplicateBattles = async(batch) => {
    if(!loading){
      var owner = window.prompt("Owner 1 or 2?");
      var month = window.prompt("Month? (from 1 to 12)");
      var date = window.prompt("Date? (1 to 31)");

      if(owner && month && date){
          owner = parseInt(owner);
          month = parseInt(month);
          date = parseInt(date);
        try{
            setLoading(true);
            var startBatch = (batch - 1) * 2;
            var endBatch = startBatch + 2;
            var result = await Moralis.Cloud.run("admin_validateBattles", {owner: owner, month: month, date: date, start: startBatch, end: endBatch});
            console.log(result);
            alert("Battle removed: " + result.count);
            setLoading(false);
            } catch(err){
                alert(err.message);
                setLoading(false);
            }
      }

    }
  }

  let [withdrawals, setWithdrawals] = useState([]);
  let [totalRGOLDsRequest, setTotalRGOLDsRequest] = useState(0);
  let [totalRGOLDsVIPRequest, setTotalRGOLDsVIPRequest] = useState(0);

  const getWithdrawalRequests = async()=>{
      if(!loading){
          setLoading(true);
          try{
            var result = await Moralis.Cloud.run("admin_get_withdrawal_requests");
            console.log("Withdrawal Requests", result);
            setWithdrawals(result);
            var rgoldsRequest = 0;
            var rgoldsVIPRequest = 0;
            for(var i = 0; i < result.length; i++){
                if(result[i].isVIP){
                    rgoldsVIPRequest += result[i].amount;
                } else {
                    rgoldsRequest += result[i].amount;
                }                
            }
            setTotalRGOLDsRequest(rgoldsRequest);
            setTotalRGOLDsVIPRequest(rgoldsVIPRequest);
          } catch(err){
              alert(err.message);
          }
          
          setLoading(false);
      }
  }

  let [riceRewardPlayers, setRiceRewardPlayers] = useState([]);
  let [riceRewardValues, setRiceRewardValues] = useState([]);
  let [riceRewardTotal, setRiceRewardTotal] = useState(0);
  
  const getRiceRewards = async () => {
    if(!loading){
        setLoading(true);
        try{
          var result = await Moralis.Cloud.run("admin_fetchRiceRewards");
          setRiceRewardPlayers(result.players);
          setRiceRewardValues(result.rices);

          console.log("Rice Reward Players", result.players);

          console.log("Rice Reward Values", result.rices);
          
          var total = 0;
          for(var i = 0; i < result.rices.length; i++){
              total += result.rices[i];
          }

          setRiceRewardTotal(total);
        } catch(err){
            alert(err.message);
        }
        
        setLoading(false);
    }
}

/**
 * 
 * @param {int} contractType - 1 for default pool. 2 for VIP
 */
  const approveWithdrawalRequests = async (contractType = 1) => {
    if(!loading){
        setLoading(true);
        try{
            var admin = user.get("ethAddress");
            var players = [];
            var golds = [];
            var VIPplayers = [];
            var VIPgolds = [];
            for(var i = 0; i < withdrawals.length; i++){
                if(!withdrawals[i].isVIP){
                    players.push(withdrawals[i].owner);
                    golds.push(withdrawals[i].amount);
                } else {
                    VIPplayers.push(withdrawals[i].owner);
                    VIPgolds.push(withdrawals[i].amount);
                }
            }

            if(contractType == 1 && players.length){
                console.log("Write to default Reward Pool", {players, golds});

                await RBGOLDREWARD_CONTRACT.methods.addReward(players, golds).send({from: admin});
            }

            if(contractType == 2 && VIPplayers.length){
                console.log("Write to VIP Reward Pool", {VIPplayers, VIPgolds});

                await RBGOLD_VIPREWARD_CONTRACT.methods.addReward(VIPplayers, VIPgolds).send({from: admin});
            }            
        
            alert("Saved to contract");
        } catch(err){
            alert(err.message);
        }
        
        setLoading(false);
    }
  }

  const completeWithdrawalRequests = async() => {
    if(!loading){
        if(window.confirm("ARE YOU SURE???")){
            setLoading(true);
            try{
            var ids = [];
            for(var i = 0; i < withdrawals.length; i++){
                ids.push(withdrawals[i].id);
            }

            console.log("Withdrawal Ids", ids);

            var result = await Moralis.Cloud.run("admin_approve_withdrawal_requests", {ids: ids});
            console.log("admin_approve_withdrawal_requests", result);
            
            setWithdrawals([]);
            setTotalRGOLDsRequest(0);
            } catch(err){
                alert(err.message);
            }
            
            setLoading(false);
        }        
    }
  }

  let [TestRGOLDs, setTestRGOLDs] = useState([]);

  useEffect(()=>{
    Extra.updateContentGridPos();
  });

  if (!rbContext) {
    return null;
}

let { RBGOLDREWARD_CONTRACT, RBGOLD_VIPREWARD_CONTRACT, RBRewardContract } = rbContext;


  return (
      <>
        <div className="grid grid-4-4-4 top-space centered">
            <div className="badge-item-stat">
                <div className="badge-item-stat-text table table-quests split-rows">
                    <div className="table-body same-color-rows">
                        <div className="table-row small">
                            <div className="infoPanel">
                                {!loading &&
                                <>
                                    <button className="button btn-block" onClick={(evt) => {lockIngameFood()}}>Lock Buy in-game Food Feature</button>
                                    <button className="button btn-block" onClick={(evt) => {unlockIngameFood()}}>Unlock Buy in-game Food Feature</button>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badge-item-stat">
                <div className="badge-item-stat-text table table-quests split-rows">
                    <div className="table-body same-color-rows">
                        <div className="table-row small">
                            <div className="infoPanel">
                                <h4>RICE Rewards</h4>
                                {!loading && riceRewardTotal > 0 &&
                                <>
                                <p>Total RICE reward: {riceRewardTotal}</p>
                                <p>Total Legendary wallets: {riceRewardPlayers.length}</p>
                                <button className="button btn-block" onClick={(evt) => {approveRiceRewards2(0)}}>3. Confirm RICE rewards (max 1000 players)</button>
                                </>
                                }
                                <h4>Withdrawal Requests</h4>
                                {
                                    totalRGOLDsRequest &&
                                    <>
                                        <p>Total RGOLDS request (regular): {totalRGOLDsRequest}</p>
                                        <p>Total RGOLDS request (VIP): {totalRGOLDsVIPRequest}</p>                                        
                                        <p>Number of requests: {withdrawals.length}</p>
                                    </>
                                }
                                
                                <h4>Write to RGOLDs Contract</h4>
                                {
                                    !loading && 
                                    <>
                                        <button className="button btn-block" onClick={(evt) => {approveWithdrawalRequests(1)}}>4. Approve Withdrawal Requests</button>
                                        <button className="button btn-block" onClick={(evt) => {approveWithdrawalRequests(2)}}>5. Approve VIP Withdrawal Requests</button>
                                    </>
                                    
                                }
                                <h4>Save Logs</h4>
                                {
                                    !loading && 
                                    <button className="button btn-block" onClick={(evt) => {completeWithdrawalRequests()}}>6. Complete Withdrawal Requests</button>
                                }        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-12 top-space centered">
            <div id="admin-logsdata" className="contentPanel">
            {!loading &&
                <>

                <div className="form-row split">
                    <div className="form-item">
                        <button className="button btn-block" onClick={(evt) => {getRiceRewards()}}>1. Fetch RICE rewards</button>
                    </div>
                    <div className="form-item">
                        <button className="button btn-block" onClick={(evt) => {getWithdrawalRequests()}}>2. Fetch Withdrawal Requests</button>
                    </div>
                </div>

                </>
            }
            {loading &&
                <button className="button btn-block">Loading data...</button>
            }
                
            </div>
        </div>


      </>
    )
}
export default DashboardGold;
