import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function getTax(timestamp){
  console.log("Last claimed: " + timestamp);
  var period = Date.now() / 1000 - timestamp;
  console.log("Minutes ago: " + (period / 60));
  var fee = -1;
  if(period <= 3 * 86400){
    fee = 70;
  } else if(period <= 4 * 86400){
    fee = 60;
  } else if(period <= 5 * 86400){
    fee = 50;
  } else if(period <= 6 * 86400){
    fee = 40;
  } else if(period <= 7 * 86400){
    fee = 30;
  } else if(period <= 8 * 86400){
    fee = 20;
  } else if(period <= 9 * 86400){
    fee = 10;
  } else if(period <= 10 * 86400){
    fee = -1;
  } else {
    fee = 3;
  }

  return fee;
}

function GoldClaim(props){
  const rbContext = useContext(RBContext);

  let [loading, setLoading] = useState(true);
  const { Moralis, user } = useMoralis();
  let [lastClaimed, setLastClaimed] = useState(false);
  let [tax, setTax] = useState(0);
  let [myReward, setMyReward] = useState(false);
  let [claimable, setClaimable] = useState(0);
  let [showSpendings, setShowSpendings] = useState(false);
  let [totalSpending, setTotalSpending] = useState(0);
  let [vipReward, setVIPReward] = useState(0);

  const { t, i18n } = useTranslation();

  const getRewardInfo = async () => {
    try{
      var userAddress = user.get('ethAddress');

      setLoading(true);

      var myReward = await Moralis.Cloud.run('getMyClaimInfo', {sender: userAddress, lang: i18n.language});

      setMyReward(myReward);
      setTotalSpending(myReward.totalSpending);

      var claimable = await RBGOLDREWARD_CONTRACT.methods.UserRewards(userAddress).call();

      setClaimable(claimable);

      var lastClaimed = await RBGOLDREWARD_CONTRACT.methods.LastClaimed(userAddress).call();

      setLastClaimed(lastClaimed);
      tax = getTax(lastClaimed);

      setTax(tax);

      var vipReward = await RBGOLD_VIPREWARD_CONTRACT.methods.UserRewards(userAddress).call();
      setVIPReward(parseInt(vipReward));

      setLoading(false);
    } catch(err){
      console.log(err.message);
    }
  }

  const claimReward = async () => {
    if(tax == -1){
      return;
    }

    var userAddress = user.get('ethAddress');
    setLoading(true);

    try{
      if(window.confirm(t("profile.confirm_tax_apply", {tax: tax}))){
        var result = await RBGOLDREWARD_CONTRACT.methods.claimReward().send({from: userAddress});

        if(result.status){
          alert(t("profile.reward_claimed"));
          var newLastClaimed = await RBGOLDREWARD_CONTRACT.methods.LastClaimed(userAddress).call();

          var log = await Moralis.Cloud.run('logClaim', {claimTimestamp: newLastClaimed,lang: i18n.language, totalRice: lastClaimed ? parseInt(claimable) : 200, totalItems: 0});
          
          var newClaimable = await RBGOLDREWARD_CONTRACT.methods.UserRewards(userAddress).call();

          setClaimable(newClaimable);

          setLastClaimed(newLastClaimed);
          tax = getTax(newLastClaimed);

          setTax(tax);

        } else {
          alert(t("profile.reward_unable_to_claim"));
          console.log(result);
        }

        setLoading(false);
      }
    } catch(err){
      console.log(err);
      alert(err.message);
      setLoading(false);
    }
  }

  const claimVIPReward = async () => {
    
    var userAddress = user.get('ethAddress');
    setLoading(true);

    try{
      
        var result = await RBGOLD_VIPREWARD_CONTRACT.methods.claimReward().send({from: userAddress});

        if(result.status){
          alert(t("profile.reward_claimed"));
          setVIPReward(1);
        } else {
          alert(t("profile.reward_unable_to_claim"));
          console.log(result);
        }

        setLoading(false);
        
    } catch(err){
      console.log(err);
      alert(err.message);
      setLoading(false);
    }
  }

  const registerPlayer = async () => {
    var userAddress = user.get('ethAddress');
    if(!loading){
      setLoading(true);

      try{
        var result = await RBGOLDREWARD_CONTRACT.methods.registerPlayer().send({from: userAddress});
        if(result.status){
          alert(t("profile.register_player_ok"));

          var claimable = await RBGOLDREWARD_CONTRACT.methods.UserRewards(userAddress).call();
          setClaimable(claimable);
        } else {
          alert(t("profile.register_player_failed"));
          console.log(result);
        }

        setLoading(false);
      } catch(err){
        alert(err.message);
        setLoading(false);
      }
    }
  }

  const toggleSpendings = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    showSpendings = !showSpendings;
    setShowSpendings(showSpendings);

    return false;
  }

  const inputChange = (evt) => {
    var number = evt.target.value;
    setWithdrawRequest(parseInt(number));
  }

  let [withdrawRequest, setWithdrawRequest] = useState(0);

  const requestWithdrawal = async() =>{
    if(withdrawRequest && withdrawRequest < myReward.verified){
      setLoading(true);
      var params = {lang: i18n.language, amount: withdrawRequest};
      console.log(params);
      var result = await Moralis.Cloud.run("request_widthdrawal", params);
      console.log(result);
      if(!result.result){
        alert(result.message);
      } else {
        myReward.withdrawal = withdrawRequest;
        setMyReward(myReward);
      }

      setLoading(false);
    }
  }

  const cancelWithdrawal = async () => {
    setLoading(true);
    var result = await Moralis.Cloud.run("cancel_widthdrawal", {lang: i18n.language});

      if(!result.result){
        alert(result.message);
      } else {
        myReward.withdrawal = 0;
        setMyReward(myReward);
      }

      setLoading(false);
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          setTimeout(getRewardInfo, 200);
        }
        fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { RBGOLDREWARD_CONTRACT, RBGOLD_VIPREWARD_CONTRACT } = rbContext;

  return(
    <>
      <div className="centered">
        <img src={process.env.REACT_APP_HOME_URL + "assets/goi_thoc_05.png"}/>
      </div>
      {parseInt(claimable) == 0 &&
      <div className="grid grid-6-6 medium-space">
        <div className="form-row split">
          <div className="form-item">
          </div>
          <div className="form-item">
            <button className="button" onClick={registerPlayer}>{loading ? t("profile.registering") : t("profile.register_first_time")}</button>
          </div>
          <div className="form-item">
          </div>
        </div>
      </div>
      }

      <div id="UserRewardProfile">
        {
        parseInt(claimable) >= 1 &&
          <>
          <div className="grid grid-half small-space">
            <div className="centered marginBottom">
              <h4 className="heading" id="claims-total-heading">{t("profile.total_unverified_rgolds")}<span className="number">{t("profile.number_rgolds", {gold: myReward ? myReward.unverified : 0})}</span></h4>
              <p className='note'>{t("profile.unverified_gold_note")}</p>
              <p className="centered note battleclaimed-info">{t("profile.view_battles_history")}<a href={process.env.REACT_APP_HOME_URL + "profile/battles"}>{t("profile.history")}</a></p>
              <hr className='separator'></hr>
              <h4 className="heading">{t("profile.total_unverified_spending")}<span className="number">{t("profile.number_rgolds", {gold: totalSpending})}</span></h4>
              <p className="note"><a onClick={toggleSpendings} href="#">{t("profile.view_detail_rgold_spending")}</a> {t("profile.the_last_50_rows_spending")}</p>
              {
                (showSpendings && myReward && myReward.spendings) ? <div className="block white form-row split">
                  <div className="form-item">
                  </div>
                  <div className="form-item"><ul className="list small">{myReward.spendings.map((item, index)=>{
                  return (<li className="row" id={"spendings-" + index}>{t("profile.number_rgolds", {gold: item.get('TotalGold')})} - {t("profile.spending_on")} {item.get('createdAt').toLocaleDateString()} - {item.get('SpendDescription')}</li>)
                })}</ul>
                  </div>
                  <div className="form-item">
                </div></div> : <>{myReward && !myReward.spendings && <p className="white">{t("profile.no_spending_so_far")}</p>}</>
              }
              <hr className='separator'></hr>
              <h4 className="heading">{t("profile.total_verified")}<span className='number'>{t("profile.number_rgolds", {gold: myReward ? myReward.verified : 0})}</span></h4>
              <p className='note'>{t("profile.verified_gold_note")}</p>

              <hr className='separator'></hr>
              <h4 className="heading">{t("profile.withdrawal_request")}<span className='number'>{t("profile.number_rgolds", {gold: myReward ? myReward.withdrawal : 0})}</span></h4>
              <p className='note'>{t("profile.withdrawal_request_note")}</p>
              {
                (myReward && myReward.withdrawal == 0) && 
                <>
                  <div className="mini-form form-row split">
                    <div className="form-item">
                      <div className="form-input small active">
                        <label htmlFor="withdrawalRequest">{t("profile.request_rgold_withdraw")}</label>
                        <input type="number" onChange={inputChange} style={{width:'100%'}} className='numberbox' min="1" max={myReward ? myReward.verified : 0} id="withdrawalRequest" />
                      </div>
                    </div>
                    <div className="form-item">
                        <button style={{marginTop:'5px'}} className="button btn-primary" onClick={requestWithdrawal}>{loading ? t("general.loading") : t("profile.button_request")}</button>
                    </div>
                  </div>
                </>
              }
              {
                (myReward && myReward.withdrawal) &&
                <>
                  <div className="mini-form form-row split">
                    <div className="form-item">
                      <div className="form-input small active">
                        <label htmlFor="withdrawalRequest">{t("profile.request_rgold_withdraw")}</label>
                        <input type="number" style={{width:'100%'}} className='numberbox' defaultValue={myReward.withdrawal} id="withdrawalRequest" />
                      </div>
                    </div>
                    <div className="form-item">
                        <button style={{marginTop:'5px'}} className="button btn-primary" onClick={cancelWithdrawal}>{loading ? t("general.loading") : t("profile.button_cancel_request")}</button>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="form-row split">
              <div className="form-item">
                <h3 className='white centered'>{t("profile.total_claimable")}<span className="number">{t("profile.number_rgolds", {gold: (parseInt(claimable) - 1)})}</span></h3>
                
                  {!loading && ((lastClaimed == 0 && parseInt(claimable) > 200) || lastClaimed > 0) &&
                    <p className='centered'>
                      <button className="button btn-primary" onClick={claimReward}>{t("profile.claim_rgolds")}</button>
                    </p>
                  }
                  {!loading && tax > -1 &&
                  <p className="note centered battleclaimed-info">{lastClaimed > 0 ? t("profile.claim_last_claimed_rgolds_time",{time: new Date(lastClaimed * 1000).toLocaleDateString("en-US") + " " + new Date(lastClaimed * 1000).toLocaleTimeString("en-US"), tax: tax, gold: (Math.floor(parseInt(claimable) * (100 - tax) / 100))}) : t("profile.first_rgolds_claimed")}. {t("profile.claim_note")} <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">{t("profile.reward_mechanism")}</a></p>
                  }
                  {!loading && tax == -1 &&
                  <p className="note centered battleclaimed-info">{lastClaimed > 0 ? t("profile.claim_cooldown_day",{time: new Date(lastClaimed * 1000).toLocaleDateString("en-US") + " " + new Date(lastClaimed * 1000).toLocaleTimeString("en-US")}) : t("profile.first_rgolds_claimed")}. {t("profile.claim_note")} <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">{t("profile.reward_mechanism")}</a></p>
                  }

                  { !loading && vipReward > 1 &&
                    <div className='centered'>
                      <hr className='separator'/>
                      <h3  className='heading'>{t("profile.vip_reward_amount")}: <span className="number">{t("profile.number_rgolds", {gold: vipReward - 1})}</span></h3>
                      <button className="button btn-primary" onClick={claimVIPReward}>{t("profile.claim_rgolds")}</button>
                    </div>
                  }

                  {
                    loading &&

                    <div className="centered paragraph white no-battles">{t("profile.checking_rewards")}</div>
                  }
              </div>
            </div>
          </div>
           
          </>
        }
      </div>

    </>
  )
}

export default GoldClaim;
