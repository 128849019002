import React, {useEffect, useContext, useState} from 'react';
import { useMoralis } from "react-moralis";
import {
  Link, useMatch
} from "react-router-dom";
import RBContext from './../../RBContext';
import { getArmoredLevel, getNFTCategory, getRoosterName, getSkillName, getGameItemName, addEggInfo, getGameItemEffect } from './../../utils/RBUtils.js';
import { useTranslation } from "react-i18next";
import NFTThumb from './../../components/common/NFTThumb';

function NFTListingItem(props){
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation();
  let [loading, setLoading] = useState(true);

  const { Moralis, user } = useMoralis();
  let [henType, setHenType] = useState(1);
  let [henBreeding, setBreeding] = useState(0);
  let [eggInfo, setEggInfo] = useState('');
  let [skillReroll, setSkillReroll] = useState(0);
  let [gameiteminfo, setGameItemInfo] = useState(false);

  const withdrawIt = async (tokenId, tokenType) => {
    if(window.confirm(t("error.confirm_withdraw"))){
      setLoading(true);
      try {
        var currentUserAddress = user.get('ethAddress');
        if(tokenType == 2){
          var myOffers = await RBMARKETGAMEITEM_CONTRACT.methods.getMyGameItemOffers(currentUserAddress).call();
          if(myOffers.includes(tokenId)){
            var result = await RBMARKETGAMEITEM_CONTRACT.methods.withdrawGameItemOffer(tokenId).send({from: currentUserAddress});
          }
          
        } else if(tokenType == 1 || tokenType == 3) {
          var offer = await marketContract.methods.roosterOffers(tokenId).call();
          if(offer.ricePrice != 0){
            var result = await marketContract.methods.withdrawRoosterOffer(tokenId).send({from: currentUserAddress});
          }
        } else if(tokenType == 4) {
          var offer = await RBMARKETEGG_CONTRACT.methods.Offers(tokenId).call();
          if(offer.ricePrice != 0){
            var result = await RBMARKETEGG_CONTRACT.methods.withdraw(tokenId).send({from: currentUserAddress});
          }
        } else if(tokenType == 5){
          var offer = await RBMARKETHEN_CONTRACT.methods.henOffers(tokenId).call();
          if(offer.ricePrice != 0){
            var result = await RBMARKETHEN_CONTRACT.methods.withdrawOffer(tokenId).send({from: currentUserAddress});
          }
        }
      } catch(err) {
        alert(err.message);
        setLoading(false);
        return false;
      }

      const params = {tokenId: tokenId,lang: i18n.language};

      if(tokenType == 2){
        var result = await Moralis.Cloud.run('withdrawGameItemOffer', params);
      } else if(tokenType == 1 || tokenType == 3){
        var result = await Moralis.Cloud.run('withdrawRoosterOffer', params);
      } else if(tokenType == 4){
        var result = await Moralis.Cloud.run('withdrawTicketOffer', params);
      } else if(tokenType == 5){
        var result = await Moralis.Cloud.run('withdrawHenOffer', params);
      }

      if(result !== "INVALID"){
        alert(t("error.withdraw_ok"));
        props.onRemoved(0);
      } else {
        alert(t("error.withdraw_failed"));
      }

      setLoading(false);
    }

    return false;
  }

  const withDrawBtn = () => {
    if(loading){
      return (<a className="button loading btn-block btn-withdrawMarket" href="#">...processing...</a>);
    }

    return (<a className="button btn-block btn-withdrawMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();withdrawIt(props.token.TokenId, props.token.TokenType);}}>Withdraw</a>);
  }

  useEffect( () => {
    async function getData(){
      if(props.token.TokenType == 5){
        var henType = await RBHenContract.methods.Hens(props.token.TokenId).call();
        setHenType(henType);

        var breeding = await RBBREEDINGF1_CONTRACT.methods.HenUsed(props.token.TokenId).call();
        setBreeding(breeding);
      } else if(props.token.TokenType == 4){
        var eggInfo = await RBEGG_CONTRACT.methods.Eggs(props.token.TokenId).call();
        console.log(eggInfo);
        eggInfo = addEggInfo(eggInfo);
        setEggInfo(eggInfo);
      } else if(props.token.TokenType == 3){
        var skillReroll = await RBBLACKSMITH_CONTRACT.methods.RerollCount(props.token.TokenId).call();
        setSkillReroll(skillReroll);
      } else if(props.token.TokenType == 2){
        var gameitemInfo = await RBMARKETGAMEITEM_CONTRACT.methods.gameItemOffers(props.token.TokenId).call();
        console.log("Gameitem", gameitemInfo);
        setGameItemInfo(gameitemInfo);
      }
      
      setLoading(false);
    }
    getData();    
  }, [user, props.token, rbContext]);

  if (!rbContext) {
      return null;
  }

  let { RBBREEDINGF1_CONTRACT, marketContract, RBMARKETGAMEITEM_CONTRACT, 
    RBMARKETHEN_CONTRACT,RBMARKETEGG_CONTRACT, 
    RBHenContract, RBEGG_CONTRACT,RBBLACKSMITH_CONTRACT } = rbContext;

  const attack_defense_el = (attack, defense, armors = []) => {
    
    if(props.token.NFT.status != 'egg' && attack && defense){
      return (
        <> 
          <p className="product-preview-text">{t("rooster.attack_detail", {slots: attack.join(', ')})}</p>
          <p className="product-preview-text">{t("rooster.defense_detail", {slots: defense.join(', ')})}</p>
          {props.token.TokenType == 1 ? 
          <>
            {(armors && armors.length == 4 && parseInt(armors[0]) >= 1 && parseInt(armors[0]) <= 3) ? 
          <>
            <p className="product-preview-text"><strong>{t("general.armored_rooster")} - {getArmoredLevel(parseInt(armors[0]))}</strong><br/><br/><br/></p>
          </> :

          <>
            {(armors && armors.length == 4 && parseInt(armors[0]) == 4) ? 
            <p className="product-preview-text"><strong>{t("general.legendary_rooster")}</strong><br/><br/><br/></p> : 
            <p><br/><br/><br/><br/><br/></p>
            }
          </>
          
            }
          </> : <></>}
        </>
      );
    } else {
      return (
        <>
        <p className="product-preview-text">{t("rooster.attack_detail", {slots: "?"})}</p>
        <p className="product-preview-text">{t("rooster.defense_detail", {slots: "?"})}</p>
        </>
      );
    }
  }

  return (
      <>
        {console.log(props.token)}
        <div className="product-preview">
          <Link to={'/marketplace/' + props.token.TokenType + '/' + props.token.TokenId}>
            <NFTThumb TokenType={props.token.TokenType == 1 ? 'ROOSTER' : (props.token.TokenType == 2 ? 'GAMEITEM' : (props.token.TokenType == 3 ? 'SKILL' : (props.token.TokenType == 4 ? 'TICKET' : 'HEN')))} 
              Armors={props.token.NFT ? props.token.NFT.armors : []}
              Status={props.token.NFT ? props.token.NFT.status : ''} 
              ItemType={props.token.TokenType == 5 ? henType : (props.token.NFT ? props.token.NFT.type : '')} 
              TokenId={props.token.TokenType == 2 ? (gameiteminfo ? gameiteminfo.gameItemType : props.token.GameItemType) : props.token.TokenId} 
              EggThumb={eggInfo.eggThumb}/>
          </Link>
          <div className="product-preview-info">
            <p className="text-sticker"><span className="highlighted">RICE</span> {parseInt(props.token.RicePrice.substring(0,props.token.RicePrice.length - 18)).toLocaleString()}</p>
            <p className="product-preview-title">
              {user ? 
              <Link to={'/marketplace/' + props.token.TokenType + '/' + props.token.TokenId}>#{props.token.TokenType != 2 ? props.token.TokenId : (parseInt(props.token.TokenId) + 1)}</Link>
              :
              <>#{props.token.TokenType != 2 ? props.token.TokenId : (parseInt(props.token.TokenId) + 1)}</>
              } 
            </p>
            <p className="product-preview-category digital">
              {getNFTCategory(props.token.TokenType)} 
              {
                props.token.TokenType == 1 && (" - " + (props.token.NFT.status == 'egg' ? '?' : getRoosterName(props.token.NFT.type)))
              }
              {
                props.token.TokenType == 3 && (" - " + getSkillName(props.token.NFT.type))
              }
              {
                props.token.TokenType == 2 && (" - " + getGameItemName(gameiteminfo ? gameiteminfo.gameItemType : props.token.GameItemType))
              }
              {
                props.token.TokenType == 4 && (" - " + eggInfo.eggName)
              }
            </p>
            <p className="product-preview-text">{t("marketplace.placed_on", {date: new Date(Date.parse(props.token.createdAt)).toLocaleDateString("en-US")})}</p>
            {props.token.TokenType == 2 &&
            <>
            
            <p className="product-preview-text">{t("gameitem.amount", {amount: props.token.Amount})}</p>
            <p className="product-preview-text">{t("gameitem.effect", {effect: getGameItemEffect(props.token.GameItemType)})}</p>
            </>
            }
            {props.token.TokenType == 4 &&
            <><p className="product-preview-text">{t("marketplace.egg_can_be_hatched_in_game")}</p>
            <p className="product-preview-text">{eggInfo ? t("marketplace.egg_hatch_time", {time: eggInfo.hatchable}) : ''}<br/><br/><br/><br/><br/></p>
            </>
            }
            {props.token.TokenType == 5 &&
            <>
            <p className="product-preview-text">{t("marketplace.hen_intro")}</p>
            <p className="product-preview-text">{t("marketplace.breeding_used", {count: henBreeding})}<br/><br/><br/><br/></p>
            </>
            }
            {(props.token.TokenType == 1 || props.token.TokenType == 3) &&
              <>
                {attack_defense_el(props.token.NFT.attack, props.token.NFT.defense, props.token.NFT.armors)}
                {(props.token.TokenType == 3
                  ) && 
                  <>
                    <p className="product-preview-text">{t("rooster.current_level", {level: props.token.NFT.armors.length ? parseInt(props.token.NFT.armors[0]) : 0})} {(props.token.NFT.armors.length && props.token.NFT.armors[0] == 1) ? <>(&#9733;)</> : <></>}</p>
                    <p className='product-preview-text'>{t("rooster.reroll_count", {count: skillReroll})}</p>
                  </>
                }
              </>
            }
          </div>
          <div className={
            (user && props.token.Seller.toLowerCase() === user.get('ethAddress')) ? "product-preview-meta block" : "product-preview-meta"}>
            <div className="product-preview-author">
              {
                (user && props.token.Seller.toLowerCase() === user.get('ethAddress')) ?
                <>
                  <p className="product-preview-author-title">{withDrawBtn()}</p>
                </> :
                <>
                  <a className="product-preview-author-image user-avatar micro no-border" href="#">
                    <div className="user-avatar-content">
                      <div className="hexagon-image-18-20">
                        <svg className="icon-friend">
                          <use xlinkHref="#svg-friend"></use>
                        </svg>
                      </div>
                    </div>
                  </a>
                  <p className="product-preview-author-title">{t("general.seller")}</p>
                  <p className="product-preview-author-text"><a target="_blank" href={process.env.REACT_APP_CHAIN_SCAN + "address/" + props.token.Seller}>{props.token.Seller}</a></p>
                </>
              }

            </div>
          </div>
        </div>
      </>
    );
}
export default NFTListingItem;
