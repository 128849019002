import React, { useEffect, useContext } from 'react';
import NFTFood from './NFTFood';
import NFTPotion from './NFTPotion';
import NFTEnhancementItem from './NFTEnhancementItem';
import { useLocation } from "react-router-dom";
import RBContext from './../../RBContext';
import Extra from './../../Extra';
import { useTranslation } from "react-i18next";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function NFTShop(props){
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    Extra.updateContentGridPos();
  }); 

  if (!rbContext) {
      return null;
  }

  return(
    <>
      <div className="content-grid">
        <div className="section-banner">
          <p className="section-banner-title">{t("shop.title")}</p>
          <p className="section-banner-text">{t("shop.subheading")}</p>
        </div>
        
        <div className="grid grid-4-4-4 centered">
          <NFTEnhancementItem package="5" count="1"/>
          <NFTEnhancementItem package="5" count="5"/>
          <NFTEnhancementItem package="5" count="10"/>
          <NFTEnhancementItem package="6" count="1"/>
          <NFTEnhancementItem package="6" count="5"/>
          <NFTEnhancementItem package="6" count="10"/>
          <NFTEnhancementItem package="7" count="1"/>
          <NFTEnhancementItem package="7" count="5"/>
          <NFTEnhancementItem package="7" count="10"/>
          <NFTEnhancementItem package="8" count="1"/>
          <NFTEnhancementItem package="8" count="5"/>
          <NFTEnhancementItem package="8" count="10"/>
          <NFTEnhancementItem package="9" count="1"/>
          <NFTEnhancementItem package="9" count="5"/>
          <NFTEnhancementItem package="9" count="10"/>
          <NFTFood count="1" />
          <NFTFood count="4" />
          <NFTFood count="10" />
          <NFTPotion count="1"/>
          <NFTPotion count="10"/>
          <NFTPotion count="50"/>
        </div>
      </div>
    </>
  );
}

export default NFTShop;
