import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import Config from './Config';
import Stakes45 from './components/staking/Stakes45';
import Stakes11 from './components/staking/Stakes11';
import UserStakes45 from './components/staking/UserStakes45';
import UserStakes8 from './components/staking/UserStakes8';
import UserStakes9 from './components/staking/UserStakes9';
import UserStakes10 from './components/staking/UserStakes10';
import UserStakesGrid from './components/staking/UserStakesGrid';
import RBContext from './RBContext';
import Extra from './Extra';
import WrongNetwork from './components/WrongNetwork';
import { useTranslation } from "react-i18next";

function StakingPage() {
    const { t, i18n } = useTranslation();  
    let { stakingContracts } = Config;

    const rbContext = useContext(RBContext);

    let { isAuthenticated, user } = useMoralis();
    let [userStakings, setUserStakings] = useState(null);
    let [sContracts, setSContracts] = useState(null);
    let [pendingSkills, setPendingSkills] = useState(null);
    let [pendingStakes4, setPendingStakes4] = useState([]);
    let [stakes4GameItems, setStakes4GameItems] = useState(0);
    let [pendingStakes5, setPendingStakes5] = useState([]);
    let [stakes5GameItems, setStakes5GameItems] = useState(0);
    let [pendingStakes6, setPendingStakes6] = useState([]);
    let [stakes6GameItems, setStakes6GameItems] = useState(0);
    let [pendingStakes7, setPendingStakes7] = useState([]);
    let [stakes7GameItems, setStakes7GameItems] = useState(0);
    let [pendingStakes8, setPendingStakes8] = useState([]);
    let [pendingStakes9, setPendingStakes9] = useState([]);
    let [pendingStakes10, setPendingStakes10] = useState([]);
    let userAddr = isAuthenticated && user ? user.get('ethAddress') : null;

    useEffect(() => {
        Extra.updateContentGridPos();
    })

    useEffect(() => {
        async function fetchStakingPoolStates() {

            if (!rbContext) {
                return;
            }

            /**
             * mapping config staking contract with web3 contract instance
             */
             let _sContracts = await Promise.all(
                stakingContracts
                    .filter((sContract) => sContract.abi && sContract.address)
                    .map(async (sContract) => {

                        let contract = new web3.eth.Contract(sContract.abi, sContract.address);

                        let [filled, todayLockedAmount] = await Promise.all([
                            contract.methods.getStakeFilled().call(),
                            contract.methods.getLockedAmountOfDay(getTodayIndex(sContract.openTimestamp)).call(),
                        ]);

                        return {
                            ...sContract,
                            contract: contract,
                            filled,
                            todayLockedAmount,
                            apr: calculateAPR(sContract, todayLockedAmount)
                        }
                    })
            )

            setSContracts(_sContracts);
            
            let stakes4 = await RBPool4Contract.methods.getUserStakingIndexes(userAddr).call();
            let pendingStakes4 = [];
            if(stakes4.length){
                for(var i = 0; i < stakes4.length; i++){
                    var theStake = await RBPool4Contract.methods.getUserStakingByIndex(stakes4[i]).call();
                    
                    if(theStake[2] == "0"){
                        var obj = {};
                        obj.id = stakes4[i];
                        obj.amount = theStake[0];
                        obj.timestamp = parseInt(theStake[1]);
                        obj.date = new Date(obj.timestamp * 1000);
                        pendingStakes4.push(obj);
                    }
                }
                //if(pendingStakes4.length){
                    try{
                        var stakes4GameItems = await RBPool4Contract.methods.getRemainingGameItems(userAddr).call();
                        setStakes4GameItems(stakes4GameItems);
                    } catch(err) {
                        console.log(err);
                    }
                //}
            }

            setPendingStakes4(pendingStakes4);

            let stakes6 = await RBPool6Contract.methods.getUserStakingIndexes(userAddr).call();
            let pendingStakes6 = [];
            console.log("Stakes 6", stakes6);
            if(stakes6.length){
                for(var i = 0; i < stakes6.length; i++){
                    var theStake = await RBPool6Contract.methods.getUserStakingByIndex(stakes6[i]).call();
                    
                    if(theStake[2] == "0"){
                        var obj = {};
                        obj.id = stakes6[i];
                        obj.amount = theStake[0];
                        obj.timestamp = parseInt(theStake[1]);
                        obj.date = new Date(obj.timestamp * 1000);
                        pendingStakes6.push(obj);
                    }
                }
                //if(pendingStakes6.length){
                    try{
                        var stakes6GameItems = await RBPool6Contract.methods.getRemainingGameItems(userAddr).call();
                        setStakes6GameItems(stakes6GameItems);
                    }catch(err){
                        console.log(err);
                    }
                //}
            }
            
            setPendingStakes6(pendingStakes6);
            
            let stakes5 = await RBPool5Contract.methods.getUserStakingIndexes(userAddr).call();
            let pendingStakes5 = [];
            
            if(stakes5.length){
                for(var i = 0; i < stakes5.length; i++){
                    var theStake = await RBPool5Contract.methods.getUserStakingByIndex(stakes5[i]).call();
                    
                    if(theStake[2] == "0"){
                        var obj = {};
                        obj.id = stakes5[i];
                        obj.amount = theStake[0];
                        obj.timestamp = parseInt(theStake[1]);
                        obj.date = new Date(obj.timestamp * 1000);
                        pendingStakes5.push(obj);
                    }
                }

                //if(pendingStakes5.length){
                    try{
                        var stakes5GameItems = await RBPool5Contract.methods.getRemainingGameItems(userAddr).call();
                        
                        setStakes5GameItems(stakes5GameItems);
                    }catch(err){
                        console.log(err);
                    }
                //}
            }
        
            setPendingStakes5(pendingStakes5);

            let stakes7 = await RBPool7Contract.methods.getUserStakingIndexes(userAddr).call();
            let pendingStakes7 = [];
            
            if(stakes7.length){
                for(var i = 0; i < stakes7.length; i++){
                    var theStake = await RBPool7Contract.methods.getUserStakingByIndex(stakes7[i]).call();
                    
                    if(theStake[2] == "0"){
                        var obj = {};
                        obj.id = stakes7[i];
                        obj.amount = theStake[0];
                        obj.timestamp = parseInt(theStake[1]);
                        obj.date = new Date(obj.timestamp * 1000);
                        pendingStakes7.push(obj);
                    }
                }
                try{
                    
                    var stakes7GameItems = await RBPool7Contract.methods.getRemainingGameItems(userAddr).call();
		            setStakes7GameItems(stakes7GameItems);
                }catch(err){
                    console.log(err);
                }
            }
            setPendingStakes7(pendingStakes7);
            
            let stakes8 = await RBPool8Contract.methods.getUserStakes(userAddr).call();
            let pendingStakes8 = [];
            
            if(stakes8.length){
                var stake1Reward = await RBPool8Contract.methods.Stake1Reward().call();
                var stake2Reward = await RBPool8Contract.methods.Stake2Reward().call();
                for(var i = 0; i < stakes8.length; i++){
                    if(parseInt(stakes8[i]) > 0){
                        var theStake = await RBPool8Contract.methods.Stakes(stakes8[i]).call();
                    
                        var obj = {};
                        
                        obj.henId = parseInt(stakes8[i]);
                        obj.timestamp = parseInt(theStake[1]) * 1000;
                        obj.reward = parseInt(theStake[2]);
                        obj.roosterId = parseInt(theStake[3]);
                        obj.redeemCount = parseInt(theStake[4]);
                        obj.riceRedeemAt = 0;

                        if(obj.reward == 1 || obj.reward == 2){
                            obj.redeemAt = obj.timestamp + 45 *  86400000;
                            if(obj.timestamp + 30 * 86400000 <= Date.now() && obj.redeemCount < 2){
                                obj.riceRedeemAt = obj.timestamp + 30 * 86400000;
                                obj.riceRedeemAmount = (obj.redeemCount == 0 ? 60 : 30) * parseInt(obj.reward == 1 ? stake1Reward : stake2Reward);
                            } else if(obj.timestamp + 15 * 86400000 <= Date.now() && obj.redeemCount < 1){
                                obj.riceRedeemAt = obj.timestamp + 15 * 86400000;
                                obj.riceRedeemAmount = 30 * parseInt(obj.reward == 1 ? stake1Reward : stake2Reward);
                            }
                        } else {
                            obj.redeemAt = obj.timestamp + 15 *  86400000;
                        }

                        pendingStakes8.push(obj);
                    }
                    
                }
            }

            setPendingStakes8(pendingStakes8);
            
            let stakes9 = await RBPool9Contract.methods.getUserStakes(userAddr).call();
            let pendingStakes9 = [];
            
            if(stakes9.length){
                
                for(var i = 0; i < stakes9.length; i++){
                    if(parseInt(stakes9[i]) > 0){
                        var theStake = await RBPool9Contract.methods.Stakes(stakes9[i]).call();
                    
                        var obj = {};
                        
                        obj.stakeIndex = parseInt(stakes9[i]);
                        obj.timestamp = parseInt(theStake[1]) * 1000;
                        obj.roosterIds = [];
                        obj.availableGold = await RBPool9Contract.methods.getAvailableGold(stakes9[i]).call();
                        
                        pendingStakes9.push(obj);
                    }
                    
                }
            }
            
            setPendingStakes9(pendingStakes9);

            let stakes10 = await RBPool10Contract.methods.getUserStakes(userAddr).call();
            let pendingStakes10 = [];
            
            if(stakes10.length){
                
                for(var i = 0; i < stakes10.length; i++){
                    if(parseInt(stakes10[i]) > 0){
                        var theStake = await RBPool10Contract.methods.Stakes(stakes10[i]).call();
                    
                        var obj = {};
                        
                        obj.stakeIndex = parseInt(stakes10[i]);
                        obj.timestamp = parseInt(theStake[1]) * 1000;
                        obj.roosterIds = await RBPool10Contract.methods.getStakeRoosters(stakes10[i]).call();
                        obj.availableGold = await RBPool10Contract.methods.getAvailableGold(stakes10[i]).call();
                        
                        pendingStakes10.push(obj);
                    }
                    
                }
            }
            
            setPendingStakes10(pendingStakes10);
        }

        if(userAddr){
            fetchStakingPoolStates();
        }
        
    }, [userAddr, rbContext])

    useEffect(() => {
        /**
         * in case user is authenticated, fetch user stakings
         */
        fetchUserStakings();
        fetchUserPendingSkills();
    }, [sContracts])

    if (!rbContext) {
        return null;
    }

    let { RBPool4Contract, RBPool5Contract, RBPool6Contract, RBPool7Contract, RBPool8Contract, RBPool9Contract, RBPool10Contract, web3 } = rbContext;    

    async function redeem(staking) {
        var currentUser = userAddr;
        if(!currentUser){
          // try to fetch user address again
          currentUser = isAuthenticated && user ? user.get('ethAddress') : false;
        }

        if(currentUser){
          return staking.pool.contract.methods
              .redeem(staking.index)
              .send({ from: userAddr })
              .then(() => {
                  return Promise.all([
                      fetchStakingsFilled(),
                      fetchUserStakings(),
                      fetchUserPendingSkills() // should fetch pending skills after redeem since there will be one after redeemed
                  ]);
              });
          } else {
            alert(t("error.please_connect_wallet"));
          }
    }

    function getTodayIndex(openingTimestamp) {
        return Math.floor((Math.floor(Date.now() / 1000) - openingTimestamp) / 86400);
    }

    function calculateAPR(pool, todayLockedAmount) {
        todayLockedAmount = new web3.utils.BN(todayLockedAmount);
        let defaultLockedAmount = new web3.utils.BN(web3.utils.toWei("5000"));

        if (todayLockedAmount.lt(defaultLockedAmount)) {
            todayLockedAmount = defaultLockedAmount;
        }

        let amount = web3.utils.toWei(pool.amount.toString());

        return (amount / pool.activeDays) / todayLockedAmount * 365 * 100;
    }

    async function redeemSkills(pendingSkill) {
        var userAddr = user.get('ethAddress');
        return pendingSkill.pool.contract.methods
            .redeemSkills()
            .send({ from: userAddr })
            .then(() => {
                return fetchUserPendingSkills();
            })
    }

    async function fetchUserStakings() {
        
        if (isAuthenticated && sContracts) {
            try {
                let uStakingIdxes = await Promise.all(
                    sContracts.map((sContract) => {
                        return sContract.contract.methods.getUserStakingIndexes(userAddr).call()
                    })
                );

                let fetchedUerStakings = uStakingIdxes
                    .reduce((_fetchedUserStakings, contractIdxes, index) => {
                        for (let i of contractIdxes) {

                            /**
                            * skip if staking index = 0
                            */
                            if (parseInt(i) <= 0) {
                                continue;
                            }

                            _fetchedUserStakings.push({
                                pool: sContracts[index],
                                index: parseInt(i)
                            });

                        }
                        return _fetchedUserStakings;
                    }, [])
                    
                let results = await Promise.all(
                    fetchedUerStakings.map((uStaking) => {
                        return Promise.all([
                            uStaking.pool.contract.methods.getUserStakingByIndex(uStaking.index).call(),
                            uStaking.pool.contract.methods.getUserStakingInterestsByIndex(uStaking.index).call()
                        ])
                    })
                )

                fetchedUerStakings = fetchedUerStakings.map((uStaking, arrIndex) => {
                    let [amount, timestamp, startDayIndex, isRedeemed] = results[arrIndex][0];
                    return {
                        amount,
                        timestamp,
                        startDayIndex,
                        isRedeemed: isRedeemed === 1 ? true : false,
                        dailyInterests: results[arrIndex][1],
                        ...uStaking
                    } 
                })

                setUserStakings(fetchedUerStakings);
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    }

    async function fetchUserPendingSkills() {
        if (isAuthenticated) {
            try {
                let uPendingSkills = await Promise.all(
                    sContracts.map((sContract) => {
                        return sContract.contract.methods.RoosterSkillRewards(userAddr).call()
                    })
                );

                uPendingSkills = uPendingSkills.map((totalSkills, i) => {
                    return {
                        pool: sContracts[i],
                        total: totalSkills
                    }
                }).filter((pendingSkill) => pendingSkill.total > 0)

                setPendingSkills(uPendingSkills);

            } catch (e) {
                return false;
            }
        }
    }

    async function fetchStakingsFilled() {
        try {
            return Promise.all(sContracts.map((sContract) => {
                if (sContract.contract) {
                    return sContract.contract.methods.getStakeFilled().call();
                } else {
                    throw new Error(`stop`);
                }
            })).then((results) => {
                setSContracts(sContracts.map((sContract, i) => {
                    return { ...sContract, filled: results[i] };
                }));
            })
        } catch (err) {
            console.log('failed to fetch staking filled');
        }
    }

    return (
        <>
            <div className="content-grid">
                <div className="section-banner">
                    <p className="section-banner-title">{t("profile.staking_heading")}</p>
                    <p className="section-banner-text">{t("profile.staking_description")}</p>
                </div>
                {!rbContext.isCorrectNetwork && <WrongNetwork/>}
                {rbContext.isCorrectNetwork && 
                    <>
                    <h2 className="section-heading">{t("profile.staking_heading_2")}</h2>
                    <div id="home-staking-packages" className="grid grid-4-4-4 top-space centered">
                        <Stakes11 />
                    </div>
                    
                    </>
                }
            </div>
            {user
                        && (
                            (userStakings && userStakings.length)
                            || (pendingSkills && pendingSkills.length)
                            || (pendingStakes4.length)
                            || (stakes4GameItems)
                            || (pendingStakes5.length)
                            || (stakes5GameItems)
                            || (pendingStakes6.length)
                            || (stakes6GameItems)
                            || (pendingStakes7.length)
                            || (stakes7GameItems)
                            || (pendingStakes8.length)
                            || (pendingStakes9.length)
                            || (pendingStakes10.length)
                        )
                        && 
            <div className="content-grid">
				<div className="section-banner">
					<p className="section-banner-title">{t("profile.staking_heading_4")}</p>
				</div>
				{!rbContext.isCorrectNetwork && <WrongNetwork/>}
				{rbContext.isCorrectNetwork && 
					<div id="home-staking-packages" className="grid grid-4-4-4 top-space centered">
                        <UserStakesGrid userStakings={userStakings} pendingSkills={pendingSkills} redeemCallback={redeem} redeemSkillsCallback={redeemSkills} />						
                        <UserStakes45 stakes4={pendingStakes4} stakes4GameItems={stakes4GameItems} stakes5={pendingStakes5} stakes5GameItems={stakes5GameItems} stakes6={pendingStakes6} stakes6GameItems={stakes6GameItems} stakes7={pendingStakes7} stakes7GameItems={stakes7GameItems}/>
                        <UserStakes8 stakes={pendingStakes8} />
                        <UserStakes9 stakes={pendingStakes9} />
                        <UserStakes10 stakes={pendingStakes10} poolOpen={true} />
                    </div>
				}
			</div>
            }
        </>
    );

}

export default StakingPage;
