import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import {
  Routes,
  Route, Link, useMatch, useLocation } from "react-router-dom";
import NFTItem from './components/profile/NFTItem';
import NFTAirdrops from './components/profile/NFTAirdrops';
import BattlesHistory from './components/profile/BattlesHistory';
import RewardClaim from './components/profile/RewardClaim';
import GoldClaim from './components/profile/GoldClaim';
import Scholars from './components/profile/Scholars';
import GameManagement from './components/profile/GameManagement';
import NFTTransfer from './components/profile/NFTTransfer';
import ProfileSettings from './components/profile/ProfileSettings';
import NFTListingItem from './components/marketplace/NFTListingItem';
import Extra from './Extra';
import RBContext from './RBContext';
import WrongNetwork from './components/WrongNetwork';
import { getRoosterName, getSkillName, getGameItemName } from './utils/RBUtils.js';
import ScholarBattlesHistory from './components/profile/ScholarBattlesHistory';
import MyScholarship from './components/profile/MyScholarship';
import { useTranslation } from "react-i18next";
import axios from 'axios';

function UserProfile(props){
  const rbContext = useContext(RBContext);
  let location = useLocation();
  const { t, i18n } = useTranslation();

  let [profile, setProfile] = useState(false);

  let [loading, setLoading] = useState(true);
  let [myItemsloading, setMyItemsLoading] = useState(true);

  const { user, Moralis } = useMoralis();

  let [myItems, updateMyItems] = useState([]);
  let [currentItemPage, setCurrentItemPage] = useState(1);
  let [myOffers, updatemyOffers] = useState([]);
  let [validSeller, setValidSeller] = useState(false);
  let [validNFTSeller, setValidNFTSeller] = useState(false);
  let [filter, setFilter] = useState('');
  let [NFTIds, setNFTIds] = useState([]);
  let [myItemsLoaded, setMyItemsLoaded] = useState(false);
  let [currentMarketOffers, updateMyCurrentMarketOffers] = useState([]);
  let [currentTab, setCurrentTab] = useState(1);
  
  const getAllNFTs = async (filter = '') => {
    if(user){
      try {
        setLoading(true);
        var currentOffers = currentMarketOffers;
        let currentUserAddress = user.get('ethAddress');

        if(filter === ''){
          var currentOffersIndex = await RBMARKETGAMEITEM_CONTRACT.methods.getMyGameItemOffers(currentUserAddress).call();
          console.log("Current Game Item Offers", currentOffersIndex);
          currentOffers = [];
          if(currentOffersIndex.length > 0){
            // validate with backend
            var params = {offerIndexes: currentOffersIndex,lang: i18n.language};
            var result = await Moralis.Cloud.run(process.env.REACT_APP_MORALIS_CLOUD_VALIDATEGAMEOFFERS, params);
            console.log("Sync gameitem offers", result);

            for(var i = 0; i < currentOffersIndex.length; i++){
              if(parseInt(currentOffersIndex[i]) > 0){
                var obj = {};
                obj.index = currentOffersIndex[i];

                var offer = await RBMARKETGAMEITEM_CONTRACT.methods.gameItemOffers(currentOffersIndex[i]).call();

                obj.offerAmount = offer.nftAmount;
                obj.tokenType = offer.gameItemType;

                if(parseInt(obj.tokenType) > 0){
                  currentOffers.push(obj);
                }
              }
            }
          }
          console.log(currentOffers);
          updateMyCurrentMarketOffers(currentOffers);
        }
        

        const contracts = [
          process.env.REACT_APP_RBHEN_ADDRESS.toLowerCase(),
          process.env.REACT_APP_RB_EGG_ADDRESS.toLowerCase(),
          process.env.REACT_APP_NFTCONTRACT_ADDRESS.toLowerCase(),
          process.env.REACT_APP_GAMEITEMCONTRACT_ADDRESS.toLowerCase(),
          ];
        var showing = 2;
        
        if(filter !== ''){
          showing = filter; // index of contracts 0 --> 3
        }

        var myNFTs = [];
        
        let NFTs = [];        

        try{
          
          const options = {params: {'nftAddress':contracts[showing], 'owner': currentUserAddress}};
          NFTs = await axios.get("https://coming.io/api/nft.php", options).catch(function(error){
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
  
            return false;
          });

        } catch(err){
          console.log(err);
          if(err.code == 141){
            // too many requests
            // do nothing. Let query try again
          } else {
            alert(err.error);
          }
          return false;
        }
                                            
        for(var i = 0; i < NFTs.data.length; i++) {
          myNFTs.push({id: NFTs.data[i].token_id, address: NFTs.data[i].token_address});
        }

        setNFTIds(myNFTs);

        // check for valid seller
        var isMarketActive = await marketContract.methods.Active().call();
        if(isMarketActive){
          setValidSeller(true);
          setValidNFTSeller(true);
        }

        var balanceOf = await nftContract.methods.balanceOf(currentUserAddress).call();
        if(balanceOf < 3){
          setValidSeller(false);
          setValidNFTSeller(false);
        }

        await getMyItems(myNFTs, 1, currentOffers); // first page

        setMyItemsLoading(false);
        setLoading(false);
      } catch (error) {
        const code = error.code;
        const message = error.message;
        console.log(code + ':' + message);
        setLoading(false);
      }
    }
  }

  const getMyItems = async (NFTIds, page = 1, gameitemOffers = []) => {
    if(NFTIds.length){
      setLoading(true);
      try {
        var myNFTs = [];
        var startIndex = (page - 1) * 16;
        
        var roosterIds = [];
        for(var i = startIndex; i < startIndex + 16; i++){
          if(NFTIds.length > i){
            if(NFTIds[i].address == process.env.REACT_APP_NFTCONTRACT_ADDRESS.toLowerCase()){
              roosterIds.push(NFTIds[i].id);
            } else {
              var obj = await getNFTDetail(NFTIds[i].id, NFTIds[i].address, gameitemOffers);
              myNFTs.push(obj);
            }
          }
        }

        if(roosterIds.length){
          var roosterData = false;
          try{
            roosterData = await Moralis.Cloud.run('getRBDetailByIds', {ids: roosterIds,lang: i18n.language});
          } catch(err){
            setLoading(false);
            console.log(err);
          }
          
          if(!roosterData){
            return;
          }

          for(var i = 0; i < roosterData.length; i++){
            let tokenDetail = {};
            let data = roosterData[i];
            tokenDetail.TokenId = data.id;
            
            tokenDetail.TokenType = 'ROOSTER';
            
            tokenDetail.Armors = data.armors;
            tokenDetail.Attack = data.attack;
            tokenDetail.Defense = data.defense;
            tokenDetail.ItemType = data.type;
            tokenDetail.Status = data.status;
            
            if(data.type >= 65){
              tokenDetail.TokenType = 'SKILL';
              tokenDetail.name = getSkillName(tokenDetail.ItemType);
            } else {
              tokenDetail.TokenType = 'ROOSTER';
              tokenDetail.name = tokenDetail.Status != 'egg' ? getRoosterName(tokenDetail.ItemType) : '?';
              tokenDetail.DisplayName = data.displayname ? data.displayname : '';
            }

            var offer = await marketContract.methods.roosterOffers(data.id).call();

            if(offer.seller != '0x0000000000000000000000000000000000000000'){
              tokenDetail.isOnSale = 1;
              console.log(data);
              if(data.privateOffer){
                tokenDetail.offerId = data.offerId;
              }
            } else {
              tokenDetail.isOnSale = 0;
            }
            myNFTs.push(tokenDetail);
          }
        }

        if(page > 1){
          myItems = myItems.concat(myNFTs);
        } else {
          myItems = myNFTs;
        }

        if(myItems.length == NFTIds.length){
          setMyItemsLoaded(true);
        }
        
        updateMyItems(myItems);
      
      setLoading(false);
    } catch (error) {
        const code = error.code;
        const message = error.message;
        console.log(code + ':' + message);
        setLoading(false);
    }
  }
}

const loadmoreItems = async() => {
  var page = currentItemPage + 1;
  setCurrentItemPage(currentItemPage + 1);
  await getMyItems(NFTIds, page, currentMarketOffers);

  return false;
}

const getNFTDetail = async (tokenId, tokenAddress, gameitemoffers = []) => {
    let currentUserAddress = user.get('ethAddress');

    let tokenDetail = {};
    tokenDetail.TokenId = tokenId;

    tokenDetail.TokenType = (tokenAddress == process.env.REACT_APP_NFTCONTRACT_ADDRESS.toLowerCase() ? 
    'ROOSTER' : (tokenAddress == process.env.REACT_APP_RB_EGG_ADDRESS.toLowerCase() ? 
    'TICKET': (tokenAddress == process.env.REACT_APP_RBHEN_ADDRESS.toLowerCase() ? 'HEN' : 'GAMEITEM')));
    
    if(tokenDetail.TokenType == 'ROOSTER'){
      var data = await Moralis.Cloud.run('getRBDetail', {id: tokenId,lang: i18n.language});
  
      tokenDetail.Armors = data.armors;
      tokenDetail.Attack = data.attack;
      tokenDetail.Defense = data.defense;
      tokenDetail.ItemType = data.type;
      tokenDetail.Status = data.status;
      
      if(data.type >= 65){
        tokenDetail.TokenType = 'SKILL';
        tokenDetail.name = getSkillName(tokenDetail.ItemType);
      } else {
        tokenDetail.TokenType = 'ROOSTER';
        tokenDetail.name = tokenDetail.Status != 'egg' ? getRoosterName(tokenDetail.ItemType) : '?';
        tokenDetail.displayName = data.displayname ? data.displayname : tokenDetail.name;
      }

      var offer = await marketContract.methods.roosterOffers(data.id).call();

      if(offer.seller != '0x0000000000000000000000000000000000000000'){
        tokenDetail.isOnSale = 1;
      } else {
        tokenDetail.isOnSale = 0;
      }
    } else if(tokenDetail.TokenType == 'GAMEITEM'){
      tokenDetail.isOnSale = 0;
      tokenDetail.Amount = await gameitemContract.methods.balanceOf(currentUserAddress, tokenId).call();
      
      tokenDetail.name = getGameItemName(tokenDetail.TokenId);
      
      // calculate the remaining amount if there are any current offers on marketplace
      if(gameitemoffers.length > 0){
        var itemOfferCount = 0;
        for(var i = 0; i < gameitemoffers.length; i++){
          if(gameitemoffers[i].tokenType == tokenId){
            itemOfferCount += parseInt(gameitemoffers[i].offerAmount);
          }
        }

        tokenDetail.Amount -= itemOfferCount;
      }
    } else if(tokenDetail.TokenType  == 'TICKET'){
      var offer = await RBMARKETEGG_CONTRACT.methods.Offers(tokenId).call();

      if(offer.seller != '0x0000000000000000000000000000000000000000'){
        tokenDetail.isOnSale = 1;
      } else {
        tokenDetail.isOnSale = 0;
      }
    } else if(tokenDetail.TokenType == 'HEN'){
      // do something
      var type = await RBHenContract.methods.Hens(tokenId).call();
      tokenDetail.henType = type;

      var offer = await RBMARKETHEN_CONTRACT.methods.henOffers(tokenId).call();

      if(offer.seller != '0x0000000000000000000000000000000000000000'){
        tokenDetail.isOnSale = 1;
      } else {
        tokenDetail.isOnSale = 0;
      }
    }

    return tokenDetail;
}

const getMyOffers = async () => {

  if(user){
    const params =  {
      skip: 0,
      useraddr: user.get('ethAddress'),
      lang: i18n.language
    };

    try {
        const results = await Moralis.Cloud.run(process.env.REACT_APP_MORALIS_CLOUD_GETMARKETOFFERS, params);

        updatemyOffers(results);
    } catch (error) {
        const code = error.code;
        const message = error.message;
        console.log(code + ":" + message);
    }
  }
}

  const filterItems = (e) => {
    setLoading(true);
    setMyItemsLoaded(false);
    var newFilter = e.target.value;

    if(newFilter == '' || newFilter == 'ROOSTER' || newFilter == 'SKILL'){
      if(filter == '' || filter == 'ROOSTER' || filter == 'SKILL'){
        setFilter(newFilter);
        setLoading(false);
      } else {
        updateMyItems([]);
        // reload
        setFilter(newFilter);
        getAllNFTs(2);
      }
      
    } else if(newFilter == 'TICKET'){
      updateMyItems([]);
      setFilter(newFilter);
      getAllNFTs(1);      
    } else if(newFilter == 'HEN'){
      updateMyItems([]);
      setFilter(newFilter);
      getAllNFTs(0);
    } else if(newFilter == 'GAMEITEM'){
      updateMyItems([]);
      setFilter(newFilter);
      getAllNFTs(3);
    }
  }

  const getMyProfile = async () => {
    try{
      var profile = await Moralis.Cloud.run('getMyInfo', {lang: i18n.language});
      console.log(profile);
      setProfile(profile);
    } catch(err){
      console.log(err);
    }
  }

  useEffect( () => {
    async function fetchData(){
      await getMyProfile();

      if(location.pathname == '/profile' || location.pathname == '/profile/'){

        try{
          getAllNFTs('');
        } catch(err){
          console.log(err);
        }
        
      }

      if(location.pathname == '/profile/offers'){
        getMyOffers();
      }
    }

    if(user){
      fetchData();
    }
    
  }, [user, location, rbContext]
  );

  const switchTab = (tab) => {
    setCurrentTab(tab);
  }

  useEffect(() => {
    Extra.updateContentGridPos();
  })

  if (!rbContext) {
      return null;
  }

  let { nftContract, marketContract, gameitemContract, RBMARKETEGG_CONTRACT, RBHenContract, RBMARKETHEN_CONTRACT, RBMARKETGAMEITEM_CONTRACT} = rbContext;

  return (
      user ? 
      <>
      <div className="content-grid">
        <div className="profile-header">
          <img src={process.env.REACT_APP_HOME_URL + "img/header-bg.png"} alt="My Assets"/>
          <div className="profile-header-info">
            <div className="user-short-description big">
              <a className="user-short-description-avatar user-avatar big" href="#">
                <div className="user-avatar-content">
                  <img width="120" src={(profile && profile.avatar) ? profile.avatar : (process.env.REACT_APP_HOME_URL + "assets/roosters/rooster_" + (1+ Math.floor(Math.random() * 6)) + ".png")} />
                  
                </div>
              </a>
              <p className="user-short-description-title"><a href="#">Hi, {(profile && profile.displayname) ? profile.displayname : user.get("ethAddress")}</a></p>
            </div>
          </div>
        </div>
        <nav className="section-navigation">
          <div id="section-navigation-slider" className="section-menu">
          <Link className={location.pathname == '/profile' ?  "section-menu-item active" : "section-menu-item"} to={'/profile'}>
            <svg className="section-menu-item-icon icon-wallet">
              <use xlinkHref="#svg-wallet"></use>
            </svg>
            <p className="section-menu-item-text">{t("profile.menu_my_assets")}</p>
          </Link>
          <Link className={location.pathname == '/profile/offers' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/offers'}>
              <svg className="section-menu-item-icon icon-earnings">
                <use xlinkHref="#svg-earnings"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_my_selling_items")}</p>
          </Link>
          <Link className={location.pathname == '/profile/rewards' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/rewards'}>
              <svg className="section-menu-item-icon icon-item">
                <use xlinkHref="#svg-item"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_my_airdrops")}</p>
            </Link>
            <Link className={location.pathname == '/profile/claims' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/claims'}>
              <svg className="section-menu-item-icon icon-badges">
                <use xlinkHref="#svg-badges"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_rice_reward")}</p>
            </Link>
            <Link className={location.pathname == '/profile/golds' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/golds'}>
              <svg className="section-menu-item-icon icon-badges">
                <use xlinkHref="#svg-badges"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_gold_reward")}</p>
            </Link>
            <Link className={location.pathname == '/profile/battles' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/battles'}>
              <svg className="section-menu-item-icon icon-trophy">
                <use xlinkHref="#svg-trophy"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_battles_history")}</p>
            </Link>
            <Link className={location.pathname == '/profile/settings' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/settings'}>
              <svg className="section-menu-item-icon icon-profile">
                <use xlinkHref="#svg-profile"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_profile_settings")}</p>
            </Link>
            <Link className={location.pathname == '/profile/scholars' ?  "section-menu-item active" : "section-menu-item"} to={'/profile/scholars'}>
              <svg className="section-menu-item-icon icon-group">
                <use xlinkHref="#svg-group"></use>
              </svg>
              <p className="section-menu-item-text">{t("profile.menu_scholars")}</p>
            </Link>
          </div>
          <div id="section-navigation-slider-controls" className="slider-controls">
            <div className="slider-control left">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
            <div className="slider-control right">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
          </div>
        </nav>
        <section className="section">
        {!rbContext.isCorrectNetwork && <WrongNetwork/>}
        {rbContext.isCorrectNetwork &&
        <Routes>
          <Route path="game" element={<GameManagement />} />
          <Route path="settings" element={<ProfileSettings profile={profile} />} />
          <Route path="claims" element={<RewardClaim />} />
          <Route path="golds" element={<GoldClaim />} />
          <Route path="battles" element={<BattlesHistory />} />
          <Route path="battles/scholar" element={<ScholarBattlesHistory />} />
          <Route path="scholars" element={<Scholars />} />
          <Route path="scholars/scholarship" element={<MyScholarship />} />
          <Route path="rewards" element={<div className="grid grid-3-3-3-3 centered">
              <NFTAirdrops />
            </div>} />
          <Route path="offers" element={<>{
              ((myOffers.length == 0) &&

                <div className="centered white comingsoon">{t("profile.text_you_dont_have_any_offer")}</div>
              )
            }
            {
              (myOffers.length > 0) &&
              <div className="grid grid-3-3-3-3 centered">
              {myOffers.map((item, index) => (
                <NFTListingItem key={"NFT-" + index} token={item} onRemoved={getMyOffers}/>
              ))}
              </div>
            }</>} />
          <Route path='*' element={
              <div className='tab-box transparent'>
                <div className='tab-box-options'>
                    <div onClick={()=>{switchTab(1)}} className={currentTab == 1 ? "tab-box-option active" : "tab-box-option"}>
                    {t("profile.menu_my_nfts")}
                    </div>
                    <div onClick={()=>{switchTab(2)}} className={currentTab == 2 ? "tab-box-option active" : "tab-box-option"}>
                    {t("profile.menu_nft_transfer")}
                    </div>
                </div>
                <div className='tab-box-tems'>
                    <div className='tab-box-item' style={currentTab == 1 ? {} : {display: 'none'}}>
                      <div className="section-header">
                        <div className="section-header-info">
                          {
                            (filter == '') &&
                            <h2 className="section-title">{t("profile.nft_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                          {
                            (filter == 'ROOSTER') &&
                            <h2 className="section-title">{t("profile.roosters_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                          {
                            filter == 'HEN' &&
                            <h2 className="section-title">{t("profile.hens_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                          {
                            filter == 'SKILL' &&
                            <h2 className="section-title">{t("profile.skills_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                          {
                            filter == 'GAMEITEM' &&
                            <h2 className="section-title">{t("profile.gameitems_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                          {
                            filter == 'TICKET' &&
                            <h2 className="section-title">{t("profile.eggs_loaded", {loaded: myItems.length, total: NFTIds.length})}</h2>
                          }
                        </div>
                        <div className="section-header-actions">
                          <select name="order" onChange={filterItems} value={filter}>
                            <option value="">{t("general.nft_roosters_skills")}</option>
                            <option value="ROOSTER">{t("general.nft_roosters")}</option>
                            <option value="SKILL">{t("general.nft_skills")}</option>
                            <option value="HEN">{t("general.nft_hens")}</option>
                            <option value="GAMEITEM">{t("general.nft_gameitems")}</option>
                            <option value="TICKET">{t("general.nft_tickets")}</option>
                          </select>
                        </div>
                      </div>

                      {myItemsloading &&
                      <p className="centered white">{t("general.loading")}</p>
                      }
                      {
                        (!myItemsloading && NFTIds.length == 0)
                        && <div className="centered white comingsoon">{t("profile.text_you_dont_have_any_item")}</div>
                      }

                      {(!myItemsloading && NFTIds.length > 0) &&
                        <>

                        <div className="grid grid-3-3-3-3 centered">
                          {
                          myItems.map((item, index) => (
                            <NFTItem token={item} filter={filter} validSeller={validSeller} validNFTSeller={validNFTSeller} key={index + '-' + item.TokenType+"-"+item.TokenId}/>
                          ))
                          }
                        </div>
                        { !myItemsLoaded && 
                        <div id="btnLoadMore" className="block centered">
                          {
                            !loading &&
                            <a href="#" className="button btn-primary" onClick={(evt) => {evt.stopPropagation();evt.preventDefault();loadmoreItems();}}>{t("general.loadmore")}</a>
                          }
                          {
                            loading &&
                            <div className="page-loader-indicator loader-bars">
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                              <div className="loader-bar"></div>
                            </div>
                          }    
                        </div>
                        }
                        </>
                      }
                    </div>
                    <div className='tab-box-item' style={currentTab == 2 ? {} : {display: 'none'}}>
                      <NFTTransfer />
                    </div>
                </div>
              </div>} />
        </Routes>
        }
        </section>
        </div>
      </>
      :
      <>
        <div className="content-grid">
          <p className='white centered'><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>{t("error.please_connect_wallet")}<br/><br/><br/></p>
        </div>
      </>
    );
}
export default UserProfile;
