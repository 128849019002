import { useContext } from "react";
import RBContext from "../RBContext";

function WrongNetwork () {
    const rbContext = useContext(RBContext);

    if (!rbContext.isCorrectNetwork) {
        return <>
            <div className="top-space centered" style={{margin: "100px 0px"}}>
                <h2 style={{color: "#fff", margin: ".5em 0"}}>You're not connecting to the network</h2>
                <h4 style={{color: "#fff", margin: ".5em 0"}}>Please switch to "{process.env.REACT_APP_WEB3_PROVIDER_LABEL}" and start connecting</h4>
            </div>
        </>;
    }

    return null;
}

export default WrongNetwork;
