import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { showError, toLocalDateTime } from './../../utils/RBUtils.js';
import { useTranslation, Trans } from "react-i18next";

function UserStakes9(props) {
  const { t, i18n } = useTranslation(); 
  let [isLoading, setIsLoading] = useState(false);
  
  let [stakes, setStakes] = useState([]);

  const { Moralis, user } = useMoralis();
  
  const rbContext = useContext(RBContext);

  useEffect(() => {
    async function initData(){
      if(user){
        setStakes(props.stakes);
      }
    }
    initData();
  }, [user, props, rbContext])

  const redeem = async (index, stakeIndex) => {
    if(!isLoading){
      try{
        setIsLoading(true);
        var result = await RBPool9Contract.methods.redeem(stakeIndex).send({from: user.get("ethAddress")});

        alert(t("staking.alert_redeem_ok"));

        setIsLoading(false);
        stakes.splice(index, 1);
        setStakes(stakes);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  const redeemGold = async (index, stakeIndex) => {
    if(!isLoading){
      try{
        setIsLoading(true);
        var result = await RBPool9Contract.methods.redeemGold(stakeIndex).send({from: user.get("ethAddress")});

        alert(t("staking.alert_redeem_ok"));

        stakes[index].availableGold = 0;

        setStakes(stakes);
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  if (!rbContext) {
    return;
  }


  let { RBPool9Contract } = rbContext;

  return (
    <>
      {stakes.map((stake, index)=> {
        return (
          <div className="badge-item-stat" key={"Stake9-" + index}>
            <p className="text-sticker">
                <svg className="text-sticker-icon icon-plus-small">
                  <use xlinkHref="#svg-plus-small"></use>
                </svg>
                {t("staking.pool_9_name")}
            </p>
            <p className="badge-item-stat-title">{t("staking.pool_9_intro")}</p>
            
            <div className="badge-item-stat-text table table-quests split-rows">
              <div className="table-body same-color-rows">
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.earn")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {t("staking.pool_9_name")}
                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.period")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {t("general.none")}
                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.staked_at")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {toLocalDateTime(stake.timestamp)}
                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.pool_9_available_gold")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {stake.availableGold}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {
            <p className="badge-item-stat-text">
              <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); redeem(index, stake.stakeIndex);}}>{!isLoading? t("staking.redeem_rooster_golds") : t("general.loading")}</button>
            </p>
            }
          </div>
        );
      })}
      
    </>
  );
}
export default UserStakes9;
