import i18n from "i18next";

export function getNFTCategory(itemType){
  var name = "?";
  switch(itemType){
    case 1:
    name = i18n.t("general.nft_rooster");
    break;
  case 2:
    name = i18n.t("general.nft_gameitem");
    break;
  case 3:
    name = i18n.t("general.nft_skill");
    break;
  case 4:
    name = i18n.t("general.nft_egg");
    break;
  case 5:
    name = i18n.t("general.nft_hen");
    break;
  default:
    break;
  }

  return name;
}

export function getRoosterName(itemType, type = 'ROOSTER'){
  let actualType = parseInt(itemType);
  if(type == 'SKILL'){
    actualType = 1 + (itemType % 65);
  }
    

    let name = "?";
    if(type == 'ROOSTER'){
      switch(actualType){
        case 1:
          name = i18n.t("rooster.skyscales");
            break;
        case 2:
          name = i18n.t("rooster.fourizontal");
            break;
        case 3:
          name = i18n.t("rooster.earthcover");
            break;
        case 4:
          name = i18n.t("rooster.blackdragon");
          break;
        case 5:
          name = i18n.t("rooster.moonpalace");
            break;
        case 6:
          name = i18n.t("rooster.whitespirit");
            break;
        case 7:
          name = i18n.t("rooster.caramuru"); // Brazil
            break;
        case 8:
          name = i18n.t("rooster.epic"); // Phil
            break;
        case 9:
          name = i18n.t("rooster.epic"); // China
            break;
        case 10:
          name = i18n.t("rooster.epic"); // Thailand
            break;
        case 11:
          name = i18n.t("rooster.epic");  // VN
            break;
        case 12:
          name = i18n.t("rooster.tiger"); // Tiger (legendary)
            break;
        case 13:
          name = i18n.t("rooster.golden"); // Golden God (legendary)
            break;
        case 14:
          name = i18n.t("rooster.samurai"); // Samurai (legendary)
            break;
        case 15:
          name = i18n.t("rooster.robot"); // Robot (legendary)
            break;
        case 50:
          name = "Super Goku";// (legendary)
          break;
        case 52:
          name = "ZeuV";// (legendary)
          break;
        default: break;
      };
    }    

    return name;
}

export function getArmoredLevel(level){
  switch(level){
    case 1: 
      return i18n.t('rooster.armored_bronze');
    case 2:
      return i18n.t('rooster.armored_silver');
    case 3:
      return i18n.t('rooster.armored_golden');
    default: break;
  }
}

export function getBalanceNumber(balance){
  if(balance && balance != "0"){
    return parseInt(balance.substring(0, balance.length - 18));
  }

  return 0;
}

export function getGameItemName(itemType){
  const actualType = parseInt(itemType);

  const skillNames = [i18n.t("gameitem.thunderblood"),  
                  i18n.t("gameitem.cricket"),
                  i18n.t("gameitem.grasshopper"),
                  i18n.t("gameitem.lizard"),
                  i18n.t("gameitem.goldenorder"), 
                  i18n.t("gameitem.apricotflower"), 
                  i18n.t("gameitem.goldenfeather"),
                  i18n.t("gameitem.snowball"),
                  i18n.t("gameitem.blackstone"),
                  i18n.t("gameitem.eel"),
                  i18n.t("gameitem.frog"),
                  i18n.t("gameitem.centipede")];

  return skillNames[actualType - 1];
}

export function getGameItemEffect(itemType){
  const actualType = parseInt(itemType);
  switch(actualType){
    case 1:
      return i18n.t("gameitem.effect_revive_rooster");
    case 2:
      return i18n.t("gameitem.effect_heal_10");
    case 3:
      return i18n.t("gameitem.effect_heal_20");
    case 4:
      return i18n.t("gameitem.effect_heal_30");
    default:
      return i18n.t("gameitem.effect_enhancement");
  }
}

export function getSkillName(itemType){
  const actualType = parseInt(itemType - 65) + 1;

  let name = "";
  switch(actualType){
    case 1:
      name = i18n.t("skill.poisonstrike");
      break;
    case 2:
      name = i18n.t("skill.frozenslash");
      break;
    case 3:
      name = i18n.t("skill.chaostrike");
      break;
    case 4:
      name = i18n.t("skill.firetornado");
      break;
    case 5:
      name = i18n.t("skill.electricslash");
      break;
    case 6:
      name = i18n.t("skill.earthslam");
      break;
    default: break;
  };

  return name;
}

export function getRoosterDescription(itemType, type = "ROOSTER"){
  const actualType = 1 + (itemType % 6);

  let name = "";
  switch(actualType){
    default: 
      name = i18n.t("rooster.description_pending");
      break;
  };

  return name;
}

/**
 * 
 * @param {uint: eggType, uint: timestamp} eggInfo - Get from NFT EGG contract
 */
export function addEggInfo(eggInfo){
  if(eggInfo.eggType == '1' || eggInfo.eggType == '4'){
    eggInfo.eggName = i18n.t("egg.type_hen");
    eggInfo.eggThumb = 'hen';
  } else if(eggInfo.eggType == '2' || eggInfo.eggType == '5'){
    eggInfo.eggName = i18n.t("egg.type_rooster");
    eggInfo.eggThumb = 'rooster';
  } else if(eggInfo.eggType == '3'){
    eggInfo.eggName = i18n.t("egg.type_food");
    eggInfo.eggThumb = 'food';
  }

  if(parseInt(eggInfo.eggType) < 4){
    eggInfo.hatchable = i18n.t("general.now");
  } else {
    var opentimestamp = parseInt(eggInfo.timestamp) + 3 * 86400;
    if(opentimestamp < Date.now() / 1000){
      eggInfo.hatchable = i18n.t("general.now");
    } else {
      var date = new Date(opentimestamp * 1000);
      eggInfo.hatchable = date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }
  }

  return eggInfo;
}

/**
 * Alert error message from a contract call
 * @param {*} err 
 */
export function showError(err){
  console.log(err);
  switch (err.code) {
    case 4001:
      // do nothing
      break;
    case -32602:
      alert(i18n.t("error.please_reconnect"));
      break;
    case -32603:
      //"Internal JSON-RPC error."
      var data = err.data;
      console.log(data);
      switch(data.code){
        case 3:
          alert(i18n.t("error.nft_does_not_exist"));
          break;
        default:
          alert(data.message);
      }
      break;
    default:
      alert(err.message);
      break;
  }
}

export function toLocalDateTime(timestamp){
  return new Date(timestamp).toLocaleDateString() + " " + new Date(timestamp).toLocaleTimeString();
}

String.prototype.replaceAt = function(index, replacement) {
  if (index >= this.length) {
      return this.valueOf();
  }

  return this.substring(0, index) + replacement + this.substring(index + 1);
}

export function walletMask(wallet){
  wallet = wallet.replaceAt(6,'.');
  wallet = wallet.replaceAt(7,'.');
  wallet = wallet.replaceAt(8,'.');
  for(var i = 9; i < 38; i++){
    wallet = wallet.replaceAt(9,'');
  }

  return wallet;
   
}