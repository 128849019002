import {
  Link
} from "react-router-dom";
import Extra from './../Extra';

function NavigationWidget(props){
  return (
    <nav id="navigation-widget-mobile" className="navigation-widget navigation-widget-mobile sidebar left hidden" data-simplebar>
      <div className="navigation-widget-close-button">
        <svg className="navigation-widget-close-button-icon icon-back-arrow">
          <use xlinkHref="#svg-back-arrow"></use>
        </svg>
      </div>
      <Link className="navigation-widget-section-link" to="/" onClick={()=>{Extra.mobileWidgetToggle();}}>Marketplace</Link>
       <Link className="navigation-widget-section-link" to="/Staking" onClick={()=>{Extra.mobileWidgetToggle();}}>Staking</Link>
      <Link className="navigation-widget-section-link" to="/shop" onClick={()=>{Extra.mobileWidgetToggle();}}>Shop</Link>
      <Link className="navigation-widget-section-link" to="/leaderboard" onClick={()=>{Extra.mobileWidgetToggle();}}>Leaderboard</Link>
      <Link className="navigation-widget-section-link" to="/profile" onClick={()=>{Extra.mobileWidgetToggle();}}>Profile</Link>
    </nav>
  );
}

export default NavigationWidget;
