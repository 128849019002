import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import {addEggInfo, getArmoredLevel, getRoosterName} from './../../utils/RBUtils';
import NFTThumb from './../../components/common/NFTThumb';
import {
  Link
} from "react-router-dom";
import { useTranslation } from "react-i18next";
function NFTItem(props){
  const rbContext = useContext(RBContext);
  const { Moralis, user } = useMoralis();

  const tokenDetail = props.token;
  let [loading, setLoading] = useState(false);
  let [validSeller, setValidSeller] = useState(false);
  let [isDev, setIsDev] = useState(false);
  let [eggInfo, setEggInfo] = useState(false);
  let [skillReroll, setSkillReroll] = useState(0);
  let [bredTimes, setBredTimes] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function init() {
      if(user){
        // validate seller:
        if(props.validSeller){
          if(tokenDetail.TokenType != "GAMEITEM" || (tokenDetail.TokenType == "GAMEITEM" && tokenDetail.Amount > 0)) {
            setValidSeller(true);
          }
        }

        if(tokenDetail.TokenType == 'TICKET'){
          var eggInfo = await RBEGG_CONTRACT.methods.Eggs(tokenDetail.TokenId).call();
          eggInfo = addEggInfo(eggInfo);

          setEggInfo(eggInfo);
        }

        if(tokenDetail.TokenType == 'SKILL'){
          var skillReroll = await RBBLACKSMITH_CONTRACT.methods.RerollCount(tokenDetail.TokenId).call();
          setSkillReroll(skillReroll);
        }

        if(tokenDetail.TokenType == 'HEN'){
          var bredTimes = await RBBREEDINGF1_CONTRACT.methods.HenUsed(tokenDetail.TokenId).call();
          setBredTimes(bredTimes);
        }

        setLoading(false);
      }   

    }

    init();
  }
  , [rbContext, user, props.validSeller, props.token]);

  if (!rbContext) {
      return null;
  }

  let { web3, nftContract, marketContract, 
    RBEGG_CONTRACT, RBMARKETEGG_CONTRACT, 
    minterContract, RBHenContract, 
    RBMARKETHEN_CONTRACT, gameitemContract, 
    RBMARKETGAMEITEM_CONTRACT, RBBLACKSMITH_CONTRACT, RBBREEDINGF1_CONTRACT } = rbContext;


  const sellRooster = async (tokenId, privateOffer = false) => {
    try {
      var roosterStatus = await Moralis.Cloud.run('getRoosterStatus', {roosterId: tokenId, lang: i18n.language});
      //console.log(roosterStatus);
      if(roosterStatus.isCamping || roosterStatus.isDojo){
        alert(t("error.cannot_sell_rooster"));
        return;
      }

      var currentUserAddress = user.get('ethAddress');
      console.log('Current user: ' , currentUserAddress);
      var price = prompt(t("error.prompt_how_many_rices"), '');
      
      if(price){
        price = parseInt(price);
        
        if(Number.isInteger(price)){
          if(window.confirm(t("error.confirm_making_1_offer_at", {price: price}))){
            
            if(privateOffer){
              if(price < 1000){
                alert("Private offer must be 1000 RICEs minimum");
                setLoading(false);
                return;
              }
            }
            var result = await marketContract.methods.makeRoosterOffer(tokenId, web3.utils.toWei(price+"")).send({from: currentUserAddress});

            const params = {tokenId: tokenId, type: tokenDetail.TokenType, privateOffer: privateOffer, lang: i18n.language};
            var result = await Moralis.Cloud.run('makeRoosterOffer', params);

            if(result !== "INVALID"){
              alert(t("error.offer_placed_ok"));
              tokenDetail.isOnSale = 1;
              // OKE. DO WHAT NOW?
            } else {
              alert(t("error.offer_placed_failed"));
            }
          }         
        } else {
          alert(t("error.invalid_price"));
        }
      }
    } catch(err) {
      if(err.code == -32602){
        alert(t("error.please_reconnect_wallet"));
      } else{
        alert(err.message);
      }
      console.log(err);
    }

    setLoading(false);

  }

  const sellHen = async (tokenId) => {
    try {
      var currentUserAddress = user.get('ethAddress');
      var price = prompt(t("error.prompt_how_many_rices"), '');
      
      if(price){
        price = parseInt(price);
        
        if(Number.isInteger(price)){
          if(window.confirm(t("error.confirm_making_1_hen_at", {price:price}))){
            var result = await RBMARKETHEN_CONTRACT.methods.makeOffer(tokenId, web3.utils.toWei(price+"")).send({from: currentUserAddress});

            const params = {tokenId: tokenId, lang: i18n.language};
            var result = await Moralis.Cloud.run('makeHenOffer', params);

            if(result !== "INVALID"){
              alert(t("error.offer_placed_ok"));
              tokenDetail.isOnSale = 1;
              // OKE. DO WHAT NOW?
            } else {
              alert(t("error.offer_placed_failed"));
            } 
          }         
        } else {
          alert(t("error.invalid_price"));
        }
      }
    } catch(err) {
      if(err.code == -32602){
        alert(t("error.please_reconnect_wallet"));
      } else{
        alert(err.message);
      }
      console.log(err);
    }

    setLoading(false);

  }

  async function sellTicket(tokenId) {  
    setLoading(true);
    try {
      var currentUserAddress = user.get('ethAddress');

      var price = prompt(t("error.prompt_how_many_rices"), '');
      if (price) {
        price = parseInt(price);
        if (Number.isInteger(price)) {
          var result = await RBMARKETEGG_CONTRACT.methods.makeOffer(tokenId, web3.utils.toWei(price + "")).send({ from: currentUserAddress });
        } else {
          alert(t("error.invalid_price"));
        }
      }

      const params = { tokenId: tokenId, type: tokenDetail.TokenType,lang: i18n.language };
      var result = await Moralis.Cloud.run('makeTicketOffer', params);

      if (result !== "INVALID") {
        alert(t("error.offer_placed_ok"));
        tokenDetail.isOnSale = 1;
        // OKE. DO WHAT NOW?
      } else {
        alert(t("error.offer_placed_failed"));
      } 
    } catch (err) {
      console.log(err);
    }

    setLoading(false);

  }

  const sellIt = async (tokenId, privateOffer = false) => {
    
    setLoading(true);
    try {
      var currentUserAddress = user.get('ethAddress');
      
      let approved = false; 
      if(tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL'){
        approved  = await nftContract.methods.isApprovedForAll(currentUserAddress, process.env.REACT_APP_MARKETCONTRACT_ADDRESS).call();
      }else if(tokenDetail.TokenType == 'TICKET'){
        approved  = await RBEGG_CONTRACT.methods.isApprovedForAll(currentUserAddress, process.env.REACT_APP_RB_MARKETEGG_ADDRESS).call();
      } else if(tokenDetail.TokenType == 'HEN'){
        approved  = await RBHenContract.methods.isApprovedForAll(currentUserAddress, process.env.REACT_APP_RBMARKETPLACE_HEN_ADDRESS).call();
      } else {
        approved  = await gameitemContract.methods.isApprovedForAll(currentUserAddress, process.env.REACT_APP_MARKETGAMEITEM_CONTRACT_ADDRESS).call();
      } 

      if(!approved) {
        // allow for marketplace to sell all items
        alert(t("error.nft_approval"));

        let result = false;
        
        if(tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL'){
          result  = await nftContract.methods.setApprovalForAll(process.env.REACT_APP_MARKETCONTRACT_ADDRESS, true).send({from: currentUserAddress});
        } else if(tokenDetail.TokenType == 'TICKET') {
          result  = await RBEGG_CONTRACT.methods.setApprovalForAll(process.env.REACT_APP_RB_MARKETEGG_ADDRESS, true).send({from: currentUserAddress});
        } else if(tokenDetail.TokenType == 'HEN'){
          result  = await RBHenContract.methods.setApprovalForAll(process.env.REACT_APP_RBMARKETPLACE_HEN_ADDRESS, true).send({from: currentUserAddress});
        } else {
          result  = await gameitemContract.methods.setApprovalForAll(process.env.REACT_APP_MARKETGAMEITEM_CONTRACT_ADDRESS, true).send({from: currentUserAddress});
        }

        if(!result || !result.status){
          alert(t("error.cannot_sell_item"));
          setLoading(false);
        } else {
          approved = true;
        }
      }

      if(approved){
        if(tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL'){
          sellRooster(tokenId, privateOffer);
        }else if(tokenDetail.TokenType == 'TICKET'){
          sellTicket(tokenId);
        } else if(tokenDetail.TokenType == 'HEN'){
          sellHen(tokenId);
        } else {
          sellGameItem(tokenId);
        }
      }
    } catch(err) {
      setLoading(false);
      if(err.code == -32602){
        alert(t("error.please_reconnect_wallet"));
      } else {
        alert(err.message);
      }
    }

    return false;
  }

  const sellGameItem = async (tokenType) => {
    try {

      var currentUserAddress = user.get('ethAddress');
      var amount = prompt(t("error.promp_how_many_gameitems", {max: tokenDetail.Amount}));
      if(amount !== '' ){
        amount = parseInt(amount);
        if(!Number.isInteger(amount) || amount > tokenDetail.Amount){
          alert('Invalid Amount');
          setLoading(false);
          return false;
        }

        var price = prompt(t("error.prompt_how_many_rices_items", {amount: amount}), '');
        if(price !== ''){
          price = parseInt(price);
          if(Number.isInteger(price)){
            if(window.confirm(t("error.confirm_making_gameitems_offer_at", {amount: amount, price: price}))){
              var result = await RBMARKETGAMEITEM_CONTRACT.methods.makeGameItemOffer(tokenType, web3.utils.toWei(price+""), amount).send({from: currentUserAddress});
            }
          } else {
            alert(t("error.invalid_price"));
          }
        }

        alert(t("error.offer_placed_ok"));
        tokenDetail.Amount -= amount;

        var currentOffersIndex = await RBMARKETGAMEITEM_CONTRACT.methods.getMyGameItemOffers(currentUserAddress).call();
        console.log(currentOffersIndex);
        if(currentOffersIndex.length > 0){
          // validate with backend
          var params = {offerIndexes: currentOffersIndex,lang: i18n.language};
          var result = await Moralis.Cloud.run(process.env.REACT_APP_MORALIS_CLOUD_VALIDATEGAMEOFFERS, params);
          console.log(result);
        }
      }
    } catch(err) {
      console.log(err);
    }

    setLoading(false);
  }

  const airdropTicket = async(tokenId)=>{
    setLoading(true);
    try{
        var receiver = prompt("Please specify airdrop address to mint NFT");
        if(receiver){
          const currentUserAddress = user.get('ethAddress');
          
          await minterContract.methods.airdropTicket(tokenId, receiver, Math.floor(Math.random() * 10000)).send({from:currentUserAddress});

          alert('Done');
          setLoading(false);
          setIsDev(false);
        }
    }catch(err){
      setLoading(false);
      alert(err.message);
    }
  }

  const devAirdropButton = () => {
    if(tokenDetail.TokenType == 'TICKET'){
      if(isDev){
        return (<a className="button btn-block btn-sendToMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();airdropTicket(tokenDetail.TokenId);}}>Airdrop Ticket</a>);
      }
    }
  }

  const reSyncOffer = async (privateOffer = false) => {
    const params = { tokenId: tokenDetail.TokenId, type: tokenDetail.TokenType, privateOffer: privateOffer,lang: i18n.language };
    try{
      setLoading(true);
      var result = await Moralis.Cloud.run('syncMarketOffer', params);
      alert(t("error.offer_synced"));
      console.log(result);
      setLoading(false);
    } catch(err){
      alert(err.message);
      setLoading(false);
    }
  }

  const buttonActions = () => {
    if(tokenDetail.isOnSale){
      return (<>
      <span><a className="button btn-block btn-sendToMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();reSyncOffer();}}>{t("profile.resync_offer")}</a></span>
      {(user && user.get("ethAddress") && user.get("ethAddress").toLowerCase() == '0x28fa36453e8421440d786b26eee42722e5e4fa44' && (tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL')) && <span><a className="button btn-block btn-sendToMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();reSyncOffer(true);}}>Re-sync private offer to Marketplace</a></span>}
            </>);
    } else {
      if(validSeller){
        if(tokenDetail.TokenType == 'TICKET' || (tokenDetail.TokenType != 'TICKET' && props.validNFTSeller)){
          if(tokenDetail.Status != 'egg'){
            return (<>
            {devAirdropButton()}
            <a className="button btn-block btn-sendToMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();sellIt(tokenDetail.TokenId);}}>{t("profile.sellit")}</a>
            {(user && user.get("ethAddress") && user.get("ethAddress").toLowerCase() == '0x28fa36453e8421440d786b26eee42722e5e4fa44' && (tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL')) && <a className="button btn-block btn-sendToMarket" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();sellIt(tokenDetail.TokenId, true);}}>Sell privately</a>}
            
            </>)
          }
        } else {
          return (<span className="btn-block">{t("error.require_3_nfts")}</span>)
        }
      } else {
        if(tokenDetail.TokenType == "GAMEITEM"){
          return (<span className="btn-block margin">{t("error.cannot_sell_or_on_sale")}</span>)
        } else {
          return (<span className="btn-block margin">{t("error.cannot_make_offer")}</span>)
        }
        
      }

      
    }
  }

  const amount_el = (amount) => {
    return (
      <p className="product-preview-text">{t("gameitem.amount", {amount: amount})}</p>
    );
  }

  const attack_defense_el = (attack, defense) => {
    if(tokenDetail.Status != 'egg' && attack && defense){
      return (
        <>
        <p className="product-preview-text">{t("rooster.attack_detail", {slots: attack.join(', ')})}</p>
        <p className="product-preview-text">{t("rooster.defense_detail", {slots: defense.join(', ')})}</p>
        </>
      );
    } else {
      return (
        <>
        <p className="product-preview-text">{t("rooster.attack_detail", {slots: "?"})}</p>
        <p className="product-preview-text">{t("rooster.defense_detail", {slots: "?"})}</p>
        </>
      );
    }
  }

  const _marketoffer_linktype = (tokenType) => {
    var link = "";
    switch(tokenType){
      case "ROOSTER":
        link = 1;
        break;
      case "SKILL":
        link = 3;
        break;
      case "GAMEITEM":
        link = 2;
        break;
      case "TICKET":
        link = 4;
        break;
      case "HEN":
        link = 5;
        break;
      default:
        break;
    }

    return link;
  }

  return (
      <>
        {(props.filter == "" || props.filter == tokenDetail.TokenType) && 
        <div className="product-preview">
          <NFTThumb TokenType={tokenDetail.TokenType} 
            Armors={tokenDetail.Armors}
            Status={tokenDetail.Status} 
            ItemType={tokenDetail.TokenType == 'HEN' ? tokenDetail.henType : tokenDetail.ItemType} 
            TokenId={tokenDetail.TokenId} 
            EggThumb={eggInfo ? eggInfo.eggThumb : ''}/>
          
          <div className="product-preview-info">
            <p className="product-preview-title">
            {tokenDetail.isOnSale == 1 &&
              <>#{tokenDetail.TokenId} <Link to={'marketplace/' + _marketoffer_linktype(tokenDetail.TokenType) + '/' + (tokenDetail.offerId ? tokenDetail.offerId : tokenDetail.TokenId)}>{t("profile.viewoffer")}</Link></>
            }
            {tokenDetail.isOnSale == 0 &&
              <span>#{tokenDetail.TokenId}</span>
            }
            </p>
            <p className="product-preview-category"><a href="#">{t("profile.nft_type", {type: tokenDetail.TokenType == 'TICKET' ? (eggInfo ? eggInfo.eggName : '') : tokenDetail.TokenType + (bredTimes == 7 ? " (" + t("general.epic") + ")":"")})}</a></p>
            <p className="product-preview-text">{tokenDetail.TokenType == 'TICKET' ? (eggInfo ? t("marketplace.egg_hatch_time", {time: eggInfo.hatchable}) : '') : (tokenDetail.DisplayName ? (tokenDetail.DisplayName + " (" + tokenDetail.name + ")") : tokenDetail.name)}</p>
            
            {tokenDetail.TokenType == 'TICKET' && <p className="product-preview-text">{t("marketplace.egg_can_be_hatched_in_game")}</p>}
            {tokenDetail.TokenType == 'GAMEITEM' && amount_el(tokenDetail.Amount)}
            {(tokenDetail.TokenType == 'ROOSTER' || tokenDetail.TokenType == 'SKILL') && attack_defense_el(tokenDetail.Attack, tokenDetail.Defense)}
            {(tokenDetail.TokenType == 'ROOSTER') ? ((tokenDetail.Armors && tokenDetail.Armors.length == 4 && tokenDetail.Armors[0] >= 1) ? <><p className="product-preview-text"><strong>{parseInt(tokenDetail.Armors[0]) == 4 ? tokenDetail.DisplayName : (t("general.armored_rooster") - getArmoredLevel(parseInt(tokenDetail.Armors[0]) ) ) }</strong><br/><br/><br/></p></> : <><p><br/><br/><br/><br/><br/></p></>) : <></>}
            {(tokenDetail.TokenType == 'SKILL') && <p className="product-preview-text">{t("rooster.current_level", {level: tokenDetail.Armors.length ? parseInt(tokenDetail.Armors[0]) : 0})} {(tokenDetail.Armors.length && tokenDetail.Armors[0] == 1) ? <>(&#9733;)</> : <></>}</p>}
            {(tokenDetail.TokenType == 'SKILL') && <p className="product-preview-text">{t("rooster.reroll_count", {count: skillReroll})}</p>}
            {tokenDetail.TokenType == 'HEN' && 
            <>
            <p className="product-preview-text">{t("marketplace.hen_intro")}<br/><br/></p>
            <p className="product-preview-text">{t("marketplace.breeding_used", {count: bredTimes})}<br/><br/></p>
            </>
            }
            <p className="badge-item-stat-text">
              {!loading && buttonActions()}
              {loading &&
                <a className="button btn-block btn-loading loading" href="#">{t("general.processing")}</a>
              }
            </p>
          </div>
        </div>
        }
      </>
    );
}
export default NFTItem;
