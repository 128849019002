import Main from "./Main";
import MainError from "./MainError";
import { MoralisProvider } from "react-moralis";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        //i18n.changeLanguage(getCookie('language'));

		let scripts = ['js/utils/app.js', 'js/vendor/xm_plugins.min.js', 
        'js/vendor/tiny-slider.min.js', 'js/global/global.hexagons.js',
         'js/global/global.tooltips.js', 'js/utils/page-loader.js', 'js/vendor/simplebar.min.js', 
         'js/utils/liquidify.js', 'js/header/header.js', 'js/sidebar/sidebar.js', 
         'js/content/content.js', 
         'js/form/form.utils.js', 
         'js/utils/svg-loader.js'];

		for (var i = 0; i < scripts.length; i++) {
			const script = document.createElement("script");
			script.src = process.env.REACT_APP_HOME_URL + scripts[i];
			script.async = false;

			document.body.appendChild(script);
		}

	}, []);

    if (window.ethereum != null) {
        return <MoralisProvider appId={process.env.REACT_APP_MORSLIS_APPID} serverUrl={process.env.REACT_APP_MORSLIS_SERVERURL}>
            <Main />
        </MoralisProvider>
    } else {
        return <MainError />
    }
}
export default App;
