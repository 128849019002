import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";
import ProfileSidebar from './ProfileSidebar';
import { toLocalDateTime } from './../../utils/RBUtils';
import RoosterInfo from './RoosterInfo';

function ProfileSettings(props){
  const { t, i18n } = useTranslation();  
  const rbContext = useContext(RBContext);
  const { Moralis, user } = useMoralis();
  let [loading, setLoading] = useState(false);
  let [guilds, setGuilds] = useState([]);
  let [pendingRequest, setPendingRequest] = useState(false);
  let [guildSelected, setGuildSelected] = useState('');
  let [guildNameInput, setGuildNameInput] = useState('');
  let [members, setGuildMembers] = useState([]);
  let [walletNameInput, setWalletNameInput] = useState('');
  let [profileCountry, setCountrySelected] = useState('');
  let profileInfo = props.profile;
  let [waiting, setWaiting] = useState(false);
  let [roosterIdInput, setRoosterIdInput] = useState('');
  let [roosterNameInput, setRoosterNameInput] = useState('');
  let [roosterDetail, setRoosterDetail] = useState(false);
  let [balance, setUserBalance] = useState(0);
  let [profileAutofeed, setProfileAutofeed] = useState(0);

  const getGuilds = async()=>{
    if(profileInfo){
      var guilds = await Moralis.Cloud.run("vip_get_all_guilds", {lang: i18n.language});

      setGuilds(guilds);
      if(profileInfo.guild == ""){
        var pendingRequest = await Moralis.Cloud.run("vip_get_pending_request",{lang: i18n.language});
        setPendingRequest(pendingRequest);
        setGuildNameInput(profileInfo.guild);
      } else {
        if(profileInfo.guildOwner){
          var members = await Moralis.Cloud.run("vip_get_guild_members", {guild: profileInfo.guildId,lang: i18n.language});
          setGuildMembers(members);
        }
      }

      setLoading(false);
    }
  }

  const cancelRequest = async() => {
    if(pendingRequest && !waiting){
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_leave_guild", {guild: pendingRequest.GuildId,lang: i18n.language});

          if(result.success){
            setPendingRequest(false);
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    } 
  }

  const leaveGuild = async () => {
    if(!waiting){
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_leave_guild", {guild: profileInfo.guildId,lang: i18n.language});

          if(result.success){
            profileInfo.guild = '';
            profileInfo.guildId = '';
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    }
  }

  const changeGuildName = async () => {
    if(!waiting && profileInfo.guildId && profileInfo.guildOwner){
      if(guildNameInput){
        if(window.confirm(t("profile.error_are_you_sure"))){
          setWaiting(true);
          try{
            var result = await Moralis.Cloud.run("vip_edit_guild", {id: profileInfo.guildId, name: guildNameInput,lang: i18n.language});

            if(result.success){
              alert(t("profile.error_update_guild_ok"));
              profileInfo.guild = guildNameInput;
            } else {
              alert(result.message);
            }
          }catch(err){
            alert(err.message);
          }
          
          setWaiting(false);
        }
      }
    }
  }

  const onGuildSelected = (evt) => {
    var value = evt.target.value;
    setGuildSelected(value);
  }

  const onInputChange = (evt) => {
    var value = evt.target.value;
    setGuildNameInput(value);
  }

  const onCountrySelected = (evt) => {
    var value = evt.target.value;
    setCountrySelected(value);
  }

  const joinRequest = async() => {
    if(!pendingRequest && guildSelected != '' && !waiting){
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_request_join_guild", {guild: guildSelected,lang: i18n.language});

          if(result.success){
            var pendingRequest = await Moralis.Cloud.run("vip_get_pending_request",{lang: i18n.language});
            setPendingRequest(pendingRequest);
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    }
  }

  const approveRequest = async(wallet) => {
    if(!waiting && profileInfo.guildOwner && profileInfo.guildId){
      
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_owner_approve_member", {guild: profileInfo.guildId, member: wallet,lang: i18n.language});

          if(result.success){
            for(var i = 0; i < members.length; i++){
              if(members[i].Wallet == wallet){
                members[i].Status = 1;
                break;
              }
            }
            setGuildMembers(members);
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    }
  }

  const kickoutMember = async (wallet) => {
    if(!waiting && profileInfo.guildOwner && profileInfo.guildId){
      
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_owner_kick_member", {guild: profileInfo.guildId, member: wallet,lang: i18n.language});

          if(result.success){
            for(var i = 0; i < members.length; i++){
              if(members[i].Wallet == wallet){
                members.splice(i, 1);
                break;
              }
            }
            setGuildMembers(members);
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    }
  }

  const createGuild = async()=>{
    if(!waiting && !profileInfo.guildOwner && guildNameInput){
      
      if(window.confirm(t("profile.error_are_you_sure"))){
        setWaiting(true);
        try{
          var result = await Moralis.Cloud.run("vip_create_guild", {name: guildNameInput,lang: i18n.language});

          if(result.success){
            profileInfo.guildOwner = true;
            profileInfo.guild = guildNameInput;
            profileInfo.guildId = result.data.id;
          } else {
            alert(result.message);
          }
        }catch(err){
          alert(err.message);
        }
        
        setWaiting(false);
      }
    }
  }

  const syncAccount = async() => {
    if(!waiting){
      setWaiting(true);
      
      try{
        
        var result = await Moralis.Cloud.run("vip_sync_status", {lang: i18n.language});
        alert(t("profile.error_synced_ok"));
      } catch(err){
        alert(err.message);
      }

      setWaiting(false);
    }
  }

  const onWalletInputChange = (evt) => {
    var value = evt.target.value;
    setWalletNameInput(value);
  }

  const updateProfile = async () => {
    if(!waiting){
      if(window.confirm(t("profile.error_are_you_sure"))){
        var params = {name: walletNameInput, country: profileCountry,lang: i18n.language};

        setWaiting(true);
        
        try{
          
          var result = await Moralis.Cloud.run("vip_update_wallet_info", params);
          if(result.success){
            profileInfo.name = walletNameInput;
            profileInfo.country = profileCountry;

            alert(t("profile.error_profile_updated"));
          } else {
            alert(result.message);
          }
        } catch(err){
          alert(err.message);
        }

        setWaiting(false);
      }
      
    }
  }

  const onRoosterInputChange = (evt) => {
    if(evt.target.id == 'roosterid'){
      setRoosterIdInput(evt.target.value);
    } else if(evt.target.id == 'roostername'){
      setRoosterNameInput(evt.target.value);
    }
  }

  const reviewRooster = async () => {
    if(!waiting && roosterIdInput){
      setWaiting(true);
      try{
        var nftOwner = await nftContract.methods.ownerOf(roosterIdInput).call();
        if(nftOwner.toLowerCase() == user.get('ethAddress')){
          var rbDetail = await Moralis.Cloud.run('getRBDetail', {id: roosterIdInput,lang: i18n.language});
          setRoosterDetail(rbDetail);
        } else {
          alert(t("profile.error_not_owner"));
        }
      } catch(err){
        alert(err.message);
      }
      setWaiting(false);
    }
  }

  const updateRooster = async () => {
    if(!waiting){
      if(window.confirm(t("profile.error_are_you_sure"))){
        var params = {rooster: roosterIdInput, name: roosterNameInput,lang: i18n.language};

        setWaiting(true);
        
        try{
          
          var result = await Moralis.Cloud.run("vip_update_rooster_name", params);
          if(result.success){
            roosterDetail.displayname = roosterNameInput;

            alert(t("profile.error_rooster_name_updated"));
          } else {
            alert(result.message);
          }
        } catch(err){
          alert(err.message);
        }

        setWaiting(false);
      }
    }
  }

  const onAutofeedSelected = (evt) => {
    setProfileAutofeed(evt.target.value);
  }

  const setAutofeed = async() => {
    if(!waiting){
      var params = {status: parseInt(profileAutofeed),lang: i18n.language};

      setWaiting(true);
      
      try{
        
        var result = await Moralis.Cloud.run("vip_set_autofeed", params);
        if(result.success){
          // do nothing
        } else {
          alert(result.message);
        }
      } catch(err){
        alert(err.message);
      }

      setWaiting(false);
    }
    
  }

  useEffect( () => {
    async function fetchData(){
      setLoading(true);

      if(profileInfo){
        setWalletNameInput(profileInfo.name);
        setCountrySelected(profileInfo.country); 
        setProfileAutofeed(profileInfo.autofeed ? 1 : 0); 
        
        try{
          var balance = await Moralis.Cloud.run("Get_User_Balance",{lang: i18n.language});
          setUserBalance(balance);
        } catch(err){
          console.log(err);
        }
      }

      await getGuilds();
    }

    fetchData();
  }, [rbContext, user, props]);

  if (!rbContext) {
    return null;
  }

  let { nftContract } = rbContext; 

  return (
      <div id="profileGame" className="grid grid-3-9 small-space">
          <div className="profileGame-sidebar">
            <ProfileSidebar />
          </div>
          <div className="account-hub-content">
            <div className="grid-column">
            <div className="widget-box">
                <h3 className="widget-box-title">{t("profile.balance")}</h3>
                <p>{t("profile.your_current_balance")}<strong>{t("profile.number_rgolds", {gold: balance})}</strong></p>
                <div className='widgetSeparator'></div>
                <h3 className="widget-box-title">{t("profile.vip_account")}</h3>
                {
                  profileInfo.level > 0 &&
                <>
                  <p>{t("profile.you_are_vip_account", {level: profileInfo.level, time: toLocalDateTime(profileInfo.expired * 1000)})}</p>
                  <p className='small-text' style={{marginTop:'20px'}}><a href="https://roosterbattle.substack.com/p/how-to-join-vip-stake-event" onClick={syncAccount}>{t("profile.button_sync_vip_account")}</a> {t("profile.sync_if_needed")}</p>
                  <div className='widgetSeparator'></div>
                  <h3 className="widget-box-title">{t("profile.vip_auto_feeding")}</h3>
                  <p>{t("profile.vip_auto_feeding_desc")}<br/><br/></p>
                  <div className="form-row split">
                    <div className="form-item">
                      <div className="form-select small active">
                        <label htmlFor="autofeedsetting">{t("profile.autofeed")}</label>
                        <select name="autofeedsetting" onChange={onAutofeedSelected} value={profileAutofeed}>
                          <option value='0'>{t("general.no")}</option>
                          <option value='1'>{t("general.yes")}</option>
                        </select>
                        <svg className="form-select-icon icon-small-arrow">
                          <use xlinkHref="#svg-small-arrow"></use>
                        </svg>
                      </div>
                    </div>
                    <div className="form-item">
                      <button style={{marginTop:'5px'}} className="button btn-primary" onClick={setAutofeed}>{waiting ? t("general.processing") : t("profile.update")}</button>
                    </div>
                  </div>
                  <ul className='simple-list'>
                    <li>{t("profile.autofeed_rule_1")}</li>
                    <li>{t("profile.autofeed_rule_2")}</li>
                    <li>{t("profile.autofeed_rule_3")}</li>
                    <li>{t("profile.autofeed_rule_4")}</li>
                  </ul>
                  <div className='widgetSeparator'></div>
                  <h3 className="widget-box-title">{t("profile.wallet_name")}</h3>
                  <p>{t("profile.change_wallet_name_text")}<br/><br/></p>
                  <div className="form-row split">
                    <div className="form-item">
                      <div className="form-input small active">
                        <label htmlFor="waletname">{t("profile.wallet_name")}</label>
                        <input type="text" id="walletname" value={walletNameInput} onChange={onWalletInputChange} />
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-select active small">
                        <label htmlFor="countrylist">{t("profile.country_list")}</label>
                        <select name="countrylist" onChange={onCountrySelected} value={profileCountry}>
                          <option value=''></option>
                          <option value='AR'>{t("general.country_argentina")}</option>
                          <option value='BR'>{t("general.country_brazil")}</option>
                          <option value='CN'>{t("general.country_china")}</option>
                          <option value='IT'>{t("general.country_italia")}</option>
                          <option value='PH'>{t("general.country_phillipines")}</option>
                          <option value='PT'>{t("general.country_portugal")}</option>
                          <option value='ES'>{t("general.country_spain")}</option>
                          <option value='TH'>{t("general.country_thailand")}</option>
                          <option value='VN'>{t("general.country_vietnam")}</option>
                        </select>
                        <svg className="form-select-icon icon-small-arrow">
                          <use xlinkHref="#svg-small-arrow"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="form-row split">
                    <div className="form-item">
                      <button style={{marginTop:'5px'}} className="button btn-primary" onClick={updateProfile}>{waiting ? t("general.processing") : t("profile.button_update_profile")}</button>
                    </div>
                    <div className="form-item">
                        
                    </div>
                  </div>
                  <p><br/><br/>{t("profile.change_wallet_name_desc")}. {t("profile.rule_for_wallet_name")}</p>
                  <ul className='simple-list'>
                    <li>{t("guild.name_rule_1")}</li>
                    <li>{t("guild.name_rule_3")}</li>
                  </ul>

                  <div className='widgetSeparator'></div>
                  <h3 className="widget-box-title">{t("profile.rooster_name")}</h3>
                  <p>{t("profile.change_rooster_name")}<br/><br/></p>
                  <div className="form-row split">
                    <div className="form-item">
                      <div className="form-input small active">
                        <label htmlFor="roosterid">{t("profile.rooster_id")}</label>
                        <input type="text" id="roosterid" value={roosterIdInput} onChange={onRoosterInputChange} />
                      </div>       
                    </div>
                    <div className="form-item">
                      <button style={{marginTop:'5px'}} className="button btn-primary" onClick={reviewRooster}>{waiting ? t("general.processing") : t("profile.button_review_rooster")}</button>
                    
                    </div>
                  </div>
                  <div className="form-row split">
                    <div className="form-item">
                      {roosterDetail && 
                        <>
                          <RoosterInfo token={roosterDetail}/>

                          <div className="form-input small active">
                            <label htmlFor="roostername">{t("profile.rooster_name")}</label>
                            <input type="text" id="roostername" value={roosterNameInput} onChange={onRoosterInputChange} />
                          </div>
                          <button style={{marginTop:'5px'}} className="button btn-primary" onClick={updateRooster}>{waiting ? t("general.processing") : t("profile.button_change_rooster_name")}</button>
                        </>
                      }
                      
                    </div>
                    <div className="form-item">
                      
                    </div>
                  </div>
                  <p><br/><br/>{t("profile.vip_change_rooster_name_desc")}. {t("profile.vip_rules_for_rooster_name")}</p>
                  <ul className='simple-list'>
                      <li>{t("guild.name_rule_1")}</li>
                      <li>{t("guild.name_rule_3")}</li>
                    </ul>
                </>
                
                }

                {
                  profileInfo.level == 0 &&
                <p>{t("profile.not_vip_account")}<a href="#">{t("general.vip_staking")}</a></p>
                }
                
                {
                  profileInfo.level > 0 && !profileInfo.guildOwner ? 
                  <>
                    <div className='widgetSeparator'></div>
                  <h3 className="widget-box-title">{t("profile.create_guild")}</h3>
                    <p>{t("guild.create_guild_text")}<br/><br/><br/></p>
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="guildname">{t("profile.guild_name")}</label>
                          <input type="text" id="guildname" value={guildNameInput} onChange={onInputChange} />
                        </div>
                      </div>
                      <div className="form-item">
                          <button style={{marginTop:'5px'}} className="button btn-primary" onClick={createGuild}>{waiting ? t("general.processing") : t("guild.button_create_guild")}</button>
                      </div>
                    </div>
                    <p><br/><br/>{t("guild.create_guild_desc")}. {t("guild.name_rule")}</p>
                    <ul className='simple-list'>
                      <li>{t("guild.name_rule_1")}</li>
                      <li>{t("guild.name_rule_2")}</li>
                      <li>{t("guild.name_rule_3")}</li>
                    </ul>
                  </> : <></>
                }
              </div>

              <div className="widget-box">
                <h3 className="widget-box-title">{t("profile.guild_info")}</h3>
                {loading && 
                <div className="page-loader-indicator loader-bars">
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                </div>
                }
                {
                  !loading && profileInfo.guild == "" &&
                  <>
                    {pendingRequest && 
                    <>
                      <p>{t("guild.you_have_pending_request", {guild: pendingRequest.Guild, time: toLocalDateTime(pendingRequest.RequestedAt)})}</p>
                      <p><button className="button btn-primary" onClick={cancelRequest}>{waiting ? t("general.processing") : t("guild.button_cancel_request")}</button></p>
                    </>
                    }
                    {!pendingRequest && 
                    <>
                      <p>{t("guild.join_guild_desc")}</p>
                      <div className="widget-box-content">
                          <div className="form-row split">
                            <div className="form-item">
                              <div className="form-select active small">
                                <label htmlFor="guildlist">{t("profile.guild_list")}</label>
                                <select name="guildList" onChange={onGuildSelected}>
                                  <option value=''></option>
                                  {guilds.map((guild, index) => {
                                    return <option key={"guild-" + index} value={guild.Id}>{guild.Guild} ({guild.Members})</option>
                                  })}
                                </select>
                                <svg className="form-select-icon icon-small-arrow">
                                  <use xlinkHref="#svg-small-arrow"></use>
                                </svg>
                              </div>
                            </div>
                            <div className="form-item">
                                <button style={{marginTop:'5px'}} className="button btn-primary" onClick={joinRequest}>{waiting ? t("general.processing") : t("guild.button_request_join")}</button>
                            </div>
                          </div>
                      </div>
                    </>
                    }
                  </>
                }
                {
                  !loading && profileInfo.guild != "" && profileInfo.guildOwner &&
                  <>
                    <p>{t("guild.you_are_guild_owner", {guild: profileInfo.guild})}<br/><br/></p>
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small active">
                          <label htmlFor="guildname">{t("profile.guild_name")}</label>
                          <input type="text" id="guildname" value={guildNameInput} onChange={onInputChange} />
                        </div>
                      </div>
                      <div className="form-item">
                          <button style={{marginTop:'5px'}} className="button btn-primary" onClick={changeGuildName}>{waiting ? t("general.processing") : t("guild.button_change_guild_name")}</button>
                      </div>
                    </div>
                    <p><br/><br/>{t("guild.update_name_cost_you")}. {t("guild.name_rule")}</p>
                    <ul className='simple-list'>
                      <li>{t("guild.name_rule_1")}</li>
                      <li>{t("guild.name_rule_2")}</li>
                      <li>{t("guild.name_rule_3")}</li>
                    </ul> 
                    <div className='widgetSeparator'></div>
                    <h3 className="widget-box-title">{t("guild.members_of", {count: members.length, guild: profileInfo.guild})}</h3>
                    {members.length > 0 && 
                    <div className="table table-guild-members">
                      <div className="table-header">
                        <div className="table-header-column">
                          <p className="table-header-title">{t("general.row_index")}</p>
                        </div>
                        <div className="table-header-column">
                          <p className="table-header-title">{t("general.wallet")}</p>
                        </div>
                        <div className="table-header-column centered padded-medium">
                          <p className="table-header-title">{t("general.row_actions")}</p>
                        </div>
                      </div>
                      <div className="table-body">
                      {members.map((member, index) => {
                        return (
                        
                          <div className="table-row medium" key={"member-"+index}>
                            <div className="table-column">
                              <span className="mobile-title">{t("general.row_index")}: </span>{index + 1}
                            </div>
                            
                            <div className="table-column padded-small">
                              <p className="table-title"><span className="mobile-title">{t("general.wallet")}: </span>{member.Wallet} {member.Status == 0 ? t("general.pending_approval") : ''}</p>
                            </div>
                            <div className="table-column centered padded-medium">
                              <p className="table-title"><span className="mobile-title">{t("general.row_actions")}: </span>
                              {(!waiting && member.Status == 0) ? 
                              <>
                                <a className="" href="#" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveRequest(member.Wallet)}}>{t("general.approve")}</a> | <a  href="#" className="" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault();kickoutMember(member.Wallet)}}>{t("general.reject")}</a>                              
                              </> : 
                              
                              <a href="#" className="" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault();kickoutMember(member.Wallet)}}>{t("general.kickout")}</a>

                              }
                              </p>
                            </div>
                          </div>
                        )
                      })}

                      </div>
                    </div>
                    }
                      
                  </>
                }

                {
                  !loading && profileInfo.guild != "" && !profileInfo.guildOwner &&
                  <>
                    <p>{t("profile.you_are_member_of_guild", {guild: profileInfo.guild})}</p>
                    <p><button className="button btn-primary" onClick={leaveGuild}>{waiting ? t("general.processing") : t("profile.leave_guild")}</button></p>
                  </>
                }
                
            </div>
          </div>
      </div>
    </div>
  );
}

export default ProfileSettings; 