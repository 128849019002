import React, { useState, useEffect, useContext } from 'react';
import { useMatch } from "react-router-dom";
import { useMoralis } from "react-moralis";
import { toLocalDateTime } from '../../utils/RBUtils';
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function ScholarsMain(props){
    const rbContext = useContext(RBContext);
    const { Moralis, user } = useMoralis();
    let [myScholars, setMyScholars] = useState([]);
    let [loading, setLoading] = useState(false);
    let [editMode, setEditMode] = useState(false);
    let [wallet, setWallet] = useState('');
    let [percentage, setPercentage] = useState(0);
    let [myScholarship, setMyScholarship] = useState([]);
    let [currentTab, setCurrentTab] = useState(1);
    const { t, i18n } = useTranslation();

    const setInput = (val, input) => {
        if(input == "wallet"){
            setWallet(val);
        } else if(input == "percentage"){
            setPercentage(val);
        }
    }

    const addScholar = async () => {
        if(wallet && percentage){
            if(window.confirm(t("profile.scholar_confirm_setting", {wallet, percentage}))){
                setLoading(true);
                var result = await Moralis.Cloud.run('scholarship_updateMyScholar', {scholar: wallet, percentage: percentage,lang: i18n.language});
                
                if(result){
                    alert(t("error.saved_successfully"));
                    setWallet('');
                    setPercentage(0);
                    setEditMode(false);
                    init();
                } else {
                    alert(t("error.unable_to_perform"));
                }
                setLoading(false);
            }
        }
    }

    const removeScholar = async (scholar, index) => {
        if(window.confirm(t("profile.scholar_confirm_remove", {wallet: scholar.wallet}))){
            setLoading(true);
            var result = await Moralis.Cloud.run('scholarship_updateMyScholar', {scholar: scholar.wallet, percentage: 0,lang: i18n.language});
            console.log(result);
            if(result){
                myScholars.splice(index, 1);
            } else {
                alert(t("error.unable_to_perform"));
            }
            setLoading(false);
        }
    }

    const editScholar = async(scholar, index)=>{
        setEditMode(true);
        setWallet(scholar.wallet);
        setPercentage(scholar.percentage);
        init();
    }

    const cancelEdit = ()=>{
        setEditMode(false);
        setWallet('');
        setPercentage(0);
    }
    const getMyScholarship = async()=>{
        var scholars = await Moralis.Cloud.run('scholarship_getMyPrimaries', {lang: i18n.language});
        console.log("my scholarship", scholars);
        setMyScholarship(scholars);
    }

    const init = async()=>{
        var scholars = await Moralis.Cloud.run('scholarship_getMySecondaries', {lang: i18n.language});
        setMyScholars(scholars);
    }

    const switchTab = (tab) => {
        setCurrentTab(tab);
    }
  
    useEffect(() => {
        async function fetchData(){
            init();

            getMyScholarship();
        }
        if(user){
            fetchData();
        }
        
    }, [user, rbContext]);

    if (!rbContext) {
        return null;
    }

    return (
        user ? <div className='tab-box'>
                <div className='tab-box-options'>
                    <div onClick={()=>{switchTab(1)}} className={currentTab == 1 ? "tab-box-option active" : "tab-box-option"}>
                        {t("profile.scholar_label_scholarship_management")}
                    </div>
                    <div onClick={()=>{switchTab(2)}} className={currentTab == 2 ? "tab-box-option active" : "tab-box-option"}>
                    {t("profile.scholar_label_my_scholarship")}
                    </div>
                </div>
                <div className='tab-box-tems'>
                    <div className='tab-box-item'  style={currentTab == 1 ? {} : {display: 'none'}}>
                            <div className='form'>
                                <h4 className='heading'>{editMode ? t("profile.scholar_edit_scholarship") : t("profile.scholar_add_scholarship")}</h4>
                                {false && <>
                                <div className="form-row split">
                                    <div className="form-item">
                                        <div className="form-input small active">
                                            <label htmlFor='wallet'>{t("profile.scholar_label_wallet")}</label>
                                            <input id="wallet" readOnly={editMode} type="text" value={wallet} onChange={event => setInput(event.target.value, "wallet")}/>
                                            <p className='form-input-description'>{t("profile.scholar_label_wallet_desc")}</p>
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <div className="form-input small active">
                                        <label htmlFor='percentage'>{t("profile.scholar_reward_percentage")}</label>
                                            <input id="percentage" value={percentage} className='numberbox' type="number" min="1" max="100" onChange={event => setInput(event.target.value, "percentage")}/>
                                            <p className='form-input-description'>{t("profile.scholar_reward_percentage_desc")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-row split'>
                                    <div className="form-item ">
                                    </div>
                                    <div className="form-item centered">
                                        <div className='centered block'>
                                        <button className="button btn-primary" onClick={addScholar}>{loading ? t("general.loading") : (editMode ? t("profile.scholar_save_info") : t("profile.scholar_add_scholar"))}</button>
                                        {editMode && <a href="#" style={{marginLeft:'10px'}} onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); cancelEdit();}}>{t("profile.scholar_cancel_edit")}</a>}
                                        <p className='centered block'>{t("profile.scholar_read_more")} <a href="#">{t("general.here")}</a></p>
                                        <p><br/></p>
                                        </div>
                                    </div>
                                    <div className="form-item"></div>
                                </div>
                                </>
                                }
                                <p>{t("profile.scholar_open_beta")}</p>
                            </div>
                            {myScholars.length ? 
                            <>
                            <div className='form'>
                                <h4 className='heading'>{t("profile.scholar_label_my_scholars")}</h4>
                                    <div className="table table-battles">
                                    <div className="table-header">
                                        <div className="table-header-column">
                                            <p className="table-header-title">{t("profile.scholar_table_index")}</p>
                                        </div>
                                        <div className="table-header-column">
                                            <p className="table-header-title">{t("profile.scholar_table_actions")}</p>
                                        </div>
                                        <div className="table-header-column">
                                            <p className="table-header-title">{t("profile.scholar_table_wallet")}</p>
                                        </div>
                                        <div className="table-header-column centered padded-medium">
                                            <p className="table-header-title">{t("profile.scholar_table_percentage")}</p>
                                        </div>
                                        <div className="table-header-column padded-medium">
                                            <p className="table-header-title">{t("profile.scholar_table_history")}</p>
                                        </div>
                                    </div>
                                    <div className="table-body">
                                        {myScholars.map((scholar, index) => {
                                            return (<div className="table-row medium" key={"scholar-" + index}>
                                                    <div className="table-column">
                                                        <span className="mobile-title">{t("profile.scholar_table_index")}: </span>{index + 1}
                                                    </div>
                                                    <div className="table-column">
                                                        <span className="mobile-title">{t("profile.scholar_table_actions")}: </span>
                                                    </div>
                                                    <div className="table-column ">
                                                        <span className="mobile-title">{t("profile.scholar_table_wallet")}: </span>{scholar.wallet}
                                                    </div>
                                                    <div className="table-column centered">
                                                        <span className="mobile-title">{t("profile.scholar_table_percentage")}: </span>{scholar.percentage}%
                                                    </div>
                                                    <div className="table-column">
                                                        <span className="mobile-title">{t("profile.scholar_table_history")}: </span>{scholar.histories.map((history, index)=>{return <p key={"history-" + history.timestamp + "-" + index}>{history.description} - {toLocalDateTime(history.timestamp)}</p>})}
                                                    </div>
                                                </div>);
                                        })}
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <><p className='centered'>{t("profile.scholar_no_scholars")}<br/><br/></p></>}
                    </div>
                    <div className='tab-box-item'  style={currentTab == 2 ? {} : {display: 'none'}}>
                        <p className='block white'>{t("profile.scholar_read_more")} <a href="#">{t("general.here")}</a></p>
                            {myScholarship.length ? 
                            <div className='form'>
                            <div className="table table-battles">
                                <div className="table-header">
                                    <div className="table-header-column">
                                        <p className="table-header-title">{t("profile.scholar_table_index")}</p>
                                    </div>
                                    <div className="table-header-column">
                                        <p className="table-header-title">{t("profile.scholar_table_wallet")}</p>
                                    </div>
                                    <div className="table-header-column centered padded-medium">
                                        <p className="table-header-title">{t("profile.scholar_table_percentage")}</p>
                                    </div>
                                    <div className="table-header-column padded-medium">
                                        <p className="table-header-title">{t("profile.scholar_table_history")}</p>
                                    </div>
                                </div>
                                <div className="table-body">
                                    {myScholarship.map((scholar, index) => {
                                        return (<div className="table-row medium" key={"scholar-" + index}>
                                                <div className="table-column">
                                                    <span className="mobile-title">{t("profile.scholar_table_index")}: </span>{index + 1}
                                                </div>
                                                <div className="table-column ">
                                                    <span className="mobile-title">{t("profile.scholar_table_wallet")}: </span><a href={"https://roosterbattle.net/play/?address=" + scholar.wallet} target="_blank">{scholar.wallet}</a>
                                                </div>
                                                <div className="table-column centered">
                                                    <span className="mobile-title">{t("profile.scholar_table_percentage")}: </span>{scholar.percentage}%
                                                </div>
                                                <div className="table-column">
                                                    <span className="mobile-title">{t("profile.scholar_table_history")}: </span>{scholar.histories.map((history, index)=>{return <p key={"history-" + history.timestamp + "-" + index}>{history.description} - {toLocalDateTime(history.timestamp)}</p>})}
                                                </div>
                                            </div>);
                                    })}
                                </div>
                            </div>
                            </div>
                            :
                            <><p className='centered'>{t("profile.scholar_no_scholarship")}</p></>}
                    </div>
                </div>
            </div> : <></>
    );
};

export default ScholarsMain;