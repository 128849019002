import React, { useState, useEffect, useContext } from 'react';
import Extra from './../../Extra';
import RBContext from './../../RBContext';
import { useMoralis } from "react-moralis";

function Fix6000(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [batches, setBatches] = useState([]);
  let [loading, setLoading] = useState(false);
  let [ownerClaimedStatus, setOwnerClaimedStatus] = useState([]);
  let [indexToCall, setIndexToCall] = useState(0);

  const getLogs = async (index) => {
      try{
        const logs = ["CIsIj0dLE7TFx2boopClbtwH", "boW6qqjIzK72zNBBDjGpo8Go", "QwxqDCsABvoAYLoldqdS8Xf0"];
          setLoading(true);
            var result = await Moralis.Cloud.run('admin_getLogs', {logs:[logs[index - 1]]});
            
            setBatches(result);
            setOwnerClaimedStatus([]);
            console.log(result);
            setLoading(false);
      } catch(err){
          alert(err.message);
          setLoading(false);
      }
    
  }

  const fetchContractClaimed = async()=>{
      setLoading(true);
      for(var i = indexToCall; i < indexToCall + 100; i++){
          if(batches[0].length >= i){
            var battle = batches[0][i];
            var owner1 = battle.get("Rooster1Owner");
            var owner2 = battle.get("Rooster2Owner");

            var timestamp1 = await getContractClaimed(owner1);
            var timestamp2 = await getContractClaimed(owner2);
            var obj = {owner1: timestamp1, owner2: timestamp2};
            console.log(obj);
            console.log(i);
            ownerClaimedStatus.push(obj);
          } else {
              break;
          }
      }

      setOwnerClaimedStatus(ownerClaimedStatus);
      setIndexToCall(indexToCall + 100);
      setLoading(false);
  }

const getContractClaimed = async (wallet) => {
    var timestamp = await battlefieldContract.methods.LastClaimed(wallet).call();

    return timestamp;
}

  useEffect(()=>{
    Extra.updateContentGridPos();    
  });

  if (!rbContext) {
    return null;
}

let { web3, battlefieldContract } = rbContext;


  return (
      <>
        <div className="grid grid-12 top-space centered">
            <div id="admin-logsdata" className="contentPanel">
            {!loading &&
        <>
        <button className="button btn-block" onClick={(evt)=> {getLogs(1);}}>Get incorrect logs 1</button>
        <button className="button btn-block" onClick={(evt)=> {getLogs(2);}}>Get incorrect logs 2</button>
        <button className="button btn-block" onClick={(evt)=> {getLogs(3);}}>Get incorrect logs 3</button>
        <button className="button btn-block" onClick={(evt)=> {fetchContractClaimed();}}>Fetch contract data</button>      
        </>
        }
        {loading &&
        <button className="button btn-block">Loading data...</button>
        }
                <h3>Logs data</h3>
                <h4>Battles to be verified</h4>
                {batches && batches.map((batch, index) => (
                <div key={index}>
                    <h5>Batch {index + 1}</h5>
                    <table>
                        <tbody>
                        <tr>
                            <td>Index</td>
                            <th>Owner 1</th>
                            <th>Owner 1 Contract Last Claimed</th>
                            <th>Owner 2</th>
                            <th>Owner 2 Contract Last Claimed</th>                            
                            <th>Battle Created</th>
                        </tr>
                        {
                            batch.map((battle, index2) => (
                                <tr key={index +"-"+index2}>
                                    <td>{index2 + 1}</td>
                                    <td>{battle.get("Rooster1Owner")}</td>
                                    <td>{ownerClaimedStatus.length > index2 ? ownerClaimedStatus[index2].owner1 : ''}</td>
                                    <td>{battle.get("Rooster2Owner")}</td>
                                    <td>{ownerClaimedStatus.length > index2 ? ownerClaimedStatus[index2].owner2 : ''}</td>
                                    <td>{battle.get("createdAt").getTime() / 1000}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>))}
            </div>
        </div>
        
        
      </>
    )
}
export default Fix6000;