import { useTranslation } from "react-i18next";

function Footer() {
    const { t, i18n } = useTranslation();

    const onLanguageChange = (evt) => {
        i18n.changeLanguage(evt.target.value);
    }

    return <div className="content-grid">
        <div className="footer">&#169; 2021-2022 - <a href="https://roosterbattle.net">Rooster Battle NFT Game</a><br/><img src="https://cdn.roosterbattle.net/rice_icon_32x32.png"/> {t("general.contract_address")} (RICE): <a href="https://bscscan.com/address/0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9" target="_blank">0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9</a></div>
        <div id="languages-switcher">
            <span>{t("general.languages")}: </span>
            <select onChange={onLanguageChange} value={i18n.language}>
                <option value="en">{t("general.lang_en")}</option>
                <option value="vn">{t("general.lang_vn")}</option>
                <option value="cn">{t("general.lang_cn")}</option>
                <option value="pt">{t("general.lang_pt")}</option>
            </select>
        </div>
    </div>
}

export default Footer;