import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { useTranslation } from "react-i18next";
import { walletMask } from "../../utils/RBUtils";

const TableRow = (battleInfo, roosterTypes, user, index, t) => {
  if(user){
    var userAddress = user.get('ethAddress');
    userAddress = userAddress.toLowerCase();
    
    if(battleInfo){
      let award = '';
      const battleLevel = battleInfo.BattleLevel;
      const rooster2Win = battleInfo.Rooster2Win;
      const player1 = battleInfo.Rooster1Owner;
      const player2 = battleInfo.Rooster2Owner;
      const rooster1 = battleInfo.Rooster1TokenId;
      const rooster2 = battleInfo.Rooster2TokenId;
      const battleResult = battleInfo.BattleResult;

      var claimedStatus = "";
      let winner = 0;
      let opponent = '0x0';
      let rooster = '';
      var myRooster = '';
      var scholarship = '';
      /*
      console.log('Current: ' + userAddress);
      console.log('Player 1: ' + player1);
      console.log('Player 2: ' + player2);
      */
      if(player1 == userAddress){
        // you are player 1
        opponent = player2;
        rooster = rooster2;
        myRooster = rooster1;
        claimedStatus = battleInfo.Rooster1ClaimedStatus ? t("profile.battle_verified") : t("profile.battle_unverified");
    
        if(rooster2Win){
          // Player 2 win, so you lose
          winner = 0;
        } else {
          // Player 1 win, so you win
          winner = 1;
        }

        var secondOwner = battleInfo.SecondOwner1;
        if(secondOwner){
          scholarship = secondOwner + " , " + t("profile.scholarship_rate") + " " + battleInfo.SecondOwner1Percentage + "%";
        }
      } else {
        // you are player 2
        opponent = player1;
        rooster = rooster1;
        myRooster = rooster2;
        claimedStatus = battleInfo.Rooster2ClaimedStatus ? t("profile.battle_verified") : t("profile.battle_unverified");
    
        if(rooster2Win){
          // Player 2 win, so you win
          winner = 1;
        } else {
          // Player 1 win, so you lose
          winner = 0;
        }

        var secondOwner = battleInfo.SecondOwner2;
        if(secondOwner){
          scholarship = secondOwner + " , " + t("profile.scholarship_rate") + " " + battleInfo.SecondOwner2Percentage + "%";
        }
      }

      if(battleInfo.ValidBattle === false){
        claimedStatus = t("profile.battle_invalid");
      }
      
      var isEpic = false;
      var rewardx2 = ' ';
      for(var j = 0; j < roosterTypes.length; j++){
        if(roosterTypes[j].id == myRooster){
          var roosterType = parseInt(roosterTypes[j].type);
          if(roosterType > 6 && roosterType < 12){
            rewardx2 = ' ' + t("profile.reward_x2") + ' ';
            isEpic = true;
          }
          break;
        }
      }

      var defaultRiceWinReward = 10;
      var defaultRiceLoseReward = 3;

      var battleTimestamp = battleInfo.BattleTimestamp;
      var itemReward = '& 3 GAME ITEMS';
      var tokenReward = ' RICEs';
      if(battleTimestamp > 1642377600000){
        // 2022-01-17 00:00:00
        defaultRiceWinReward = 8;
        defaultRiceLoseReward = 2;
        //itemReward = '';
      }

      if(battleTimestamp > 1643241600000){
        // 2022-01-27 0:0:0
        defaultRiceWinReward = 6;
        defaultRiceLoseReward = 3;
        itemReward = '';
        tokenReward = ' RGOLDs';
      }

      if(battleTimestamp > 1650614400000){
        rewardx2 = '';
      }

      if(battleLevel == 1){
        if(winner){
          award = defaultRiceWinReward + tokenReward + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + tokenReward + rewardx2;
        }
      } else if(battleLevel == 2){
        defaultRiceWinReward = 9;
        defaultRiceLoseReward = 1;

        if(battleTimestamp > 1650614400000){
          // default bronze armored
          defaultRiceWinReward = 10;
          defaultRiceLoseReward = 6;

          if(battleResult.length == 8){
            var roosterArmored = battleResult[6]; // default player 1
            
            if(player2 == userAddress){
              // player 2
              roosterArmored = battleResult[7];
            }
            
            if(roosterArmored == 2){
              // silver
              defaultRiceWinReward = 32;
              defaultRiceLoseReward = 16;
            } else if(roosterArmored == 3){
              // golden
              defaultRiceWinReward = 104;
              defaultRiceLoseReward = 40;
            }
          }
        }

        if(isEpic){
          // EPIC reward
          defaultRiceWinReward = 20;
          defaultRiceLoseReward = 12;
        }

        if(winner){
          award = defaultRiceWinReward + tokenReward + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + tokenReward + rewardx2;
        }
      } else if(battleLevel == 100){
        // Legendary battles
        // EPIC reward (RICE)
        var realRiceWinReward = 225;
        var realRiceLoseReward = 125;

        if(winner){
          award = realRiceWinReward + ' RICEs';
        } else {
          award = realRiceLoseReward + ' RICEs';
        }
      }

      let timestamp = battleInfo.BattleTimestamp;
      // Create a new JavaScript Date object based on the timestamp
      var date = new Date(timestamp);

      // Will display time in 10:30:23 format
      var formattedTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();      
      
      return (
      <div className="table-row medium" key={battleInfo.objectId}>
        <div className="table-column column-status">
          <span className="mobile-title">{t("profile.history_table_index")}: </span>{index}
        </div>
        <div className="table-column column-info">
          <div className="discussion-preview">
            <p>{t("profile.battle_fight_with")} <a href="#">{rooster}</a> {t("general.time_at")} <span> {formattedTime} </span></p>
            <p>{t("profile.battle_your_rooster")} <a href="#">{myRooster}</a></p>
          
            <div className="discussion-preview-meta">
              <p className="discussion-preview-meta-text">{t("profile.history_table_opponent")}: </p>
              <p className="discussion-preview-meta-text"><a title={opponent} href={"https://bscscan.com/address/" + opponent}>{walletMask(opponent)}</a></p>
            </div>
          </div>
        </div>
        <div className="table-column column-level centered padded-medium">
          <p className="table-title"><span className="mobile-title">{t("profile.history_table_level")}: </span>{battleLevel == 1 ? t("profile.battle_lightweight") : (battleLevel == 2 ? t("profile.battle_armored") : t("profile.battle_legendary"))}</p>
        </div>
        <div className="table-column column-status centered padded-medium">
          <p className="table-title"><span className="mobile-title">{t("profile.history_table_status")}: </span>{winner ? t("profile.battle_win") : t("profile.battle_lose")}</p>
        </div>

        <div className="table-column column-award centered padded-medium">
          <p className="table-title"><span className="mobile-title">{t("profile.history_table_awards")}: </span>{award}</p>
        </div>
        <div className="table-column column-scholar centered padded-medium">
          <p className="table-title"><span className="mobile-title">{t("profile.history_table_scholarship")}: </span>{scholarship}</p>
        </div>
        <div className="table-column column-verify">
          {claimedStatus}
        </div>
      </div>);
    }
  }
}

function BattlesHistoryMain(props){
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation();  
  
  let [battles, setBattles] = useState([]);

  let [loading, setLoading] = useState(true);
  let [processing, setProcessing] = useState(false);
  const { Moralis, user } = useMoralis();
  let [rareTypes, setRareTypes] = useState([]);
  let [moreResults, setMoreResults] = useState(true);
  let [mains, setMainOwners] = useState([]);
  const loadMoreBattles = async () => {
    if(moreResults){
      setProcessing(true);
      getHistoryBattles(battles.length);
      setProcessing(false);
    }
  }

  // marketplace
  const getHistoryBattles = async (offset) => {
      let userAddress = user.get('ethAddress');
      if(userAddress){
        userAddress = userAddress.toLowerCase();
              
      try {
          setLoading(true);
          var results = await Moralis.Cloud.run('getBattleHistories2', {offset: offset,lang: i18n.language, sender: userAddress});
          console.log(results);
          if(results){
            var allIDs = [];
            
            for(var i = 0; i < results.length; i++){
              
              allIDs.push(results[i].objectId);

              var rareType = 0;
              var roosterId = results[i].Rooster1TokenId;
              if(results[i].Rooster2Owner == userAddress){
                roosterId = results[i].Rooster2TokenId;
              }

              var foundRooster = false;
              for(var j = 0; j < rareTypes.length; j++){
                if(rareTypes[j].id == roosterId){
                  foundRooster = true;
                  break;
                }
              }

              if(!foundRooster){
                rareType = await nftContract.methods.getRBType(roosterId).call();
                rareTypes.push({id: roosterId, type: parseInt(rareType)});
              }
              
            }
            
            setRareTypes(rareTypes);
            
            if(offset == 0){
              setBattles(results);
            } else {
              var newbattles = battles ? battles.concat(results) : results;
              console.log(newbattles);
              setBattles(newbattles);
            }
          }

          if(results.length < 100){
            setMoreResults(false);
          }
          
          setLoading(false);
      } catch (error) {
          const code = error.code;
          const message = error.message;
          console.log(code + ':' + message);
      }
    }
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          getHistoryBattles(0);

          var mains = await Moralis.Cloud.run('scholarship_getMyPrimaries', {lang: i18n.language});
          setMainOwners(mains);
        }
        fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { nftContract } = rbContext;

  return(
    <>
      {mains.length > 0 && <div className="tab-header">
        <h4 className='tab tab-active'>{t("profile.history_main_account")}</h4>
        <h4 className='tab'><a href={process.env.REACT_APP_HOME_URL + "profile/battles/scholar"}>{t("profile.history_scholarship_account")}</a></h4>
      </div>}
      <div className="table table-battles">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">{t("profile.history_table_index")}</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">{t("profile.history_table_info")}</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">{t("profile.history_table_level")}</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">{t("profile.history_table_result")}</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">{t("profile.history_table_awards")}</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">{t("profile.history_table_scholarship")}</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">{t("profile.history_table_status")}</p>
          </div>
        </div>
        <div className="table-body">
          {battles.length > 0 ?
            battles.map((item, index) => TableRow(item, rareTypes, user, index + 1, t)) : ''
          }
        </div>
    </div>
    {battles.length > 0 && processing &&
    <div className="centered paragraph">
      <button className="button primary">{t("general.processing")}</button>
    </div>
    }
    {
      !loading && moreResults && <div className="centered paragraph">
        <button className='button' onClick={loadMoreBattles}>{processing ? t("general.loading") : t("general.loadmore")}</button>
      </div>
    }
    {
      loading &&

      <div className="centered paragraph white no-battles">{t("general.loading")}</div>
    }
    {
      !loading && battles.length == 0 &&

      <div className="centered paragraph white no-battles">{t("profile.history_no_battles")}</div>
    }
    </>
  )
}

export default BattlesHistoryMain;