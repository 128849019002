import React, { useEffect, useContext } from 'react';
import Extra from '../../Extra';
import UserStaking from './UserStaking';
import RBContext from '../../RBContext';
import WrongNetwork from '../WrongNetwork';
import LoadingIcon from '../LoadingIcon';
import UserPendingSkill from './UserPendingSkill';

function UserStakesGrid(props) {

	useEffect(() => {
		Extra.updateContentGridPos();
	})

	const rbContext = useContext(RBContext);

	if (!rbContext) {
		return;
	}

	return (
		<>	
			{/* user rice stakings */}
			{props.userStakings && props.userStakings
				.map((staking, i) => {
					return <UserStaking staking={staking} key={i} redeemCallback={props.redeemCallback} />;
				})
			}

			{/* user pending skills */}
			{props.pendingSkills && props.pendingSkills.map((pendingSkill, i) => {
				return <UserPendingSkill skill={pendingSkill} key={i} redeemCallback={props.redeemSkillsCallback}/>
			})}
		</>
	)
}

export default UserStakesGrid;
