import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { showError } from '../../utils/RBUtils.js';
import { useTranslation } from "react-i18next";
import {toLocalDateTime} from './../../utils/RBUtils';

/**
 * VIP Staking
 * @param {*} props 
 * @returns 
 */
function Stakes11(props) {
  let [isLoading, setIsLoading] = useState(true);
  let [isPoolOpen, setPoolOpen] = useState(true);
  let [isRiceApproved, setRiceApproved] = useState(false);
  let [isStaked, setIsStaked] = useState(0);

  const { t, i18n } = useTranslation(); 

  const { user, Moralis } = useMoralis();
  
  const rbContext = useContext(RBContext);

  useEffect(() => {
    async function init(){
      if(user){
        var userAddr = user.get('ethAddress');
  
        let [ riceAllowance, poolOpen, currentStake ] = await Promise.all([
          riceContract.methods.allowance(userAddr, process.env.REACT_APP_POOL11_ADDRESS).call(),
          RBPool11Contract.methods.isStakingPoolActive().call(),
          RBPool11Contract.methods.StakingHolders(userAddr).call()
        ]);
  
        setPoolOpen(poolOpen);
        setIsStaked(currentStake);
          
        riceAllowance = new web3.utils.BN(riceAllowance);
  
        if(riceAllowance.gte(new web3.utils.BN("100000000000000000000000"))){
          setRiceApproved(true);
        }
  
      }
  
      setIsLoading(false);
    }
    init();
  }, [user, rbContext])

  const approveRice = async () => {
    if(!isLoading){
      try{
        var userAddr = user.get('ethAddress');
        setIsLoading(true);
        var result = await riceContract.methods.approve(process.env.REACT_APP_POOL11_ADDRESS, new web3.utils.BN( "1000000000000000000000000")).send({from: userAddr});
        setRiceApproved(true);
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  const doStake = async () => {
    if(!isLoading){
      var userAddr = user.get('ethAddress');
      setIsLoading(true);
      try{
        var balance = await riceContract.methods.balanceOf(userAddr).call();
        var riceBalance = new web3.utils.BN(balance);    

        if(riceBalance.lt(new web3.utils.BN("100000000000000000000000"))){
          alert(t("staking.error_not_enough_rice_for_staking", {rice: 100000}));

          setIsLoading(false);
          return;
        }
        
        var result = await RBPool11Contract.methods.stake().send({from: userAddr});
          
        if(result.status){
          alert(t("staking.staked_completed"));

          var expired = await RBPool11Contract.methods.StakingHolders(userAddr).call();
          setIsStaked(parseInt(expired));

          // sync with server
          var synced = await Moralis.Cloud.run("vip_sync_status", {lang: i18n.language});
          console.log("Sync VIP Status", synced);
        }
        
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  const extendStake = async () => {
    if(!isLoading){
      var userAddr = user.get('ethAddress');
      setIsLoading(true);
      try{
        var result = await RBPool11Contract.methods.extend().send({from: userAddr});
          
        if(result.status){
          alert(t("staking.staked_completed"));

          var expired = await RBPool11Contract.methods.StakingHolders(userAddr).call();
          setIsStaked(parseInt(expired));

          // sync with server
          var synced = await Moralis.Cloud.run("vip_sync_status", {lang: i18n.language});
          console.log("Sync VIP Status", synced);
        }
        
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  const cancelStake = async() => {
    if(!isLoading){
      var userAddr = user.get('ethAddress');
      setIsLoading(true);
      try{
        var result = await RBPool11Contract.methods.redeem().send({from: userAddr});
          
        if(result.status){
          alert(t("staking.error_redeem_completed"));
          setIsStaked(false);
        }
        
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
    
  }

  if (!rbContext) {
    return;
  }


  let { RBPool11Contract, riceContract, web3} = rbContext;

  return (
    <>
      <div className="badge-item-stat">
				<p className="text-sticker">
						<svg className="text-sticker-icon icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
						{t("staking.pool_11_name")}
				</p>
				<img className="badge-item-stat-image" width="90px" src="" alt="" />
				<p className="badge-item-stat-title">{t("staking.pool_11_title")}</p>
				<p className="badge-item-stat-text">{t("staking.pool_11_description")}</p>
        <ul className="badge-item-stat-text small list">
          <li>{t("staking.pool_11_desc_2")}</li>
          <li>{t("staking.pool_11_desc_3")}</li>
          <li>{t("staking.pool_11_desc_4")}</li>
          <li>{t("staking.pool_11_desc_5")}</li>
          <li>{t("staking.pool_11_desc_6")}</li>
          <li>{t("staking.pool_11_desc_7")}<br/><br/><br/><br/></li>
          
        </ul>
				<div className="badge-item-stat-text table table-quests split-rows">
					<div className="table-body same-color-rows">
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.earn")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_11_name")}
								</p>
							</div>
						</div>
            <div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.period")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.pool_11_redeem_policy")}</p>
							</div>
						</div>
          </div>
				</div>
        {
          isStaked > 0 &&
          <>
            <p className="badge-item-stat-text">
                <button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); extendStake();}}>
                  {isLoading ? t("general.loading") : t("staking.pool_11_button_extend")}
                </button>
            </p>
            <p className="badge-item-stat-text">
              {
                (isStaked >= Date.now() / 1000) &&
                <>
                {t("staking.expired_at", {expired: toLocalDateTime(isStaked * 1000)})}
                </>
              }
              {
                (isStaked < Date.now() / 1000) &&
                <>
                <button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); cancelStake();}}>
                  {isLoading ? t("general.loading") : t("staking.pool_11_button_cancel")}
                </button>
                </>
              }
              
            </p>
          </>
        }
        {isStaked == 0 && isPoolOpen && 
				<p className="badge-item-stat-text">

					{/* APPROVE BUTTON */}
					{ !isLoading && user && ! isRiceApproved && <><button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveRice();}}>{t("staking.approve_for_rice")}</button></> }

					{/* STAKE BUTTON */}
					{
						!isLoading && user && isRiceApproved &&
						<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doStake();}}>{t("staking.button_stake")}</button>
					}

					{/* LOADING BUTTON */}
					{ isLoading && <button className="button loading">{t("general.loading")}</button>}
          
				</p>
        }
        {
          isStaked == 0 && !isPoolOpen &&
          <p className="badge-item-stat-text">
              {t("staking.pool_is_closed")}
          </p>
        }
			</div>
    </>
  );
}
export default Stakes11;
