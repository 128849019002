import React, { useState, useEffect, useContext } from 'react';
import Extra from './../../Extra';
import RBContext from './../../RBContext';
import { useMoralis } from "react-moralis";

function Dashboard(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [batches, setBatches] = useState([]);
  let [rewards, setRewards] = useState([]);
  let [totalRice, setTotalRice] = useState(0);
  let [totalItem, setTotalItem] = useState(0); 
  let [loading, setLoading] = useState(false);
  let [contractPlayers, setContractPlayers] = useState([]);
  let [contractRices, setContractRices] = useState(0);
  let [contractItems, setContractItems] = useState(0);  
  let [offset, setOffset] = useState(0);

  const recalculateRewards = (newrewards) => {
    for(var i = 0; i < newrewards.length; i++){
        var found = false;
        for(var j = 0; j < rewards.length; j++){
            if(rewards[j].owner == newrewards[i].owner){
                // add up
                rewards[j].rice += newrewards[i].rice;
                rewards[j].item += newrewards[i].item;

                found = true;
                break;
            }
        }

        if(!found){
            rewards.push(newrewards[i]);
        }
    }

    setRewards(rewards);
    return rewards;
  }

  const getTotalBattles = () => {
    var totalBattle = 0;
    for(var i = 0; i < batches.length; i++){
        totalBattle += batches[i].battles.length;
    }

    return totalBattle;
  }

  const fetchUnclaimedRewards = async (limit, poffset = -1, today = false) => {
      try{
        
          setLoading(true);
          if(poffset == -1){
              poffset = offset;
          }
            var result = await Moralis.Cloud.run('admin_countUnclaimedBattles', {offset: poffset, limit: limit, today: today});
            console.log(result);
            var stop = false;
            if(result){
                // calculate for each batch
                var totalRice = 0;
                var totalItem = 0;
                var contractPlayers = [];
                var contractRices = [];
                var contractItems = [];
                for(var i = 0; i < result.Rewards.length; i++){
                    totalRice += result.Rewards[i].rice;
                    totalItem += result.Rewards[i].item;
                }
                batches.push({battles: result.Battles, totalRice: totalRice, totalItem: totalItem});
                
                var newrewards = recalculateRewards(result.Rewards);

                // calculate for all batches
                var totalRice = 0;
                var totalItem = 0;
                var contractPlayers = [];
                var contractRices = [];
                var contractItems = [];
                for(var i = 0; i < newrewards.length; i++){
                    totalRice += newrewards[i].rice;
                    totalItem += newrewards[i].item;

                    contractPlayers.push(newrewards[i].owner);
                    contractRices.push(newrewards[i].rice);
                    contractItems.push(newrewards[i].item);
                }
                setTotalRice(totalRice);
                setTotalItem(totalItem);
                setContractPlayers(contractPlayers);
                setContractRices(contractRices);
                setContractItems(contractItems);
            } else {
                stop = true;
            }

            setOffset(poffset + 1);
            setLoading(false);

            return stop;
      } catch(err){
          alert(err.message);
          setLoading(false);
      }
    
  }

  const fetchAll = async ()=>{
      for(var i = 0; i < 30; i++){
          await fetchUnclaimedRewards(2000, i);
          console.log("Batch " + (i + 1) + " done!");
      }
  }

  const fetchUnclaimedRewardsFix = async (limit, poffset = -1, today = false) => {
    try{
      
        setLoading(true);
        if(poffset == -1){
            poffset = offset;
        }
          var result = await Moralis.Cloud.run('admin_countUnclaimedBattles_fix', {offset: poffset, limit: limit, today: today});
          console.log(result);
          var stop = false;
          if(result){
              // calculate for each batch
              var totalRice = 0;
              var totalItem = 0;
              var contractPlayers = [];
              var contractRices = [];
              var contractItems = [];
              for(var i = 0; i < result.Rewards.length; i++){
                  totalRice += result.Rewards[i].rice;
                  totalItem += result.Rewards[i].item;
              }
              batches.push({battles: result.Battles, totalRice: totalRice, totalItem: totalItem});
              
              var newrewards = recalculateRewards(result.Rewards);

              // calculate for all batches
              var totalRice = 0;
              var totalItem = 0;
              var contractPlayers = [];
              var contractRices = [];
              var contractItems = [];
              for(var i = 0; i < newrewards.length; i++){
                  totalRice += newrewards[i].rice;
                  totalItem += newrewards[i].item;

                  contractPlayers.push(newrewards[i].owner);
                  contractRices.push(newrewards[i].rice);
                  contractItems.push(newrewards[i].item);
              }
              setTotalRice(totalRice);
              setTotalItem(totalItem);
              setContractPlayers(contractPlayers);
              setContractRices(contractRices);
              setContractItems(contractItems);
          } else {
              stop = true;
          }

          setOffset(poffset + 1);
          setLoading(false);

          return stop;
    } catch(err){
        alert(err.message);
        setLoading(false);
    }
  
}

  const fetch60kFix = async ()=>{
    for(var i = 0; i < 30; i++){
        await fetchUnclaimedRewardsFix(2000, i);
        console.log("Batch " + (i + 1) + " done!");
    }
}

  const fetchUntilToday = async ()=>{
    var i = 0;
    var stop = await fetchUnclaimedRewards(2000, i, true);
    console.log("Batch " + (i + 1) + " done!");
    while(!stop){
        i++;
        stop = await fetchUnclaimedRewards(2000, i, true);
        console.log("Batch " + (i + 1) + " done!");
    }
    
  }

  const resetData = () => {
    setBatches([]);
    setRewards([]);

    setTotalRice(0);
    setTotalItem(0);
    setContractItems([]);
    setContractPlayers([]);
    setContractRices([]);
  }

  const approveRewards = async (chunkIndex) => {
    try{
      var admin = user.get('ethAddress');

      setLoading(true);
      console.log(contractPlayers);
      var players = contractPlayers.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var rices = contractRices.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var items = contractItems.slice(chunkIndex * 1000, (chunkIndex + 1) *1000);

      // write to contract
      console.log(players);
      console.log(rices);
      console.log(items);
      
      var result = await RBRewardContract.methods.addReward(players, rices, items).send({from: admin});
      
      alert("Saved to contract");
      
      setLoading(false);
    } catch(err){
        alert(err.message);
        setLoading(false);
    }
  }

  const fixRewards = async (chunkIndex) => {
    try{
      var admin = user.get('ethAddress');

      setLoading(true);
      console.log(contractPlayers);
      var players = contractPlayers.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var rices = contractRices.slice(chunkIndex * 1000, (chunkIndex + 1) * 1000);
      var items = contractItems.slice(chunkIndex * 1000, (chunkIndex + 1) *1000);

      // write to contract
      console.log(players);
      console.log(rices);
      console.log(items);
      
      var result = await RBREWARDFIX_CONTRACT.methods.reduceReward(players, rices, items).send({from: admin});
      
      alert("Saved to contract");
      
      setLoading(false);
    } catch(err){
        alert(err.message);
        setLoading(false);
    }
  }

  const saveLogToDB = async () => {
    if(!window.confirm('Save to DB? Sure?')){
      return;
    }
    for(var i = 0; i < batches.length; i++){
        var params = {battles: batches[i].battles, totalRice: batches[i].totalRice, totalItem: batches[i].totalItem};

        console.log(params);
        // update admin
        var result = await Moralis.Cloud.run('admin_claimBattlesBoth', params);

        console.log(result);
    }            
        
    alert('OK');

    resetData();
  }

  const removeDuplicateBattles = async() => {
      if(!loading){

      
      var owner = window.prompt("Owner 1 or 2?");
      var month = window.prompt("Month? (from 1 to 12)");
      var date = window.prompt("Date? (1 to 31)");
      setLoading(true);
      if(owner && month && date){
          owner = parseInt(owner);
          month = parseInt(month);
          date = parseInt(date);
        try{
            var result = await Moralis.Cloud.run("admin_validateBattles", {owner: owner, month: month, date: date});
            console.log(result);
            alert("Battle removed: " + result.count);
            setLoading(false);
            } catch(err){
                alert(err.message);
                setLoading(false);
            }
      }
      
    }
  }

  useEffect(()=>{
    Extra.updateContentGridPos();    
  });

  if (!rbContext) {
    return null;
}

let { web3, RBRewardContract, RBREWARDFIX_CONTRACT } = rbContext;


  return (
      <>
        <div className='infoPanel'>
            <p className='white centered'>Remove duplicate battles at a specific date</p>
            <button className="button btn-block" onClick={(evt)=> {removeDuplicateBattles();}}>{loading ? "Loading..." : "Validate & Remove"}</button>
        </div>
        
        <div className="grid grid-4-4-4 top-space centered">
            <div className="badge-item-stat">
                <div className="badge-item-stat-text table table-quests split-rows">
                    <div className="table-body same-color-rows">
                        <div className="table-row small">
                            <div className="infoPanel">
                                <h4>Unclaimed Battles</h4>
                                <p>Total battles: {getTotalBattles()}</p>
                                <p>Total user award: {rewards.length}</p>
                                <p>Total RICE award: {totalRice}</p>
                                <p>Total GAME ITEM award: {totalItem}</p>

                                {!loading && totalRice > 0 && 
                                <><button className="button btn-block" onClick={(evt) => {fixRewards(0)}}>Fix batch 1200 - 1</button>
                                <button className="button btn-block" onClick={(evt) => {fixRewards(1)}}>Fix batch 1200 - 2</button>
                                <button className="button btn-block" onClick={(evt) => {fixRewards(2)}}>Fix batch 1200 - 3</button>
                                </>
                                }
                                {loading && totalRice > 0 &&
                                <button className="button btn-block">Loading data...</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-12 top-space centered">
            <div id="admin-logsdata" className="contentPanel">
            {!loading &&
        <>
        <div className="form-row split">
            <div className="form-item"><button className="button btn-block" onClick={(evt)=> {fetchUnclaimedRewards(3000);}}>Fetch 3000 battles - Page: {offset + 1}</button>
        </div>
            <div className="form-item"><button className="button btn-block" onClick={(evt) => {fetchUnclaimedRewards(5000);}}>Fetch 5000 battles - Page: {offset + 1}</button>
        </div>
            <div className="form-item"><button className="button btn-block" onClick={(evt) => {fetchAll();}}>Fetch 60,000 battles</button>
        </div>
        <div className="form-item"><button className="button btn-block" onClick={(evt) => {fetch60kFix();}}>Fetch 60,000 ERRORs</button>
        </div>
            <div className="form-item"><button className="button btn-block" onClick={(evt) => {fetchUntilToday();}}>Fetch All Battles before Today</button>
        </div>
        </div>
        
        </>
        }
        {loading &&
        <button className="button btn-block">Loading data...</button>
        }
                <h3>Logs data</h3>
                <h4>Battles to be verified</h4>
                {batches && batches.map((batch, index) => (
                <div key={index}>
                    <h5>Batch {index + 1}</h5>
                    <ul>
                        {
                            batch.battles.map((battle, index2) => (
                                <li key={index +"-"+index2}>{index2 + 1}. {battle}</li>
                            ))
                        }
                    </ul>
                </div>))}
            </div>
        </div>
        
        
      </>
    )
}
export default Dashboard;