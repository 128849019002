function LoadingIcon(){
  return(
    <>
    <div>
      <span className="waiting-loading">Fetching data...</span>
    </div>
    </>
  );
}

export default LoadingIcon;
