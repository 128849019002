import React, { useEffect, useState, Suspense } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Marketplace from './Marketplace';
import UserProfile from './UserProfile';
import StakingPage from './StakingPage';
import Leaderboard from './Leaderboard';
import SuperAdmin from './SuperAdmin';
import Moderator from './components/moderator/Moderator';
import NFTShop from './components/marketplace/NFTShop';
import NFTCollector from './components/NFTCollector';
import { useTranslation } from "react-i18next";

import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";

function MainPage(){
    const { t, i18n } = useTranslation(); 

    return (
    <div className={"lang-" + i18n.language}>
    <Router>
        <Routes>
            <Route path="/profile/*" element={<><Header /><UserProfile /><Footer /></>} />
            <Route path="/leaderboard" element={<><Header />                <Leaderboard />                <Footer /></>} />
            <Route path="/superadmin/*" element={<><Header />                <SuperAdmin />                <Footer /></>} />
            <Route path="/moderator" element={<><Header />                <Moderator />                <Footer /></>} />
            <Route path="/staking" element={<><Header />                <StakingPage />                <Footer /></>} />
            <Route path="/marketplace/*" element={<><Header />                <Marketplace />                <Footer /></>} />
            <Route path="/shop" element={<><Header />                <NFTShop />                <Footer /></>} />
            <Route path="/tool/collector" element={<><Header />                <NFTCollector />                <Footer /></>} />
            <Route path="*" element={<><Header />                <Marketplace />                <Footer /></>} />
        </Routes>
    </Router>
</div>);
}
export default MainPage;