import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Routes,
  Route, useParams, useMatch } from "react-router-dom";
import RBContext from '../../RBContext';
import BattlesHistoryMain from './BattlesHistoryMain';
import ScholarBattlesHistory from './ScholarBattlesHistory';

function BattlesHistory(props){
  const rbContext = useContext(RBContext);
  const { Moralis, user } = useMoralis();

  useEffect( () => {
      
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  return(
    <>
      <Routes>
          <Route path="scholar" element={<ScholarBattlesHistory />} />
          <Route path="*" element={<BattlesHistoryMain />} />
        </Routes>
    </>
  )
}

export default BattlesHistory;