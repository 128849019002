import { useTranslation } from "react-i18next";

function PageLoader(){
  const { t, i18n } = useTranslation();

  return(
  <div className="page-loader">
    <div className="page-loader-decoration">
      <img width="250" src={process.env.REACT_APP_HOME_URL + "assets/roosterbattle_logo.png"}/>
    </div>
    <div className="page-loader-info">
      <p className="page-loader-info-text">{t("general.app_loading")}</p>
    </div>
    <div className="page-loader-indicator loader-bars">
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
    </div>
  </div>
)
}

export default PageLoader;
