import React, { useState, useEffect, useContext } from 'react';
import LoadingIcon from './../LoadingIcon';
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Routes,
  Route, useParams, useMatch } from "react-router-dom";
import RBContext from './../../RBContext';

const TableRow = (battleInfo, roosterTypes, user, index) => {
  if(user){
    
    if(battleInfo){
      let award = '';
      const battleLevel = battleInfo.get('BattleLevel');
      const rooster2Win = battleInfo.get('Rooster2Win');
      const player1 = battleInfo.get('Rooster1Owner');
      const player2 = battleInfo.get('Rooster2Owner');
      const rooster1 = battleInfo.get('Rooster1TokenId');
      const rooster2 = battleInfo.get('Rooster2TokenId');

      var claimedStatus = "";
      let winner = 0;
      let opponent = '0x0';
      let rooster = '';
      var myRooster = '';
      /*
      console.log('Current: ' + userAddress);
      console.log('Player 1: ' + player1);
      console.log('Player 2: ' + player2);
      */
      if(player1 == user){
        // you are player 1
        opponent = player2;
        rooster = rooster2;
        myRooster = rooster1;
        claimedStatus = battleInfo.get('Rooster1ClaimedStatus') ? 'Claimed' : 'Unverified';
    
        if(rooster2Win){
          // Player 2 win, so you lose
          winner = 0;
        } else {
          // Player 1 win, so you win
          winner = 1;
        }
      } else {
        // you are player 2
        opponent = player1;
        rooster = rooster1;
        myRooster = rooster2;
        claimedStatus = battleInfo.get('Rooster2ClaimedStatus') ? 'Claimed' : 'Unverified';
    
        if(rooster2Win){
          // Player 2 win, so you win
          winner = 1;
        } else {
          // Player 1 win, so you lose
          winner = 0;
        }
      }

      if(battleInfo.get("ValidBattle") === false){
        claimedStatus = "Invalid Battle";
      }
      
      var rewardx2 = ' ';
      for(var j = 0; j < roosterTypes.length; j++){
        if(roosterTypes[j].id == myRooster){
          
          if(parseInt(roosterTypes[j].type) > 6){
            rewardx2 = ' (x2 for Rare Type) ';
          }
          break;
        }
      }

      var defaultRiceWinReward = 10;
      var defaultRiceLoseReward = 3;
      var battleTimestamp = battleInfo.get("BattleTimestamp");
      var itemReward = '& 3 GAME ITEMS';
      if(battleTimestamp > 1642377600000){
        // 2022-01-16 07:00:00
        defaultRiceWinReward = 8;
        defaultRiceLoseReward = 2;
        //itemReward = '';
      }

      if(battleLevel == 1){
        if(winner){
          award = defaultRiceWinReward + ' RICEs' + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + ' RICEs' + rewardx2;
        }
      } else if(battleLevel == 2){
        if(winner){
          award = defaultRiceWinReward + ' RICEs' + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + ' RICEs' + rewardx2;
        }
      }

      let timestamp = battleInfo.get('BattleTimestamp');
      // Create a new JavaScript Date object based on the timestamp
      var date = new Date(timestamp);

      // Will display time in 10:30:23 format
      var formattedTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();
      var className = "table-row medium";
      
      
      return (
      <div className="table-row medium" key={battleInfo.id}>
        <div className="table-column">
          <span className="mobile-title">Index: </span>{index}
        </div>
        <div className="table-column">
          <div className="discussion-preview">
            <p>Fight with: <a href="#">{rooster}</a> on <span> {formattedTime} </span></p>
            <p>Your Rooster: <a href="#">{myRooster}</a></p>
          
            <div className="discussion-preview-meta">
              <p className="discussion-preview-meta-text">Opponent: </p>
              <p className="discussion-preview-meta-text"><a href="#">{opponent}</a></p>
            </div>
          </div>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Battle Level: </span>{battleLevel == 1 ? 'LIGHTWEIGHT' : 'ARMORED'}</p>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Status: </span>{winner ? 'WIN' : 'LOSE'}</p>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Award: </span>{award}</p>
        </div>
        <div className="table-column padded-big-left">
          {claimedStatus}
        </div>
      </div>);
    }
  }
}

function UserBattles(props){
  const rbContext = useContext(RBContext);

  let [battles, setBattles] = useState([]);

  let match = useMatch();

  let [loading, setLoading] = useState(false);
  let [processing, setProcessing] = useState(false);
  const { Moralis, user } = useMoralis();
  let [rareTypes, setRareTypes] = useState([]);
  let [offset, setOffset] = useState(0);
  let [moreResults, setMoreResults] = useState(true);
  let [testBattle, setTestBattle] = useState(false);
  let [userWallet, setUserWallet] = useState('');
  
  const loadMoreBattles = async () => {
    if(moreResults){
      setProcessing(true);
      getHistoryBattles(battles.length);
      setProcessing(false);
    }
  }

  // marketplace
  const getHistoryBattles = async (offset) => {
      let userAddress = user.get('ethAddress');
      if(userAddress){
        userAddress = userWallet.toLowerCase();
              
      try {
          setLoading(true);
          var results = await Moralis.Cloud.run('getBattleHistories', {sender: userWallet, offset: offset});
          
          if(results){
            var allIDs = [];
            
            for(var i = 0; i < results.length; i++){
              
              allIDs.push(results[i].id);

              var rareType = 0;
              var roosterId = results[i].get('Rooster1TokenId');
              if(results[i].get('Rooster2Owner') == userAddress){
                roosterId = results[i].get('Rooster2TokenId');
              }

              var foundRooster = false;
              for(var j = 0; j < rareTypes.length; j++){
                if(rareTypes[j].id == roosterId){
                  foundRooster = true;
                  break;
                }
              }

              if(!foundRooster){
                rareType = await nftContract.methods.getRBType(roosterId).call();
                rareTypes.push({id: roosterId, type: parseInt(rareType)});
              }
              
            }
            
            setRareTypes(rareTypes);
            
            if(offset == 0){
              setBattles(results);
            } else {
              var newbattles = battles ? battles.concat(results) : results;
              console.log(newbattles);
              setBattles(newbattles);
            }
          }

          if(results.length < 100){
            setMoreResults(false);
          }
          
          setLoading(false);
      } catch (error) {
          const code = error.code;
          const message = error.message;
          console.log(code + ':' + message);
      }
    }
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          //getHistoryBattles(0);
        }
        //fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { nftContract } = rbContext;

  return(
    <>
      <input id="userWallet" placeholder='Enter user wallet' type="text" onChange={event => setUserWallet(event.target.value)}/>
      <div className="table table-battles">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">Index</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">Battle Info</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">LEVEL</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">RESULT</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">AWARDS</p>
          </div>
          <div className="table-header-column padded-big-left">
            <p className="table-header-title">STATUS</p>
          </div>
        </div>
        <div className="table-body">
          {battles.length > 0 ?
            battles.map((item, index) => TableRow(item, rareTypes, userWallet, index + 1)) : ''
          }
        </div>
    </div>
    {battles.length > 0 && processing &&
    <div className="centered paragraph">
      <button className="button primary">processing...</button>
    </div>
    }
    {
      !loading && moreResults && <div className="centered paragraph">
        <button className='button' onClick={loadMoreBattles}>{processing ? "loading..." : "Load More"}</button>
      </div>
    }
    {
      loading &&

      <div className="centered paragraph white no-battles">loading histories...</div>
    }
    {
      !loading && battles.length == 0 &&

      <div className="centered paragraph white no-battles">NO BATTLES SO FAR, START FIGHTING NOW!</div>
    }
    </>
  )
}

export default UserBattles;