import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../RBContext';
import { useTranslation } from "react-i18next";

const TableRow = (battleInfo, user, index) => {
  if(user){
    var userAddress = user.get('ethAddress');

    if(battleInfo){
      return (
      <div className={(userAddress.toLowerCase() == battleInfo.Owner ? "current" : "") + (index <= 10 ? " table-row medium top" : " table-row medium") + (" position-" + index)} key={index}>
        <div className="table-column">
          <span className="mobile-title">No. </span>{index}
        </div>
        <div className="table-column">
          <div className="user-address">
            {
              index <= 50 &&
              <p><span className="mobile-title">Wallet: </span><a href={process.env.REACT_APP_HOME_URL + "leaderboard/histories/" + battleInfo.Owner} target="_blank">{battleInfo.Owner}</a></p> 
            }
            {
              index > 50 &&
              <p><span className="mobile-title">Wallet: </span>{battleInfo.Owner}</p>
            }
            
          </div>
        </div>
        <div className="table-column centered padded-medium">
          <p className="battle-win"><span className="mobile-title">Total Point: </span>{battleInfo.TotalPoint}</p>
          <p className="small">Win: {battleInfo.TopWin} / Lose: {battleInfo.TopLose}</p>
        </div>
      </div>);
    }
  }
}

function LeaderboardItems(props){
  const { t, i18n } = useTranslation();

  const rbContext = useContext(RBContext);

  let [owners, setOwners] = useState([]);

  let [loading, setLoading] = useState(true);
  const { Moralis, user } = useMoralis();

  // marketplace
  const getBoard = async () => {
      const params =  {
        user: user.get('ethAddress'),
        lang: i18n.language
      };

      try {
          var results = await Moralis.Cloud.run('getTournamentResult', params);
          console.log(results);
          setOwners(results);
          setLoading(false);

      } catch (error) {
          const code = error.code;
          const message = error.message;
          console.log(code + ':' + message);
      }
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          getBoard();
        }
        fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { web3 } = rbContext;

  return(
    <>
      <h2 id="leaderboard-heading">Your position: { (owners && owners.Board && owners.UserIndex >= 0) ? (owners.UserIndex) : "N/A"}</h2>
      <h3 id="leaderboard-updated">Updated at: {owners && owners.Updated && ((new Date(owners.Updated * 1000)).toLocaleDateString() + " " + (new Date(owners.Updated * 1000)).toLocaleTimeString()) }</h3>
      <p className="align-left block link"><a href="https://roosterbattle.substack.com/p/rooster-battle-heavyweight-championship" target="_blank">Check out rewards for this tournament</a></p>
      <div className="table table-leaderboard tournament-1">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">Position</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">Warriors Owner</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">Total Point</p>
          </div>
        </div>
        <div className="table-body">
          {(owners && owners.Board && owners.Board.length > 0) ?
            owners.Board.map((item, index) => TableRow(item, user, index + 1)) : ''
          }
        </div>
    </div>
    {
      loading &&

      <div className="centered paragraph white no-battles">loading loaderboard...</div>
    }
    {
      !loading && owners.Board.length == 0 &&

      <div className="centered paragraph white no-battles">LEADERBOARD IS NOT READY YET</div>
    }
    </>
  )
}

export default LeaderboardItems;