import React, { useState, useEffect, useContext } from 'react';
import Extra from './../../Extra';
import RBContext from './../../RBContext';
import { useMoralis } from "react-moralis";

function ResetReward(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [wallet, setWallet] = useState("");
  let [rice, setRice] = useState(0);
  let [item, setItems] = useState(0);

  const setReward = async () => {
    if(user){
        var admin = user.get('ethAddress');
        console.log(wallet);
        console.log(parseInt(rice));
        console.log(parseInt(item));
        if(wallet && parseInt(rice) && parseInt(item)){
            try{
                var result = await RBRewardContract.methods.resetAward(wallet, rice, item).send({from: admin});
                if(result.status){
                    console.log(result);
                }
            } catch(err){
                alert(err.message);
            }
        }
    }
  }

  

  useEffect(()=>{
    Extra.updateContentGridPos();    
  });

  if (!rbContext) {
    return null;
}

let { web3, RBRewardContract } = rbContext;


  return (
      <>
            <input type="text" placeholder='Wallet' onChange={event => setWallet(event.target.value)}/>
            <input type="text" placeholder='Rice' onChange={event => setRice(event.target.value)}/>
            <input type="text" placeholder='Items' onChange={event => setItems(event.target.value)}/>
            <button className="button btn-block" onClick={setReward}>Reset Reward</button>
        
      </>
    )
}
export default ResetReward;