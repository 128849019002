function NFTThumb(props){
  var rooster_thumb = props.ItemType;
  
  if(props.Armors && parseInt(props.Armors[0]) > 0){
    rooster_thumb += '_' + props.Armors[0];
  }
    return (
        <>{(props.TokenType == 'ROOSTER' && props.Status != 'egg') && 
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/rooster_" + rooster_thumb + ".png"} alt={props.TokenId}/>
          }
          {(props.TokenType == 'ROOSTER' && props.Status == 'egg') && 
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/goi_trung_01.png"} alt={props.TokenId}/>
          }
          {props.TokenType == 'SKILL' &&
            <img src={process.env.REACT_APP_HOME_URL + "assets/skills/skill_" + (1 + (props.ItemType % 65)) + ".png"} alt={props.TokenId}/>
          }
          {props.TokenType == 'GAMEITEM' &&
            <img src={process.env.REACT_APP_HOME_URL + "assets/gameitems/gameitem_" + props.TokenId + ".png"} width="186" height="186" />
          }
          {props.TokenType == 'TICKET' &&
            <img src={process.env.REACT_APP_HOME_URL + "assets/egg_" + (props.EggThumb ? props.EggThumb : '') + ".png"} height="186" />
          }
          {props.TokenType == 'HEN' &&
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/ga_mai_" + props.ItemType + ".png"} width="186" height="186" />
          }
        </>
    )
}

export default NFTThumb;