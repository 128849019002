import React, {useEffect, useContext, useState} from 'react';
import { useMoralis } from "react-moralis";
import {
  Link, useMatch
} from "react-router-dom";
import RBContext from './../../RBContext';

function NFTAirdrops(props){
  const rbContext = useContext(RBContext);

  let [loading, setLoading] = useState(true);
  let [claiming, setClaiming] = useState(false);
  let [isTopLeaderBoard, setTopLeaderBoard] = useState(false);
  let [isStakerPool3, setStakerPool3] = useState(false);
  let [isDojoAirdrop, setDojoAirdrop] = useState(0);

  const { user } = useMoralis();

  const buyButton = (type) => {
    if(claiming){
      return (<a className="button loading btn-block btn-buyPresale centered" href="#">...processing...</a>);
    }
    if(type == 1){
      return (<a className="button btn-block btn-buyPresale" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();claimAsTestNetLeaderboardNow();}}>Claim Now</a>);
    }
    
    if(type == 2){
      return (<a className="button btn-block btn-buyPresale" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();claimAsStaker3Now();}}>Claim Now</a>);
    }

    if(type == 3){
      return (<a className="button btn-block btn-buyPresale" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();claimFromDojo();}}>Claim Now</a>);
    }
    
  }

  const claimAsTestNetLeaderboardNow = async () => {
    setClaiming(true);
    try{
      const currentUser = user.get('ethAddress');
      await minterContract.methods.leaderboardAward(Math.floor(Math.random() * 10000)).send({from:currentUser});
      alert('Claimed successfully. You can check your assets in a few minutes');
      setTopLeaderBoard(false);
      setClaiming(false);
    } catch(err){
      alert(err.message);
      setClaiming(false);
    }
  }

  const claimAsStaker3Now = async () => {
    setClaiming(true);
    try{
      const currentUser = user.get('ethAddress');
      await minterContract.methods.pool3Award(Math.floor(Math.random() * 10000)).send({from:currentUser});
      alert('Claimed successfully. You can check your assets in a few minutes');
      setStakerPool3(false);
      setClaiming(false);
    } catch(err){
      alert(err.message);
      setClaiming(false);
    }
  }

  const claimFromDojo = async () => {
    setClaiming(true);
    try{
      const currentUser = user.get('ethAddress');
      await dojoContract.methods.claimSkillsForAirdrop(Math.floor(Math.random() * 10000)).send({from:currentUser});
      alert('Claimed successfully. You can check your assets in a few minutes');
      setDojoAirdrop(false);
      setClaiming(false);
    } catch(err){
      alert(err.message);
      setClaiming(false);
    }
  }

  if (!rbContext) {
      return null;
  }

  let { dojoContract, minterContract } = rbContext;

  return (
    (!loading && !isTopLeaderBoard && !isStakerPool3 && isDojoAirdrop == 0) ? <><div className="centered paragraph white no-items">You do not have any airdrops</div></>:<>
        {!loading && isTopLeaderBoard &&
        <div className="product-preview">
          <a href="#">
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/goi_trung_01.png"} width="186px" alt="Rooster Battle NFT"/>
          </a>
          <div className="product-preview-info">
            <p className="text-sticker">1 Rooster</p>
            <p className="product-preview-title">#NFT</p>
            <p className="product-preview-category">Airdrop</p>
            <p className="product-preview-text">You are awarded 1 NFT Rooster as Testnet Top Leaderboard</p>
          </div>
          <div className="product-preview-meta">{buyButton(1)}</div>
        </div>
        }

        {!loading && isStakerPool3 &&
        <div className="product-preview">
          <a href="#">
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/goi_trung_01.png"} width="186px" alt="Rooster Battle NFT"/>
          </a>
          <div className="product-preview-info">
            <p className="text-sticker">2 Roosters</p>
            <p className="product-preview-title">#NFT</p>
            <p className="product-preview-category">$4000 RICE Stakers</p>
            <p className="product-preview-text">You are awarded 2 NFT Roosters as Staker Pool 3</p>
          </div>
          <div className="product-preview-meta">{buyButton(2)}</div>
        </div>
        }

        {!loading && isDojoAirdrop &&
        <div className="product-preview">
          <a href="#">
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/goi_trung_01.png"} width="186px" alt="Rooster Battle NFT"/>
          </a>
          <div className="product-preview-info">
            <p className="text-sticker">NFT Skill</p>
            <p className="product-preview-title">#NFT</p>
            <p className="product-preview-category">Airdrop &amp; Top Leaderboard</p>
            <p className="product-preview-text">You are awarded {isDojoAirdrop} NFT Skills</p>
          </div>
          <div className="product-preview-meta">{buyButton(3)}</div>
        </div>
        }{
          loading &&

          <div className="centered paragraph white no-items">Checking for airdrops...</div>
        }
      </>
    );
}
export default NFTAirdrops;
