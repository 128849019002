import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import { toLocalDateTime } from '../../utils/RBUtils';
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function MyScholarship(props){
    const rbContext = useContext(RBContext);
    const { Moralis, user } = useMoralis();
    let [myScholars, setMyScholars] = useState([]);
    const { t, i18n } = useTranslation();

    const init = async()=>{
        var scholars = await Moralis.Cloud.run('scholarship_getMyPrimaries', {lang: i18n.language});
        setMyScholars(scholars);
    }
  
    useEffect(() => {
        async function fetchData(){
            init();
        }
        
        fetchData();
    }, [rbContext]);

    if (!rbContext) {
        return null;
    }

    return (
        user ? 
        <>
        <div className='tab-box'>
                <div className='tab-box-options'>
                    <div className='tab-box-option'>
                        <a href={process.env.REACT_APP_HOME_URL + "profile/scholars/"}>Scholarship Management</a>
                    </div>
                    <div className='tab-box-option active'>
                        My Scholarship
                    </div>
                </div>
                <div className='tab-box-tems'>
                    <div className='tab-box-item'>
                    </div>
                    <div className='tab-box-item'>
                    <p className='block white'>Read more about Scholarship <a href="#">here</a></p>
                        {myScholars.length ? 
                        <div className='form'>
                        <div className="table table-battles">
                            <div className="table-header">
                                <div className="table-header-column">
                                    <p className="table-header-title">Index</p>
                                </div>
                                <div className="table-header-column">
                                    <p className="table-header-title">Wallet</p>
                                </div>
                                <div className="table-header-column centered padded-medium">
                                    <p className="table-header-title">Scholarship</p>
                                </div>
                                <div className="table-header-column padded-medium">
                                    <p className="table-header-title">History</p>
                                </div>
                            </div>
                            <div className="table-body">
                                {myScholars.map((scholar, index) => {
                                    return (<div className="table-row medium" key={"scholar-" + index}>
                                            <div className="table-column">
                                                <span className="mobile-title">Index: </span>{index + 1}
                                            </div>
                                            <div className="table-column ">
                                                <span className="mobile-title">Wallet: </span><a href={"https://roosterbattle.net/play/?address=" + scholar.wallet} target="_blank">{scholar.wallet}</a>
                                            </div>
                                            <div className="table-column centered">
                                                <span className="mobile-title">Percentage: </span>{scholar.percentage}%
                                            </div>
                                            <div className="table-column">
                                                <span className="mobile-title">Histories: </span>{scholar.histories.map((history, index)=>{return <p key={"history-" + history.timestamp + "-" + index}>{history.description} - {toLocalDateTime(history.timestamp)}</p>})}
                                            </div>
                                        </div>);
                                })}
                            </div>
                        </div>
                        </div>
                        :
                        <><p className='centered'>You don't have any scholarship</p></>}
                    </div>
                </div>
            </div>
        
        
        </> : <></>
    );
};

export default MyScholarship;