import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";
import { getRoosterName } from "./../../utils/RBUtils";

function RoosterInfo(props){
  const rbContext = useContext(RBContext);
  const { Moralis, user } = useMoralis();

  const tokenDetail = props.token;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    

  }, [rbContext, user, props.token]);

  if (!rbContext) {
      return null;
  }

  const attack_defense_el = (attack, defense) => {
    return (
        <>
        <p className="product-preview-text">{t("rooster.attack_detail", {slots: attack.join(', ')})}</p>
        <p className="product-preview-text">{t("rooster.defense_detail", {slots: defense.join(', ')})}</p>
        </>
      );
  }

  return (
      <>
        <div className="product-preview roosterinfo">
          {
            <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/rooster_" + tokenDetail.type + ".png"} alt={tokenDetail.id}/>
          }
          <div className="product-preview-info">
            <p className="product-preview-category">{t("profile.nft_type", {type: getRoosterName(tokenDetail.type)})}</p>
            <p className="product-preview-text">{(tokenDetail.displayname ? (tokenDetail.displayname + " (#" + tokenDetail.id + ")") : "#" + tokenDetail.id)}</p>
            
            {attack_defense_el(tokenDetail.attack, tokenDetail.attack)}
            {((tokenDetail.armors && tokenDetail.armors.length == 4 && tokenDetail.armors[0] == 1) ? <><p className="product-preview-text"><strong>{t("general.armored_rooster")}</strong></p></> : <></>)}
            
          </div>
        </div>
      </>
    );
}
export default RoosterInfo;
