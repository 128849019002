import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { useTranslation, Trans } from "react-i18next";

function Stakes45(props) {
  let [isLoading1, setIsLoading1] = useState(true);
  let [isLoading2, setIsLoading2] = useState(true);
  let [isApproved1, setIsApproved1] = useState(false);
  let [isApproved2, setIsApproved2] = useState(false);
  let [isLoading3, setIsLoading3] = useState(true);
  let [isApproved3, setIsApproved3] = useState(false);
  let [isLoading4, setIsLoading4] = useState(true);
  let [isApproved4, setIsApproved4] = useState(false);
  let [isPoolOpen, setPoolOpen] = useState(false);
  const { t, i18n } = useTranslation(); 

  const { user } = useMoralis();
  
  const rbContext = useContext(RBContext);

  useEffect(() => {
    async function init(){
      if(user){
        setPoolOpen(true);
        var userAddr = user.get('ethAddress');
  
        let [ riceAllowance, ricePool6Allowance, lpAllowance, lpPool7Allowance ] = await Promise.all([
          riceContract.methods.allowance(userAddr, process.env.REACT_APP_POOL4_ADDRESS).call(),
          riceContract.methods.allowance(userAddr, process.env.REACT_APP_POOL6_ADDRESS).call(),
          LPCakeContract.methods.allowance(userAddr, process.env.REACT_APP_POOL5_ADDRESS).call(),
          LPCakeContract.methods.allowance(userAddr, process.env.REACT_APP_POOL7_ADDRESS).call()]);
          
          riceAllowance = new web3.utils.BN(riceAllowance);
          lpAllowance = new web3.utils.BN(lpAllowance);
          ricePool6Allowance = new web3.utils.BN(ricePool6Allowance);
          lpPool7Allowance = new web3.utils.BN(lpPool7Allowance);
          
  
          if(riceAllowance.gte(new web3.utils.BN("1000000000000000000000"))){
            setIsApproved1(true);
          }
  
          if(ricePool6Allowance.gte(new web3.utils.BN("1000000000000000000000"))){
            setIsApproved3(true);
          }
          
          if(lpAllowance.gte(new web3.utils.BN("20000000000000000000"))){
            setIsApproved2(true);
          }
  
          if(lpPool7Allowance.gte(new web3.utils.BN("20000000000000000000"))){
            setIsApproved4(true);
          }
      }
  
      setIsLoading1(false);
      setIsLoading2(false);
      setIsLoading3(false);
      setIsLoading4(false);
    }
    init();
  }, [user, rbContext])

  const approveRice = async () => {
    try{
      var userAddr = user.get('ethAddress');
      setIsLoading1(true);
      var result = await riceContract.methods.approve(process.env.REACT_APP_POOL4_ADDRESS, new web3.utils.BN( "1000000000000000000000000")).send({from: userAddr});
      setIsApproved1(true);
      setIsLoading1(false);
    } catch(err){
      alert(err.message);
      setIsLoading1(false);
    }
  }

  const doRiceStake = async () => {
    var userAddr = user.get('ethAddress');
    
    var balance = await riceContract.methods.balanceOf(userAddr).call();
    var riceBalance = new web3.utils.BN(balance);    

    var count = prompt(t("staking.pool_4_how_many_stakes"));
    
    if(count){
      count = parseInt(count);
      if(count){
        if(riceBalance.lt(new web3.utils.BN( (count * 1000) + "000000000000000000"))){
          alert(t("staking.pool_4_not_enough_rices"));
        } else {
          try{
            setIsLoading1(true);
            var allowance = await riceContract.methods.allowance(userAddr, process.env.REACT_APP_POOL4_ADDRESS).call();
            var riceAllowance = new web3.utils.BN(allowance);
            if(riceAllowance.lt(new web3.utils.BN((count * 1000) + "000000000000000000"))){
              setIsApproved1(false);
              approveRice();
            }

            setIsApproved1(true);
            var result = await RBPool4Contract.methods.stake(count).send({from: userAddr});

            alert(t("staking.staked_completed"));
            setIsLoading1(false);
          } catch(err){
            alert(err.message);
            setIsLoading1(false);
          }
        }
      }
      
    }
  }

  const approveRicePool6 = async () => {
    try{
      var userAddr = user.get('ethAddress');
      setIsLoading3(true);
      var result = await riceContract.methods.approve(process.env.REACT_APP_POOL6_ADDRESS, new web3.utils.BN( "1000000000000000000000000")).send({from: userAddr});
      setIsApproved3(true);
      setIsLoading3(false);
    } catch(err){
      alert(err.message);
      setIsLoading3(false);
    }
  }

  const doRiceStakePool6 = async () => {
    var userAddr = user.get('ethAddress');
    
    var balance = await riceContract.methods.balanceOf(userAddr).call();
    var riceBalance = new web3.utils.BN(balance);    

    var count = prompt(t("staking.pool_4_how_many_stakes"));
    
    if(count){
      count = parseInt(count);
      if(count){
        if(riceBalance.lt(new web3.utils.BN( (count * 1000) + "000000000000000000"))){
          alert(t("staking.pool_4_not_enough_rices"));
        } else {
          try{
            setIsLoading3(true);
            var allowance = await riceContract.methods.allowance(userAddr, process.env.REACT_APP_POOL6_ADDRESS).call();
            var riceAllowance = new web3.utils.BN(allowance);
            if(riceAllowance.lt(new web3.utils.BN((count * 1000) + "000000000000000000"))){
              setIsApproved3(false);
              approveRicePool6();
            }

            setIsApproved3(true);
            var result = await RBPool6Contract.methods.stake(count).send({from: userAddr});

            alert(t("staking.staked_completed"));
            setIsLoading3(false);
          } catch(err){
            alert(err.message);
            setIsLoading3(false);
          }
        }
      }      
    }
  }

  const approveLP = async () => {
    try{
      var userAddr = user.get('ethAddress');
      setIsLoading2(true);
      var result = await LPCakeContract.methods.approve(process.env.REACT_APP_POOL5_ADDRESS, new web3.utils.BN( "200000000000000000000")).send({from: userAddr});
      setIsApproved2(true);
      setIsLoading2(false);
    } catch(err){
      alert(err.message);
      setIsLoading2(false);
    }
  }

  const doLPStake = async () => {
    var userAddr = user.get('ethAddress');
    
    var balance = await LPCakeContract.methods.balanceOf(userAddr).call();
    var lpBalance = new web3.utils.BN(balance);    

    var count = prompt(t("staking.pool_4_how_many_ricelp"));
    
    if(count){
      count = parseInt(count);
      if(count){
        if(lpBalance.lt(new web3.utils.BN( (count * 20) + "000000000000000000"))){
          alert(t("staking.pool_4_not_enough_ricelp"));
        } else {
          try{
            setIsLoading2(true);
            var allowance = await LPCakeContract.methods.allowance(userAddr, process.env.REACT_APP_POOL5_ADDRESS).call();
            var lpAllowance = new web3.utils.BN(allowance);
            if(lpAllowance.lt(new web3.utils.BN((count * 20) + "000000000000000000"))){
              setIsApproved2(false);
              approveRice();
            }

            setIsApproved2(true);
            var result = await RBPool5Contract.methods.stake(count).send({from: userAddr});

            alert(t("staking.staked_completed"));
            setIsLoading2(false);
          } catch(err){
            alert(err.message);
            setIsLoading2(false);
          }
        }
      }
      
    }
  }

  const approveLPPool7 = async () => {
    try{
      var userAddr = user.get('ethAddress');
      setIsLoading4(true);
      var result = await LPCakeContract.methods.approve(process.env.REACT_APP_POOL7_ADDRESS, new web3.utils.BN( "200000000000000000000")).send({from: userAddr});
      setIsApproved4(true);
      setIsLoading4(false);
    } catch(err){
      alert(err.message);
      setIsLoading4(false);
    }
  }

  const doLPStakePool7 = async () => {
    var userAddr = user.get('ethAddress');
    
    var balance = await LPCakeContract.methods.balanceOf(userAddr).call();
    var lpBalance = new web3.utils.BN(balance);    

    var count = prompt(t("staking.pool_4_how_many_ricelp"));
    
    if(count){
      count = parseInt(count);
      if(count){
        if(lpBalance.lt(new web3.utils.BN( (count * 20) + "000000000000000000"))){
          alert(t("staking.pool_4_not_enough_ricelp"));
        } else {
          try{
            setIsLoading4(true);
            var allowance = await LPCakeContract.methods.allowance(userAddr, process.env.REACT_APP_POOL7_ADDRESS).call();
            var lpAllowance = new web3.utils.BN(allowance);
            if(lpAllowance.lt(new web3.utils.BN((count * 20) + "000000000000000000"))){
              setIsApproved4(false);
              approveRice();
            }

            setIsApproved4(true);
            var result = await RBPool7Contract.methods.stake(count).send({from: userAddr});

            alert(t("staking.staked_completed"));
            setIsLoading4(false);
          } catch(err){
            alert(err.message);
            setIsLoading4(false);
          }
        }
      }
      
    }
  }

  

  if (!rbContext) {
    return;
  }


  let { riceContract, RBPool4Contract, RBPool5Contract, RBPool6Contract, RBPool7Contract, LPCakeContract, web3 } = rbContext;

  return (
    <>
      <div className="badge-item-stat">
				<p className="text-sticker">
						<svg className="text-sticker-icon icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
						{t("staking.pool_4_desc_1")}
				</p>
				<img className="badge-item-stat-image" width="90px" src="" alt="" />
				<p className="badge-item-stat-title">{t("staking.pool_4_desc_2")}</p>
				<p className="badge-item-stat-text">{t("staking.pool_4_desc_3")}</p>
        <ul className="badge-item-stat-text small list">
          <li>{t("staking.pool_4_desc_4")}</li>
          <li>{t("staking.pool_4_desc_5")}</li>
          <li>{t("staking.pool_4_desc_6")}</li>
          <li>{t("staking.pool_4_desc_7")}</li>
          <li>{t("staking.pool_4_desc_8")}</li>
          <li>{t("staking.pool_4_desc_9")}</li>
        </ul>
				<div className="badge-item-stat-text table table-quests split-rows">
					<div className="table-body same-color-rows">
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.earn")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_4_desc_10")}
								</p>
							</div>
						</div>
            <div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.tax_refund")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("general.no")}
								</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.period")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.period_days", {duration: 7})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.min")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.amount_rice", {rice: 1000})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.max")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.unlimited")}</p>
							</div>
						</div>
          </div>
				</div>
        {isPoolOpen && 
				<p className="badge-item-stat-text">

					{/* APPROVE BUTTON */}
					{ !isLoading1 && ! isApproved1 && <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveRice();}}>{t("general.approve")}</button> }

					{/* STAKE BUTTON */}
					{
						!isLoading1 && isApproved1 &&
						<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doRiceStake();}}>{t("staking.button_stake")}</button>
					}

					{/* LOADING BUTTON */}
					{ isLoading1 && <button className="button loading">{t("general.loading")}</button>}
          
				</p>
        }
        {
          user && !isPoolOpen &&
          <p className="badge-item-stat-text">
              {t("staking.pool_is_closed")}
          </p>
        }
			</div>

      <div className="badge-item-stat">
				<p className="text-sticker">
						<svg className="text-sticker-icon icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
						{t("staking.pool_5_name")}
				</p>
				<img className="badge-item-stat-image" width="90px" src="" alt="" />
				<p className="badge-item-stat-title">{t("staking.pool_5_title")}</p>
				<p className="badge-item-stat-text">{t("staking.pool_5_desc")}</p>
        <ul className="badge-item-stat-text small list">
          <li>{t("staking.pool_5_desc_line_1")}</li>
          <li>{t("staking.pool_5_desc_line_2")}</li>
          <li>{t("staking.pool_5_desc_line_3")}</li>
          <li>{t("staking.pool_5_desc_line_4")}</li>
          <li>{t("staking.pool_5_desc_line_5")}</li>
          <li>{t("staking.pool_5_desc_line_6")}</li>
        </ul>
				<div className="badge-item-stat-text table table-quests split-rows">
					<div className="table-body same-color-rows">
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.earn")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_5_desc_line_7")}
								</p>
							</div>
						</div>
            <div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.tax_refund")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("general.no")}
								</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.period")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.period_days", {duration: 7})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.min")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.amount_rice", {rice: 1000})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.max")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.unlimited")}</p>
							</div>
						</div>
          </div>
				</div>
        {isPoolOpen && 
				<p className="badge-item-stat-text">

					{/* APPROVE BUTTON */}
					{ !isLoading3 && ! isApproved3 && <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveRicePool6();}}>{t("general.approve")}</button> }

					{/* STAKE BUTTON */}
					{
						!isLoading3 && isApproved3 &&
						<button className="button" onClick={(evt) => {evt.stopPropagation(); evt.preventDefault(); doRiceStakePool6();}}>{t("staking.button_stake")}</button>
					}

					{/* LOADING BUTTON */}
					{ isLoading3 && <button className="button loading">{t("general.loading")}</button>}


				</p>
        }
        {
          user && !isPoolOpen &&
          <p className="badge-item-stat-text">
              {t("staking.pool_is_closed")}
          </p>
        }
			</div>

      <div className="badge-item-stat">
				<p className="text-sticker">
						<svg className="text-sticker-icon icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
						{t("staking.pool_6_name")}
				</p>
				<img className="badge-item-stat-image" width="90px" src="" alt="" />
				<p className="badge-item-stat-title">{t("staking.pool_6_title")}</p>
				<p className="badge-item-stat-text">{t("staking.pool_6_desc")}</p>
        <ul className="badge-item-stat-text small list">
          <li>{t("staking.pool_6_desc_line_1")}</li>
          <li>
            
            <Trans i18nKey="staking.pool_6_desc_line_2">
              {""}
              <a href='https://pancakeswap.finance/add/0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9/BNB' target='_blank'>RICE-BNB LP</a>
            </Trans></li>
          <li>{t("staking.pool_6_desc_line_3")}</li>
          <li>{t("staking.pool_6_desc_line_4")}</li>
          <li>{t("staking.pool_6_desc_line_5")}</li>
          <li>{t("staking.pool_6_desc_line_6")}</li>
        </ul>
				<div className="badge-item-stat-text table table-quests split-rows">
					<div className="table-body same-color-rows">
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.earn")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_6_desc_line_7")}
								</p>
							</div>
						</div>
            <div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.tax_refund")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                  {t("staking.pool_6_desc_line_8")}
								</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.period")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.period_days", {duration: 30})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.min")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.amount_rice_bnb_lp", {amount: 20})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.max")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.unlimited")}</p>
							</div>
						</div>
          </div>
				</div>
        {isPoolOpen &&
				<p className="badge-item-stat-text">

					{/* APPROVE BUTTON */}
					{ !isLoading2 && ! isApproved2 && <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveLP();}}>{t("general.approve")}</button> }

					{/* STAKE BUTTON */}
					{
						!isLoading2 && isApproved2 &&
						<button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); doLPStake();}}>{t("staking.button_stake")}</button>
					}

					{/* LOADING BUTTON */}
					{ isLoading2 && <button className="button loading">{t("general.loading")}</button>}


				</p>
        }
        {
          user && !isPoolOpen &&
          <p className="badge-item-stat-text">
              {t("staking.pool_is_closed")}
          </p>
        }
			</div>

      <div className="badge-item-stat">
				<p className="text-sticker">
						<svg className="text-sticker-icon icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
						{t("staking.pool_7_name")}
				</p>
				<img className="badge-item-stat-image" width="90px" src="" alt="" />
				<p className="badge-item-stat-title">{t("staking.pool_7_title")}</p>
				<p className="badge-item-stat-text">{t("staking.pool_7_desc")}</p>
        <ul className="badge-item-stat-text small list">
          <li>{t("staking.pool_7_desc_line_1")}</li>
          <li>
            <Trans i18nKey="staking.pool_7_desc_line_2">
              {""}
              <a href="https://pancakeswap.finance/add/0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9/BNB" target="_blank">RICE-BNB LP</a>
            </Trans>
          </li>
          <li>{t("staking.pool_7_desc_line_3")}</li>
          <li>{t("staking.pool_7_desc_line_4")}</li>
          <li>{t("staking.pool_7_desc_line_5")}</li>
          <li>{t("staking.pool_7_desc_line_6")}</li>
        </ul>
				<div className="badge-item-stat-text table table-quests split-rows">
					<div className="table-body same-color-rows">
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.earn")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_7_desc_line_7")}
								</p>
							</div>
						</div>
            <div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.tax_refund")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">
                {t("staking.pool_7_desc_line_8")}
								</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.period")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.period_days", {duration: 30})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.min")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.amount_rice_bnb_lp", {amount: 20})}</p>
							</div>
						</div>
						<div className="table-row small">
							<div className="table-column">
								<div className="table-information">
									<p className="table-title">{t("staking.max")}</p>
								</div>
							</div>
							<div className="table-column">
								<p className="table-text align-right">{t("staking.unlimited")}</p>
							</div>
						</div>
          </div>
				</div>
        {isPoolOpen &&
				<p className="badge-item-stat-text">

					{/* APPROVE BUTTON */}
					{ !isLoading4 && ! isApproved4 && <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); approveLPPool7();}}>{t("general.approve")}</button> }

					{/* STAKE BUTTON */}
					{
						!isLoading4 && isApproved4 &&
						<button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); doLPStakePool7();}}>{t("staking.button_stake")}</button>
					}

					{/* LOADING BUTTON */}
					{ isLoading4 && <button className="button loading">{t("general.loading")}</button>}


				</p>
        }
        {
          user && !isPoolOpen &&
          <p className="badge-item-stat-text">
              {t("staking.pool_is_closed")}
          </p>
        }
			</div>
    </>
  );
}
export default Stakes45;
