import i18n from "i18next";

import Backend from "i18next-xhr-backend";

import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

i18n

  .use(Backend)

  .use(initReactI18next)

  .use(LanguageDetector) // Registering the detection plugin

  .init({

    fallbackLng: "en", //when specified language translations not present 

//then fallbacklang translations loaded.

    debug: process.env.NODE_ENV === "development",
    backend: {

      /* translation file path */

      loadPath: process.env.REACT_APP_HOME_URL + "assets/i18n/{{ns}}/{{lng}}.json",

    },
 

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */


    ns: ["languages"],

    defaultNS: "languages",

    keySeparator: false,

    interpolation: {

      escapeValue: false,

      formatSeparator: ",",

    },

    react: {

      wait: true,

    },

  });


export default i18n;