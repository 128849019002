import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function getTax(timestamp){
  console.log("Last claimed: " + timestamp);
  var period = Date.now() / 1000 - timestamp;
  console.log("Minutes ago: " + (period / 60));
  var fee = -1;
  if(period <= 1 * 86400){
    fee = 50;
  } else if(period <= 2 * 86400){
    fee = 40;
  } else if(period <= 3 * 86400){
    fee = 30;
  } else if(period <= 4 * 86400){
    fee = 20;
  } else if(period <= 5 * 86400){
    fee = 10;
  } else if(period <= 6 * 86400){
    fee = -1;
  } else {
    fee = 3;
  }

  return fee;
}

function RewardClaim(props){
  const rbContext = useContext(RBContext);

  let [loading, setLoading] = useState(true);
  const { Moralis, user } = useMoralis();
  let [lastClaimed, setLastClaimed] = useState(false);
  let [tax, setTax] = useState(0);
  let [myReward, setMyReward] = useState(false);
  let [claimable, setClaimable] = useState(false);
  let [availableItemsReward, setItemReward] = useState(0);
  const { t, i18n } = useTranslation();

  const getRewardInfo = async () => {
    try{
      var userAddress = user.get('ethAddress');
      setLoading(true);
    
      var myReward = await Moralis.Cloud.run('getMyClaimInfo', {sender: userAddress,lang: i18n.language});
      
      setMyReward(myReward);

      var claimable = await RBRewardContract.methods.UserRewards(userAddress).call();
      
      setClaimable(claimable);
      
      var lastClaimed = await RBRewardRiceContract.methods.LastClaimed(userAddress).call();
      if(lastClaimed == 0){
        lastClaimed = await RBRewardContract.methods.LastClaimed(userAddress).call();
      }

      setLastClaimed(lastClaimed);
      tax = getTax(lastClaimed);
      
      var availableItems = await RBItemRewardContract.methods.getAvailableItems(userAddress).call();
      
      setItemReward(availableItems);

      setTax(tax);

      setLoading(false);
    } catch(err){
      console.log(err.message);
    }
  }

  const claimItemReward = async () => {
    if(!loading && availableItemsReward){
      var userAddress = user.get('ethAddress');
      setLoading(true);

      try{
        var result = await RBItemRewardContract.methods.claimReward(1000000 + Math.floor(Math.random() * 100000000)).send({from: userAddress});
        if(result.status){
          alert(t("profile.reward_claimed"));
          setItemReward(0);
        } else {
          alert(t("profile.reward_unable_to_claim"));
          console.log(result);
        }

        setLoading(false);
      } catch(err){
        alert(err.message);
        setLoading(false);
      }
    }
  }

  const claimReward = async () => {
    var userAddress = user.get('ethAddress');
    setLoading(true);

    try{
      var nonce = 100000000 + Math.floor(Math.random() * 100000000);
      
      if(lastClaimed == 0){
        // first claimed
        var result = await RBRewardContract.methods.claimReward(nonce).send({from: userAddress});
      } else {
        var result = await RBRewardRiceContract.methods.claimReward().send({from: userAddress});
      }
      
      if(result.status){
        alert(t("profile.reward_claimed"));
        if(lastClaimed == 0){
          var newLastClaimed = await RBRewardContract.methods.LastClaimed(userAddress).call();
        } else {
          var newLastClaimed = await RBRewardRiceContract.methods.LastClaimed(userAddress).call();
        }

        var log = await Moralis.Cloud.run('logClaim', {lang: i18n.language,claimTimestamp: newLastClaimed, totalRice: lastClaimed ? claimable.availableRice : 200, totalItems: claimable.availableItem});

        var newClaimable = await RBRewardContract.methods.UserRewards(userAddress).call();
        
        setClaimable(newClaimable);
        
        setLastClaimed(newLastClaimed);
        tax = getTax(newLastClaimed);

        setTax(tax);

        console.log(newClaimable);
        
      } else {
        alert(t("profile.reward_unable_to_claim"));
        console.log(result);
      }

      setLoading(false);
    } catch(err){
      console.log(err);
      alert(err.message);
      setLoading(false);
    }
  }

  const registerPlayer = async () => {
    var userAddress = user.get('ethAddress');
    if(!loading){
      setLoading(true);

      try{
        var result = await RBRewardContract.methods.registerPlayer().send({from: userAddress});
        if(result.status){
          alert(t("profile.register_player_ok"));

          var claimable = await RBRewardContract.methods.UserRewards(userAddress).call();
          setClaimable(claimable);
        } else {
          alert(t("profile.register_player_failed"));
          console.log(result);
        }

        setLoading(false);
      } catch(err){
        alert(err.message);
        setLoading(false);
      }
    }
  }

  useEffect( () => {
      if(user){
        async function fetchData(){
          setTimeout(getRewardInfo, 200);
        }
        fetchData();
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { web3, RBItemRewardContract, RBRewardContract, RBRewardRiceContract } = rbContext;

  return(
    <>
      {claimable && parseInt(claimable.availableRice) == 0 &&
      <button className="button" onClick={registerPlayer}>{loading ? "registering..." : "Register for Reward (one time only)"}</button>
      }
      
      <div id="UserRewardProfile">
        {
        claimable && parseInt(claimable.availableRice) >= 1 &&
          <><div className="centered marginBottom">
            <img src={process.env.REACT_APP_HOME_URL + "assets/goi_thoc_05.png"}/>
            <h3>{t("profile.total_claimable")} <span className="number">{claimable ? (parseInt(claimable.availableRice) - 1) : 0} RICE</span></h3>  
            <hr className='separator'/>
            <h4 class="heading">{t("profile.verified_rice")} <span className="number">{myReward ? myReward.verifiedRice : 0} RICE</span></h4>  
            <p className="battleclaimed-info">{t("profile.view_battles_history")} <a href={process.env.REACT_APP_HOME_URL + "profile/battles"}>{t("profile.history")}</a></p>
          </div>
          <div className="grid medium-space">
            <div className="form-row split">
              <div className="form-item">
                {!loading && claimable && ((lastClaimed == 0 && parseInt(claimable.availableRice) > 200) || lastClaimed > 0) &&
                <button className="button" onClick={claimReward}>{t("profile.claim_rices")}</button>}
                {!loading && tax > -1 && 
                <p className="battleclaimed-info centered">{lastClaimed > 0 ? t("profile.claim_last_claimed_time", {time: new Date(lastClaimed * 1000).toLocaleDateString("en-US") + " " + new Date(lastClaimed * 1000).toLocaleTimeString("en-US"), tax: tax, rice: (claimable ? Math.floor(parseInt(claimable.availableRice) * (100 - tax) / 100) : 0)}) : t("profile.first_claimed")}. {t("profile.claim_note")} <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">{t("profile.reward_mechanism")}</a></p>
                }
                {!loading && tax == -1 && 
                <p className="battleclaimed-info centered">{lastClaimed > 0 ? t("profile.claim_cooldown_day", {time: new Date(lastClaimed * 1000).toLocaleDateString("en-US")}) : t("profile.first_claimed")}. {t("profile.claim_note")} <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">{t("profile.reward_mechanism")}</a></p>
                }
              </div>
            </div>
          </div>
          </>
        }  
        {
          loading &&

          <div className="centered paragraph white no-battles">{t("profile.checking_rewards")}</div>
        }
      </div>
            
    </>
  )
}

export default RewardClaim;