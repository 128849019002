import React, { useState, useEffect, useContext } from 'react';
import Extra from './../Extra';
import RBContext from './../RBContext';
import { useMoralis } from "react-moralis";

function NFTCollector(props) {
  const rbContext = useContext(RBContext);
  const { user, Moralis } = useMoralis();
  let [roosterId, setRoosterId] = useState(0);
  let [loading, setLoading] = useState(false);

  const sendNFT = async () => {
    if(roosterId && !loading){
      var params = {ids: roosterId};
      
      try{
        setLoading(true);

        var userAddress = user.get('ethAddress');

        var approval =  await nftContract.methods.isApprovedForAll(userAddress, process.env.REACT_APP_RB_COLLECTOR_ADDRESS).call();
        if(!approval){
          alert("It is required to ask for your approval to send the NFT to the Collector contract");

          var result = await nftContract.methods.setApprovalForAll(process.env.REACT_APP_RB_COLLECTOR_ADDRESS, true).send({from: userAddress});
          if(result.status){
            approval = true;
          }
        }

        if(approval){
          var valid = await RBCOLLECTOR_CONTRACT.methods.NFTTransfers(roosterId).call();

          if(valid){
            var result = await RBCOLLECTOR_CONTRACT.methods.sendToCollector(roosterId).send({from: userAddress});
            if(result.status){
              alert("Done. Please contact the Support Team if you need further instructions");
            }
          } else {
            alert("This NFT is not approved for transferred yet. Please contact the Support Team for support");
          }
        }
        
        setLoading(false);
      } catch(err){
        setLoading(false);
        alert(err.message);
      }      
    }
  }

  useEffect(()=>{
    Extra.updateContentGridPos();
    
  }, [user, rbContext]);

  if (!rbContext) {
    return null;
}

let { nftContract, RBCOLLECTOR_CONTRACT } = rbContext;

  return (
      <div className="content-grid" id="nftcollector">
        <div className="section-banner">
          <p className="section-banner-title">NFT Collector Tool</p>
          <p className="section-banner-text"></p>
        </div>
        <div className="sub-header">
          <h3>Enter your Rooster/Skill ID to send to the Collector</h3>
          <p>Please contact support team in advance before sending your Rooster/Skill to the collector</p>
          
        </div>
        <input id="roosterID" type="text" onChange={event => setRoosterId(event.target.value)}/>
        
        <div className="form-row split">
          <div className="form-item">
            <button className="button btn-block" onClick={sendNFT}>{loading ? "Loading..." : "Send"}</button>
          </div>
        </div>
      </div>
    )
}
export default NFTCollector;