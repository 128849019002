const Extra = {
    updateContentGridPos() {
        window.dispatchEvent(new Event('resize'));
        if(window.app){
          window.app.plugins.createSlider({
            container: '#section-navigation-slider',
            fixedWidth: 122,
            nav: false,
            loop: false,
            controlsContainer: '#section-navigation-slider-controls'
          });
        }
        
    },

    mobileWidgetToggle() {
      const button = document.querySelector('.navigation-widget-close-button');
      button.dispatchEvent(new Event('click'));
    }
};

export default Extra;
