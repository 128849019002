import { useState } from 'react';

function UserPendingSkill(props) {

    let { skill, redeemCallback } = props;
    let [ isLoading, setIsLoading ] = useState(false);
    
    return (
        <div className="badge-item-stat">
            {
                skill.pool &&
                <>
                    <p className="text-sticker">
                        <svg className="text-sticker-icon icon-plus-small">
                            <use xlinkHref="#svg-plus-small"></use>
                        </svg>
                        {parseFloat(parseFloat(skill.pool.apr) / 365 / 24).toFixed(2).toLocaleString()}%/hour
                    </p>
                    <img className="badge-item-stat-image" width="90px" src={skill.pool.label.icon} alt="" />
                    <p className="badge-item-stat-title">{skill.pool.label.title}</p>
                </>
            }

            <p className="badge-item-stat-text">
                { ! isLoading &&
                    <button className="button btn-block" onClick={(e) => {
                        e.preventDefault();
                       
                        setIsLoading(true);

                        redeemCallback(skill)
                            .then(() => {
                                alert('Redeem completed');
                            })
                            .catch((err) => {
                                alert("Failed to redeem");
                                console.log(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            })
                    }}>Redeem {skill.total} skills</button>
                }
                { false && isLoading && <button className="button loading">Processing...</button>}
            </p>
        </div>
    );
}

export default UserPendingSkill;
