import React, { useState, useEffect, useContext } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route, useParams, useMatch } from "react-router-dom";
import ScholarsMain from './ScholarsMain';

function Scholars(props){
    
    return (
        <Routes>
          <Route path="*" element={<ScholarsMain/>}/>
        </Routes>
    );
};

export default Scholars;