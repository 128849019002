import React, { useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
  import Extra from './../../Extra';
  import RBContext from './../../RBContext';
  import { getArmoredLevel, getRoosterName, getSkillName, getGameItemName, getRoosterDescription, addEggInfo, getGameItemEffect } from './../../utils/RBUtils.js';
  import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  import { Radar } from 'react-chartjs-2';
  import { useTranslation } from "react-i18next";

function NFTItemDetail(props){
  const { t, i18n } = useTranslation();
  const rbContext = useContext(RBContext);

  let { tokenType, tokenId } = useParams();
  let [realNFTId, setRealNFTId] = useState(0);

  let [loading, setLoading] = useState(true);

  let [sold, setSold] = useState(false);
  let [isOwner, setIsOwner] = useState(false);
  let { Moralis, isAuthenticated, user } = useMoralis();

  let [nftItem, setNFTItem] = useState({});

  const init = async () => {
    if(user){

    
      var currentUserAddress = user.get('ethAddress');
      var needSyncOffer = false;
      var params = {id: tokenId,type: tokenType,lang: i18n.language};
      
      var marketOffer = await Moralis.Cloud.run('getMarketOfferDetail', params);

      if(!marketOffer || !marketOffer.Status){
        alert(t("error.offer_does_not_exist"));
        return;
      }

      if(tokenType == 1 || tokenType == 3){
        setRealNFTId(marketOffer.TokenId);
        
        var result = await marketContract.methods.roosterOffers(marketOffer.TokenId).call();
        
        if(result.seller != "0x0000000000000000000000000000000000000000"){
          if(result.seller.toLowerCase() == currentUserAddress){
            setSold(true);
            setIsOwner(true);
          }

          // check if owner of the Ticket is different from seller
          var currentOwner = await nftContract.methods.ownerOf(marketOffer.TokenId).call();
          if(currentOwner.toLowerCase() != result.seller.toLowerCase()){
            setSold(true);
            needSyncOffer = true;
            alert(t("error.nft_transferred"));
          } else {
            if(!marketOffer || marketOffer.Seller.toLowerCase() != result.seller.toLowerCase() || marketOffer.Price != parseInt(result.ricePrice.substr(0, result.ricePrice.length - 18))){
              // sync offer again
              var update = await Moralis.Cloud.run('makeRoosterOffer', {lang: i18n.language, tokenId: marketOffer.TokenId, type: tokenType == 1 ? 'ROOSTER' : 'SKILL'});
              console.log(update);
            }

            var data = await Moralis.Cloud.run('getRBDetail', {lang: i18n.language, id: marketOffer.TokenId});
            
            var nft = {};
            nft.armors = data.armors;
            nft.attack = data.attack;
            nft.defense = data.defense;
            nft.seller = result.seller;
            nft.price = result.ricePrice.substr(0, result.ricePrice.length - 18);
            nft.itemType = data.type;
            nft.stamina = Math.floor(data.stamina*100);
            if(nft.itemType < 65){
              nft.type = "ROOSTER";
            } else {
              nft.type = "SKILL";
            }
            
            nft.status = data.status;
            
            if(tokenType == 1){
              nft.name = getRoosterName(nft.itemType, nft.status == 'egg' ? 'EGG' : nft.type);

              var dojoStatus = await dojoContract.methods.getRoosterStartTraining(marketOffer.TokenId).call();
              if(dojoStatus > 0){
                alert(t("error.rooster_in_dojo"));

                setSold(true); // sold or not in market
                needSyncOffer = true;
              }
            } else {
              nft.name = getSkillName(nft.itemType);
              nft.rerollCount = await RBBLACKSMITH_CONTRACT.methods.RerollCount(marketOffer.TokenId).call();
            }
            setSold(false);

            console.log(nft);
            setNFTItem(nft);
          }
          setLoading(false);
        } else {
          setSold(true); // sold or not in market
          needSyncOffer = true;
        }
      } else if(tokenType == 2){
        var result = await RBMARKETGAMEITEM_CONTRACT.methods.gameItemOffers(tokenId).call();

        if(result.seller != "0x0000000000000000000000000000000000000000"){
          if(result.seller.toLowerCase() == currentUserAddress){
            setSold(true);
            setIsOwner(true);
          }

          // check if owner of the game item has enough items to sell
          var currentSellerBalance = await gameitemContract.methods.balanceOf(result.seller, result.gameItemType).call();
          
          if(parseInt(currentSellerBalance) < result.nftAmount){
            setSold(true);
            needSyncOffer = true;
            alert(t("error.seller_does_not_have_enough_items"));
          } else {
            var nft = {};
            nft.seller = result.seller;
            nft.price = result.ricePrice.substr(0, result.ricePrice.length - 18);
            nft.amount = result.nftAmount;
            nft.gameItemType = result.gameItemType;
            nft.name = getGameItemName(nft.gameItemType);
            nft.type = "GAMEITEM";
            setNFTItem(nft);
            setSold(false);
          }
          setLoading(false);
        } else {
          setSold(true); // sold or not in market
          needSyncOffer = true;
        }
        
      } else if(tokenType == 4){
        var result = await RBMARKETEGG_CONTRACT.methods.Offers(tokenId).call();

        if(result.seller != "0x0000000000000000000000000000000000000000"){
          
          if(result.seller.toLowerCase() == currentUserAddress){
            setSold(true);
            setIsOwner(true);
            needSyncOffer = true;
          }

          // check if owner of the Ticket is different from seller
          var currentOwner = '';

          try{
            currentOwner = await RBEGG_CONTRACT.methods.ownerOf(tokenId).call();
          } catch(err){
            if(err.message.indexOf('owner query for nonexistent token') !== false){
              alert(t("error.egg_has_been_hatched"));
            }

            setSold(true);
            needSyncOffer = true;
          }
          if(currentOwner != ''){
            if(currentOwner.toLowerCase() != result.seller.toLowerCase()){
              setSold(true);
              needSyncOffer = true;
              alert(t("error.ticket_has_been_transferred"));
            } else {
              if(!marketOffer || marketOffer.Seller.toLowerCase() != result.seller.toLowerCase() || marketOffer.Price != parseInt(result.ricePrice.substr(0, result.ricePrice.length - 18))){
                // sync offer again
                var update = await Moralis.Cloud.run('makeTicketOffer', {tokenId: tokenId, lang: i18n.language});
                console.log(update);
              }
  
              var nft = {};
              nft.id = tokenId;
              nft.seller = result.seller;
              nft.price = result.ricePrice.substr(0, result.ricePrice.length - 18);
              nft.type = "TICKET";
  
              var eggInfo = await RBEGG_CONTRACT.methods.Eggs(tokenId).call();
              eggInfo = addEggInfo(eggInfo);
              nft.name = "NFT EGG - " + eggInfo.eggName;
              nft.eggInfo = eggInfo;
  
              nft.amount = 1;
  
              setNFTItem(nft);
              setSold(false);
            }
          }
          
          setLoading(false);
        } else {
          setSold(true); // sold or not in market
          needSyncOffer = true;
        }
      } else if(tokenType == 5){
        var result = await RBMARKETHEN_CONTRACT.methods.henOffers(tokenId).call();
        
        if(result.seller != "0x0000000000000000000000000000000000000000"){
          if(result.seller.toLowerCase() == currentUserAddress){
            setSold(true);
            setIsOwner(true);
          }

          // check if owner is different from seller
          var currentOwner = await RBHenContract.methods.ownerOf(tokenId).call();
          if(currentOwner.toLowerCase() != result.seller.toLowerCase()){
            setSold(true);
            needSyncOffer = true;
            alert(t("error.nft_transferred"));
          } else {
            if(!marketOffer || marketOffer.Seller.toLowerCase() != result.seller.toLowerCase() || marketOffer.Price != parseInt(result.ricePrice.substr(0, result.ricePrice.length - 18))){
              // sync offer again
              var update = await Moralis.Cloud.run('makeHenOffer', {tokenId: tokenId,lang: i18n.language});
              console.log(update);
            }
            var nft = {};
            nft.seller = result.seller;
            nft.price = result.ricePrice.substr(0, result.ricePrice.length - 18);
            nft.itemType = await RBHenContract.methods.Hens(tokenId).call();

            const BreedingContract = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_RB_BREEDINGF1_ABI), process.env.REACT_APP_RB_BREEDINGF1_ADDRESS);

            nft.breedings = await BreedingContract.methods.HenUsed(tokenId).call();


            nft.type = 'HEN';
            nft.name = 'The Hen';
            
            setNFTItem(nft);
            setSold(false);
          }
          setLoading(false);
        } else {
          setSold(true); // sold or not in market
          needSyncOffer = true;
        }
      }
      
      if(needSyncOffer){
        // sync with backend
        let params = {};
        switch(tokenType){
          case "1":
            params = { tokenId: tokenId, type: 'ROOSTER',lang: i18n.language};
            break;
          case "2":
            params = { tokenId: tokenId, type: 'GAMEITEM',lang: i18n.language};
            break;
          case "3":
            params = { tokenId: tokenId, type: 'SKILL',lang: i18n.language};
            break;
          case "4":
            params = { tokenId: tokenId, type: 'TICKET',lang: i18n.language};
            break;
          case "5":
            params = { tokenId: tokenId, type: 'HEN',lang: i18n.language};
            break;
        }
        
        try{
          var result = await Moralis.Cloud.run('syncMarketOffer', params);
          console.log(result);
        } catch(err){
          console.log(err);
        }
      }
    }
  }

  useEffect(() => {
    async function getData(){
      init();
    }
    getData();
  },[rbContext]);

  useEffect(() => {
    Extra.updateContentGridPos();
  })

  if (!rbContext) {
      return null;
  }

  let { web3, riceContract, RBMARKETEGG_CONTRACT, dojoContract, 
    marketContract, gameitemContract, nftContract, 
    RBEGG_CONTRACT, RBHenContract, RBMARKETGAMEITEM_CONTRACT, 
    RBMARKETHEN_CONTRACT, RBBLACKSMITH_CONTRACT } = rbContext;

  const purchase = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
      if(isAuthenticated){
        try {
          setLoading(true);

          var currentUserAddress = user.get('ethAddress');

          if(nftItem.seller.toLowerCase() != currentUserAddress){
            if(nftItem.type == "ROOSTER" || nftItem.type == "SKILL"){
              await purchaseRooster(currentUserAddress);
            } else if(nftItem.type == "TICKET"){
              await purchaseTicket(currentUserAddress);
            } else if(nftItem.type == "HEN"){
              await purchaseHen(currentUserAddress);
            } else if(nftItem.type == "GAMEITEM"){
              await purchaseGameItem(currentUserAddress);
            }

            // sync with backend
            let params = { tokenId: tokenId, type: nftItem.type, buyer: currentUserAddress,lang: i18n.language};
            
            try{
              
              var result = await Moralis.Cloud.run('syncMarketOffer', params);
              console.log(result);
            } catch(err){
              console.log(err);
            }
          } else {
            alert(t("error.cannot_buy_own_offer"));
            setLoading(false);
          }
        } catch( err ){
          setLoading(false);
          alert(err.message);
          console.log(err);
        }
      } else {
        alert(t("error.wallet_not_connected"));
      }
  }

  const purchaseGameItem = async (currentUserAddress) => {
    const allowance = await riceContract.methods.allowance(currentUserAddress, process.env.REACT_APP_MARKETGAMEITEM_CONTRACT_ADDRESS).call();

    const balanceOfUser = await riceContract.methods.balanceOf(currentUserAddress).call();

    if((balanceOfUser > 0 && parseInt(balanceOfUser.substr(0, balanceOfUser.length - 18))) >= (nftItem.price * 1.05)){
      let okToGo = true;
      if((allowance == 0 || parseInt(allowance.substr(0, allowance.length - 18))) < (nftItem.price * 1.05)){
        okToGo = false;
        // need to confirm how many tokens should we ask for approval
        // TO BE IMPROVED...
        alert(t("error.rice_approval"));
        const offerPrice = web3.utils.toWei((parseInt(nftItem.price) * 1.1 * 10 ) + "");
        var result = await riceContract.methods.approve(process.env.REACT_APP_MARKETGAMEITEM_CONTRACT_ADDRESS, offerPrice).send({from: currentUserAddress});

        if(result.status){
          okToGo = true;
        }
      }

      if(okToGo){
        try{

          // check for gameitem balance
          var gameitembalance = await gameitemContract.methods.balanceOf(nftItem.seller, nftItem.gameItemType).call();

          if(parseInt(gameitembalance) >= nftItem.amount){            
            var result = await RBMARKETGAMEITEM_CONTRACT.methods.purchaseGameItem(tokenId).send({from: currentUserAddress});
            if(result.status){
              alert(t("error.offer_bought"));
              setSold(true);
            } else {
              alert(t("error.failed_to_buy"));
              console.log(result);
            }
          } else {
            alert(t("error.invalid_gameitem_amount")) ;
            // Update to Moralis this valid offer
            setSold(true);
          }

        } catch(err){
          alert(err.message);
          console.log(err);
        }
      }
      setLoading(false);
    } else {
      alert(t("error.not_enough_rice"));
      setLoading(false);
    }
  }

  const purchaseRooster = async (currentUserAddress) => {
    const allowance = await riceContract.methods.allowance(currentUserAddress, process.env.REACT_APP_MARKETCONTRACT_ADDRESS).call();

    const balanceOfUser = await riceContract.methods.balanceOf(currentUserAddress).call();

    if(balanceOfUser > 0 && parseInt(balanceOfUser.substr(0, balanceOfUser.length - 18)) >= (nftItem.price * 1.05)){
      let okToGo = true;
      if(allowance == 0 || parseInt(allowance.substr(0, allowance.length - 18)) < (nftItem.price * 1.05)){
        okToGo = false;
        // need to confirm how many tokens should we ask for approval
        // TO BE IMPROVED...
        alert(t("error.rice_approval"));
        const offerPrice = web3.utils.toWei((nftItem.price * 1.1 * 10 ) + "");
        var result = await riceContract.methods.approve(process.env.REACT_APP_MARKETCONTRACT_ADDRESS, offerPrice).send({from: currentUserAddress});

        if(result.status){
          okToGo = true;
        }
      }

      if(okToGo){
        try{
          var result = await marketContract.methods.purchaseRooster(realNFTId).send({from: currentUserAddress});
          if(result.status){
            alert(t("error.offer_bought"));
            setSold(true);
          } else {
            alert(t("error.failed_to_buy"));
            console.log(result);
          }
        } catch(err){
          alert(err.message);
          console.log(err);
        }
      }
      setLoading(false);
    } else {
      alert(t("error.not_enough_rice"));
      setLoading(false);
    }
  }

  const purchaseHen = async (currentUserAddress) => {
    const allowance = await riceContract.methods.allowance(currentUserAddress, process.env.REACT_APP_RBMARKETPLACE_HEN_ADDRESS).call();
    console.log("Allowance", allowance);
    const balanceOfUser = await riceContract.methods.balanceOf(currentUserAddress).call();
    console.log("balanceOfUser", balanceOfUser);
    if(balanceOfUser > 0 && parseInt(balanceOfUser.substr(0, balanceOfUser.length - 18)) >= (nftItem.price * 1.05)){
      let okToGo = true;
      if(allowance == 0 || parseInt(allowance.substr(0, allowance.length - 18)) < (nftItem.price * 1.05)){
        okToGo = false;
        // need to confirm how many tokens should we ask for approval
        // TO BE IMPROVED...
        alert(t("error.rice_approval"));
        const offerPrice = web3.utils.toWei((nftItem.price * 1.1 * 10 ) + "");
        
        var result = await riceContract.methods.approve(process.env.REACT_APP_RBMARKETPLACE_HEN_ADDRESS, offerPrice).send({from: currentUserAddress});

        if(result.status){
          okToGo = true;
        }
      }

      if(okToGo){
        try{
          var result = await RBMARKETHEN_CONTRACT.methods.purchase(tokenId).send({from: currentUserAddress});
          if(result.status){
            alert(t("error.offer_bought"));
            setSold(true);
          } else {
            alert(t("error.failed_to_buy"));
            console.log(result);
          }
        } catch(err){
          alert(err.message);
          console.log(err);
        }
      }
      setLoading(false);
    } else {
      alert(t("error.not_enough_rice"));
      setLoading(false);
    }
  }

  const purchaseTicket = async (currentUserAddress) => {
    const allowance = await riceContract.methods.allowance(currentUserAddress, process.env.REACT_APP_RB_MARKETEGG_ADDRESS).call();
    const balanceOfUser = await riceContract.methods.balanceOf(currentUserAddress).call();

    if(currentUserAddress != user.get('ethAddress')){
      alert(t("error.wallet_not_connected"));
      setLoading(false);
      return;
    }
    
    if((balanceOfUser > 0) && parseInt(balanceOfUser.substr(0, balanceOfUser.length - 18)) >= (nftItem.price * 1.05)){
      let okToGo = true;
      if(allowance == 0 || parseInt(allowance.substr(0, allowance.length - 18)) < (nftItem.price * 1.05)){
        okToGo = false;
        // need to confirm how many tokens should we ask for approval
        // TO BE IMPROVED...
        const offerPrice = web3.utils.toWei((nftItem.price * 1.1 * 10 ) + ""); // increase 10% to pay fee

        alert(t("error.rice_approval"));
        
        try{
          var result = await riceContract.methods.approve(process.env.REACT_APP_RB_MARKETEGG_ADDRESS, offerPrice).send({from: currentUserAddress});
        } catch(err){
          alert(err.message);
          setLoading(false);
          console.log(err);
          return;
        }
        

        if(result.status){
          okToGo = true;
        }
      }

      if(okToGo){
        try{
          
            var result = await RBMARKETEGG_CONTRACT.methods.purchase(tokenId).send({from: currentUserAddress});
            if(result.status){
              alert(t("error.offer_bought"));
              setSold(true);
            } else {
              alert(t("error.failed_to_buy"));
              console.log(result);
            }
        } catch(err){
          alert(err.message);
          console.log(err);
        }
      }
      setLoading(false);
    } else {
      alert(t("error.not_enough_rice"));
      setLoading(false);
    }
  }

  const withdrawIt = async (tokenId, tokenType) => {
    if(window.confirm(t("error.confirm_withdraw"))){
      setLoading(true);
      try {
        var currentUserAddress = user.get('ethAddress');
        
        if(tokenType == 2){
          var myOffers = await RBMARKETGAMEITEM_CONTRACT.methods.getMyGameItemOffers(currentUserAddress).call();
          if(myOffers.includes(tokenId)){
            var result = await RBMARKETGAMEITEM_CONTRACT.methods.withdrawGameItemOffer(tokenId).send({from: currentUserAddress});
            console.log(result);
          }
          
        } else if(tokenType == 1 || tokenType == 3) {
          var offer = await marketContract.methods.roosterOffers(realNFTId).call();
          if(offer.ricePrice != 0){
            var result = await marketContract.methods.withdrawRoosterOffer(realNFTId).send({from: currentUserAddress});
            console.log(result);
          }
        } else if(tokenType == 4) {
          var offer = await RBMARKETEGG_CONTRACT.methods.Offers(tokenId).call();
          if(offer.ricePrice != 0){
            var result = await RBMARKETEGG_CONTRACT.methods.withdraw(tokenId).send({from: currentUserAddress});
            console.log(result);
          }
        } else if(tokenType == 5) {
          var offer = await RBMARKETHEN_CONTRACT.methods.henOffers(tokenId).call();
          if(offer.ricePrice != 0){
            var result = await RBMARKETHEN_CONTRACT.methods.withdrawOffer(tokenId).send({from: currentUserAddress});
            console.log(result);
          }
        }
      } catch(err) {
        alert(err.message);
        setLoading(false);
        return false;
      }

      const params = {tokenId: tokenId,lang: i18n.language};

      if(tokenType == 2){
        var result = await Moralis.Cloud.run('withdrawGameItemOffer', params);
      } else if(tokenType == 1 || tokenType == 3){
        var result = await Moralis.Cloud.run('withdrawRoosterOffer', params);
      } else if(tokenType == 4){
        var result = await Moralis.Cloud.run('withdrawTicketOffer', params);
      } else if(tokenType == 5){
        var result = await Moralis.Cloud.run('withdrawHenOffer', params);
      }
      console.log(result);
      if(result !== "INVALID"){
        alert(t("error.withdraw_ok"));
        setSold(true);
      } else {
        alert(t("error.withdraw_failed"));
      }
      
      setLoading(false);
    }

    return false;
  }

  const buyButton = () => {
    if(!user){
      return <>{t("error.please_connect_wallet")}</>
    }
    return (<>
      {
              !sold &&
              <><p className="price-title big"><span className="currency">RICE </span>{nftItem.price ? parseInt(nftItem.price).toLocaleString() : ''} + 5%</p>
              <p>{t("marketplace.market_fee_info")}</p>
              </>
            }

            { !sold && loading &&
              <p className="button full loading"><span className="inactive-text">{t("general.processing")}</span></p>
            }
            { !sold && !isOwner && !loading && 
              <p className="button primary" onClick={purchase}>{t("general.button_purchase")}</p>
            }
            {
              sold &&
              <p className="button full"><span className="inactive-text">{t("marketplace.sold_or_expired")}</span></p>
            }
            {
              !sold && isOwner && !loading &&
              <>
              <p className="button full" onClick={(e) => {e.stopPropagation();e.preventDefault();withdrawIt(tokenId, tokenType);}}><span className="inactive-text">{t("general.button_withdraw")}</span></p>
              </>
            }
    </>);
  }

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  const getRadarData = () => {

    var data = {
      labels: [t("rooster.slot_1"), t("rooster.slot_2"), t("rooster.slot_3"), t("rooster.slot_4"), t("rooster.slot_5"), t("rooster.slot_6")],
      datasets: [
        {
          label: t("rooster.attack"),
          data: [nftItem.attack[0], nftItem.attack[1], nftItem.attack[2], nftItem.attack[3], nftItem.attack[4], nftItem.attack[5]],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: t("rooster.defense"),
          data: [nftItem.defense[0], nftItem.defense[1], nftItem.defense[2], nftItem.defense[3], nftItem.defense[4], nftItem.defense[5]],
          backgroundColor: 'rgba(54, 163, 240, 0.2)',
          borderColor: 'rgba(147, 209, 252, 1)',
          borderWidth: 1,
        }
      ],

    }

    return data;
  };

  return (
    <>
    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle">{(nftItem.type == "ROOSTER" || nftItem.type == 'HEN') ? "NFT" : "Offer"}</p>
        <h2 className="section-title">#{nftItem.type == "GAMEITEM" ? (parseInt(tokenId) + 1) : tokenId} - {nftItem.name}</h2>
      </div>
      <div className="section-header-actions">
          { !sold &&
            <p className="section-header-subsection"><a href={process.env.REACT_APP_CHAIN_SCAN + "address/" + nftItem.seller} target="_blank">{t("marketplace.seller", {wallet: nftItem.seller})}</a></p>
          }
      </div>
    </div>
    <div className="grid grid-9-3">
      <div className="marketplace-content grid-column">
        <div className="tab-box">
          <div className="tab-box-options">
            <div className="tab-box-option">
              <p className="tab-box-item-title centered">{nftItem.type == "ROOSTER" ? "#" + tokenId + "-" + nftItem.name : nftItem.name}</p>
            </div>
          </div>
          <div className="tab-box-items">
            <div className="tab-box-item">
              <div className="tab-box-item-content">
                <div className="grid grid-half">
                  <div className='grid-column'>
                      {nftItem.type == "GAMEITEM" &&
                    <>
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/gameitems/gameitem_" + nftItem.gameItemType + ".png"}/>
                      </div>
                    </>
                    
                    }
                    {(nftItem.type == "ROOSTER" && nftItem.status != 'egg') && 
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/rooster_" + nftItem.itemType + (nftItem.armors && nftItem.armors.length ? ("_" + nftItem.armors[0]) : "") + ".png"} />
                      </div>
                    }
                    {(nftItem.type == "ROOSTER" && nftItem.status == 'egg') && 
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/goi_trung_01.png"} alt={tokenId}/>
                      </div>
                    }
                    {(nftItem.type == "HEN") && 
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/roosters/ga_mai_" + nftItem.itemType + ".png"} alt={tokenId}/>
                      </div>
                    }
                    {(nftItem.type == "SKILL") &&
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/skills/skill_" + (1 + (nftItem.itemType % 65)) + ".png"} width="186px" height="186px" alt={tokenId}/>
                      </div>
                    }
                    {nftItem.type == 'TICKET' &&
                    <>
                      <div className="centered block item-detail-thumb">
                        <img src={process.env.REACT_APP_HOME_URL + "assets/egg_" + (nftItem.eggInfo.eggThumb) + ".png"}/>
                      </div>
                      <p className="tab-box-item-paragraph">
                          {t("marketplace.egg_can_be_hatched_in_game")}
                          {nftItem.eggInfo.eggThumb == 'hen' && t("marketplace.egg_give_hen")}
                          {nftItem.eggInfo.eggThumb == 'rooster' && t("marketplace.egg_give_hen")}
                          {nftItem.eggInfo.eggThumb == 'food' && t("marketplace.egg_give_food")}
                      </p>
                      <p className="tab-box-item-paragraph">{nftItem.eggInfo ? t("marketplace.egg_hatch_time", {time: nftItem.eggInfo.hatchable}) : ''}</p>
                    </>                
                    }
                    {
                      nftItem.type == 'HEN' &&
                      <>
                        <div className="roosterAttributes">
                          <p className="tab-box-item-paragraph">
                              {t("marketplace.hen_description")}
                          </p>
                          <ul className="bullet-item-list">
                            <li className="bullet-item">{t("marketplace.breeding_used", {count: nftItem.breedings})}</li>
                          </ul>
                        </div>
                      </>
                    }
                    {
                      (nftItem.type == "ROOSTER" || nftItem.type == "SKILL") ?
                      <div className="roosterAttributes">
                        <p>{getRoosterDescription(nftItem.itemType, nftItem.type)}</p>
                        <h3>{t("general.attributes")}</h3>
                        <ul className="bullet-item-list">
                          {nftItem.type == 'ROOSTER' &&
                          <li className="bullet-item">{(nftItem.armors && nftItem.armors.length && parseInt(nftItem.armors[0]) >= 1 && parseInt(nftItem.armors[0]) <= 3) ? <strong>{t("general.armored_rooster")} - {getArmoredLevel(parseInt(nftItem.armors[0]))}</strong> : 
                          (nftItem.armors && nftItem.armors.length && parseInt(nftItem.armors[0]) == 4) ? t("general.legendary_rooster") : t("general.lightweight_rooster")}
                          </li>
                          }
                          <li className="bullet-item">{t("rooster.attack_detail", {slots: (nftItem.attack.length ? nftItem.attack.join(', ') : '?')})}</li>
                          <li className="bullet-item">{t("rooster.defense_detail", {slots: (nftItem.defense.length ? nftItem.defense.join(', ') : '?')})}</li>
                          {nftItem.type == 'ROOSTER' && 
                          <li className="bullet-item">{t("rooster.current_stamina", {stamina: nftItem.stamina + '%'})}</li>
                          }
                          {(nftItem.type == 'SKILL') && <li className="bullet-item">{t("rooster.current_level", {level: nftItem.armors.length ? parseInt(nftItem.armors[0]) + 0 : 0})} {(nftItem.armors.length && nftItem.armors[0] == 1) ? <>(&#9733;)</> : <></>}</li>}
                          {nftItem.type == 'SKILL' &&
                            <li className='bullet-item'>{t("rooster.reroll_count", {count: nftItem.rerollCount})}</li>
                          }
                        </ul>
                      </div>
                      
                      :
                      (nftItem.type == "GAMEITEM") ? 
                      <>
                      <p className="tab-box-item-paragraph">
                          {t("gameitem.description")}
                      </p>
                      
                      <ul>
                        <li className="bullet-item">{t("gameitem.amount", {amount: nftItem.amount})}</li>
                        <li className="bullet-item">{t("gameitem.effect", {effect: getGameItemEffect(nftItem.gameItemType)})}</li>
                      </ul>
                      </>
                      :
                      <></>
                    }
                  </div>
                  <div className='grid-column'>
                    {
                      (nftItem.type == 'ROOSTER' || nftItem.type == 'SKILL') && 
                    
                        <Radar data={getRadarData()} />
                    }
                  </div>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marketplace-sidebar">
        <div className="sidebar-box">
          <div className="sidebar-box-items">
            {buyButton()}
          </div>
          <p className="sidebar-box-title medium-space">{t("general.itemshare")}</p>
          <div className="sidebar-box-items">
            <div className="social-links small align-left">
              <a className="social-link small facebook" href="#">
                <svg className="social-link-icon icon-facebook">
                  <use xlinkHref="#svg-facebook"></use>
                </svg>
              </a>
              <a className="social-link small twitter" href="#">
                <svg className="social-link-icon icon-twitter">
                  <use xlinkHref="#svg-twitter"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NFTItemDetail;
