import { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import RBContext from '../../RBContext';

function UserStaking(props) {
    const { t, i18n } = useTranslation(); 
    const rbContext = useContext(RBContext);

    let { staking, redeemCallback } = props;
    let [ isLoading, setIsLoading ] = useState(false);

    staking.timestamp = parseInt(staking.timestamp);
    let stakedAt = new Date(staking.timestamp * 1000);
    let redeemAt = new Date((staking.timestamp + (staking.pool.duration * 24 * 60 * 60)) * 1000);

    let amountBN = new web3.utils.BN(staking.amount.toString());
    let _amount = parseFloat(web3.utils.fromWei(amountBN, 'ether')).toLocaleString();

    let interestBN = staking.dailyInterests ? staking.dailyInterests.reduce((total, dailyInterest) => {
        let dailyInterestBN = new web3.utils.BN(dailyInterest);
        return total.add(dailyInterestBN);
    }, new web3.utils.BN(0)) : 0;
    let interest = parseFloat(web3.utils.fromWei(interestBN, 'ether')).toFixed(0).toLocaleString();

    useEffect(() => {
        
    }, [rbContext]);

    if (!rbContext) {
        return;
      }

    let { web3 } = rbContext;


    return (
        <div className="badge-item-stat">
            {
                staking.pool &&
                <>
                    <p className="text-sticker">
                        <svg className="text-sticker-icon icon-plus-small">
                            <use xlinkHref="#svg-plus-small"></use>
                        </svg>
                        {t("staking.percentage_hour", {percentage: parseFloat(parseFloat(staking.pool.apr) / 365 / 24).toFixed(2).toLocaleString()})}
                    </p>
                    <img className="badge-item-stat-image" width="90px" src={staking.pool.label.icon} alt="" />
                    <p className="badge-item-stat-title">{staking.pool.label.title}</p>
                </>
            }

            <div className="badge-item-stat-text table table-quests split-rows">
                <div className="table-body same-color-rows">
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.staking_apr")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{parseFloat(staking.pool.apr).toFixed(2).toLocaleString()}%</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.earn")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{t("staking.rice_nft")}</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.period")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{t("staking.amount", {duration: staking.pool.duration})}</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.amount")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{t("staking.amount_rice", {rice: _amount})}</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.interest_till_now")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{t("staking.amount_rice", {rice: interest})}</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.staked_at")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{stakedAt.toLocaleDateString() + ' ' + stakedAt.toLocaleTimeString()}</p>
                        </div>
                    </div>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <p className="table-title">{t("staking.redeem_at")}</p>
                            </div>
                        </div>
                        <div className="table-column">
                            <p className="table-text align-right">{redeemAt.toLocaleDateString() + ' ' + redeemAt.toLocaleTimeString()}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="badge-item-description">
              Your earnt RICEs are calculated daily at 11:30 UTC
            </p>
            <p className="badge-item-stat-text">
                { ! isLoading && ! staking.isRedeemed &&
                    <button className="button btn-block btn-staking" onClick={(e) => {
                        e.preventDefault();
                         if (redeemAt.getTime() > Date.now()) {
                             alert(t("staking.error_redeem_not_due"));
                         } else {
                            setIsLoading(true);
                            redeemCallback(staking)
                                .then(() => {
                                    alert(t("staking.error_redeem_completed"));
                                })
                                .catch((err) => {
                                    alert(t("staking.error_failed_to_redeem"));
                                    console.log(err);
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                })
                         }
                    }}>{ redeemAt.getTime() > Date.now() ? t("staking.locked_until_due_date") : t("staking.button_redeem")}</button>
                }
                { ! isLoading && staking.isRedeemed && <button className="button btn-block btn-staking">{t("staking.redeemed")}</button> }
                { isLoading && <button className="button loading">{t("general.processing")}</button> }
            </p>
        </div>
    );
}

export default UserStaking;
