import React, { useState, useEffect, useContext } from 'react';
import LoadingIcon from './../LoadingIcon';
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Routes,
  Route, useParams, useMatch } from "react-router-dom";
import RBContext from './../../RBContext';
import RewardClaim from '../profile/RewardClaim';

const _calculateAward = (battleInfo, userAddress, roosterTypes) => {
  userAddress = userAddress.toLowerCase();
  let award = '';
  const battleLevel = battleInfo.get('BattleLevel');
  const rooster2Win = battleInfo.get('Rooster2Win');
  const player1 = battleInfo.get('Rooster1Owner');
  const player2 = battleInfo.get('Rooster2Owner');
  const rooster1 = battleInfo.get('Rooster1TokenId');
  const rooster2 = battleInfo.get('Rooster2TokenId');
  let winner = 0;
  let opponent = '0x0';
  let rooster = '';
  var myRooster = '';
  var riceReward = 0;
  var itemsReward = 0;
  /*
  console.log('Current: ' + userAddress);
  console.log('Player 1: ' + player1);
  console.log('Player 2: ' + player2);
  */
  if(player1 == userAddress){
    // you are player 1
    opponent = player2;
    rooster = rooster2;
    myRooster = rooster1;
    if(rooster2Win){
      // Player 2 win, so you lose
      winner = 0;
    } else {
      // Player 1 win, so you win
      winner = 1;
    }
  } else {
    // you are player 2
    opponent = player1;
    rooster = rooster1;
    myRooster = rooster2;
    if(rooster2Win){
      // Player 2 win, so you win
      winner = 1;
    } else {
      // Player 1 win, so you lose
      winner = 0;
    }
  }
  
  var rewardx2 = 1;
  for(var j = 0; j < roosterTypes.length; j++){
    if(roosterTypes[j].id == myRooster){
      
      if(parseInt(roosterTypes[j].type) > 6){
        rewardx2 = 2;
      }
      break;
    }
  }

  if(battleLevel == 1){
    if(winner){
      riceReward += 10 * rewardx2;
      itemsReward += 3;      
    } else {
      riceReward += 3 * rewardx2;
    }
  } else if(battleLevel == 2){
    if(winner){
      riceReward += 10 * rewardx2;
      itemsReward += 3;
    } else {
      riceReward += 3 * rewardx2;
    }
  }

  return [riceReward, itemsReward];
}

const TableRow = (invalidBattles, battleInfo, roosterTypes, user, lastClaimed, index) => {
  if(user){
    //setBattleInfo({Rooster1TokenId: 1, BattleTimestamp: 123412341234, Result: 'WIN', BattleLevel: 2, Opponent: '0x00000000000000000'});
    user = user.toLowerCase();
    if(battleInfo){
      let award = '';
      const battleLevel = battleInfo.get('BattleLevel');
      const rooster2Win = battleInfo.get('Rooster2Win');
      const player1 = battleInfo.get('Rooster1Owner');
      const player2 = battleInfo.get('Rooster2Owner');
      const rooster1 = battleInfo.get('Rooster1TokenId');
      const rooster2 = battleInfo.get('Rooster2TokenId');
      let winner = 0;
      let opponent = '0x0';
      let rooster = '';
      var myRooster = '';
      /*
      console.log('Current: ' + userAddress);
      console.log('Player 1: ' + player1);
      console.log('Player 2: ' + player2);
      */
      if(player1 == user){
        // you are player 1
        opponent = player2;
        rooster = rooster2;
        myRooster = rooster1;
        if(rooster2Win){
          // Player 2 win, so you lose
          winner = 0;
        } else {
          // Player 1 win, so you win
          winner = 1;
        }
      } else {
        // you are player 2
        opponent = player1;
        rooster = rooster1;
        myRooster = rooster2;
        if(rooster2Win){
          // Player 2 win, so you win
          winner = 1;
        } else {
          // Player 1 win, so you lose
          winner = 0;
        }
      }
      
      var rewardx2 = ' ';
      for(var j = 0; j < roosterTypes.length; j++){
        if(roosterTypes[j].id == myRooster){
          
          if(parseInt(roosterTypes[j].type) > 6){
            rewardx2 = ' (x2 for Rare Type) ';
          }
          break;
        }
      }

      var defaultRiceWinReward = 10;
      var defaultRiceLoseReward = 3;
      var battleTimestamp = battleInfo.get("BattleTimestamp");
      var itemReward = '& 3 GAME ITEMS';
      var tokenReward = ' RICEs';
      if(battleTimestamp > 1642377600000){
        // 2022-01-17 00:00:00
        defaultRiceWinReward = 8;
        defaultRiceLoseReward = 2;
        //itemReward = '';
      }

      if(battleTimestamp > 1643241600000){
        // 2022-01-27 0:0:0
        defaultRiceWinReward = 6;
        defaultRiceLoseReward = 3;
        itemReward = '';
        tokenReward = ' GOLDs';
      }

      if(battleLevel == 1){
        if(winner){
          award = defaultRiceWinReward + tokenReward + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + tokenReward + rewardx2;
        }
      } else if(battleLevel == 2){
        defaultRiceWinReward = 9;
        defaultRiceLoseReward = 1;

        if(winner){
          award = defaultRiceWinReward + tokenReward + rewardx2 + itemReward;
        } else {
          award = defaultRiceLoseReward + tokenReward + rewardx2;
        }
      }

      // Create a new JavaScript Date object based on the timestamp
      var date = new Date(battleTimestamp);

      // Will display time in 10:30:23 format
      var formattedTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();
      var className = "table-row medium";
      var text = "";

      if(lastClaimed > 0){
        if(index <= (200 + invalidBattles.length)){
          className += " claimable";
          text = "Verified";
        }
      } else {
        if(index <= (20 + invalidBattles.length)){
          className += " claimable";
          text = "Verified";
        }
      }

      if(invalidBattles.includes(battleInfo.id)){
        text = "Invalid";
      }
      
      return (
      <div className="table-row medium" key={battleInfo.id}>
        <div className="table-column">
          <span className="mobile-title">Index: </span>{index}
        </div>
        <div className="table-column">
          <div className="discussion-preview">
            <p>Fight with <a href="#">{rooster}</a> on <span> {formattedTime} </span></p>

            <div className="discussion-preview-meta">
              <p className="discussion-preview-meta-text">Opponent: </p>
              <p className="discussion-preview-meta-text"><a href="#">{opponent}</a></p>
              
            </div>
            <p className="discussion-preview-meta-text">Battle Id: {battleInfo.id}</p>
            <p className="discussion-preview-meta-text">Player Rooster Id: {myRooster}</p>
          </div>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Battle Level: </span>{battleLevel == 1 ? 'LIGHTWEIGHT' : 'ARMORED'}</p>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Status: </span>{winner ? 'WIN' : 'LOSE'}</p>
        </div>
        <div className="table-column centered padded-medium">
          <p className="table-title"><span className="mobile-title">Award: </span>{award}</p>
        </div>
        <div className="table-column padded-big-left">
          {text}
        </div>
      </div>);
    }
  }
}

const GetTableCSV = (battles, roosterTypes, user, lastClaimed) => {
  var text = "Date,Opponent Rooster Id,My Rooster Id,Winner,RGOLDs,\n";
  if(user){
    //setBattleInfo({Rooster1TokenId: 1, BattleTimestamp: 123412341234, Result: 'WIN', BattleLevel: 2, Opponent: '0x00000000000000000'});
    user = user.toLowerCase();
    for(var i = 0; i < battles.length; i++){
      var battleInfo = battles[i];

      if(battleInfo){
        let award = '';
        const battleLevel = battleInfo.get('BattleLevel');
        const rooster2Win = battleInfo.get('Rooster2Win');
        const player1 = battleInfo.get('Rooster1Owner');
        const player2 = battleInfo.get('Rooster2Owner');
        const rooster1 = battleInfo.get('Rooster1TokenId');
        const rooster2 = battleInfo.get('Rooster2TokenId');
        let winner = 0;
        let opponent = '0x0';
        let rooster = '';
        var myRooster = '';
        /*
        console.log('Current: ' + userAddress);
        console.log('Player 1: ' + player1);
        console.log('Player 2: ' + player2);
        */
        if(player1 == user){
          // you are player 1
          opponent = player2;
          rooster = rooster2;
          myRooster = rooster1;
          if(rooster2Win){
            // Player 2 win, so you lose
            winner = 0;
          } else {
            // Player 1 win, so you win
            winner = 1;
          }
        } else {
          // you are player 2
          opponent = player1;
          rooster = rooster1;
          myRooster = rooster2;
          if(rooster2Win){
            // Player 2 win, so you win
            winner = 1;
          } else {
            // Player 1 win, so you lose
            winner = 0;
          }
        }
        
        var rewardx2 = 1;
        for(var j = 0; j < roosterTypes.length; j++){
          if(roosterTypes[j].id == myRooster){
            
            if(parseInt(roosterTypes[j].type) > 6){
              rewardx2 = 2;
            }
            break;
          }
        }
  
        var defaultRiceWinReward = 10;
        var defaultRiceLoseReward = 3;
        var battleTimestamp = battleInfo.get("BattleTimestamp");
        var itemReward = '& 3 GAME ITEMS';
        var tokenReward = ' RICEs';
        if(battleTimestamp > 1642377600000){
          // 2022-01-17 00:00:00
          defaultRiceWinReward = 8;
          defaultRiceLoseReward = 2;
          //itemReward = '';
        }
  
        if(battleTimestamp > 1643241600000){
          // 2022-01-27 0:0:0
          defaultRiceWinReward = 6;
          defaultRiceLoseReward = 3;
          itemReward = '';
          tokenReward = ' GOLDs';
        }
  
        if(battleLevel == 1){
          if(winner){
            award = defaultRiceWinReward * rewardx2;
          } else {
            award = defaultRiceLoseReward * rewardx2;
          }
        } else if(battleLevel == 2){
          defaultRiceWinReward = 9;
          defaultRiceLoseReward = 1;
  
          if(winner){
            award = defaultRiceWinReward * rewardx2;
          } else {
            award = defaultRiceLoseReward * rewardx2;
          }
        }
  
        // Create a new JavaScript Date object based on the timestamp
        var date = new Date(battleTimestamp);
  
        // Will display time in 10:30:23 format
        var formattedTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();
        
        text += formattedTime+","+rooster+","+myRooster+","+winner+","+award+"\n";
      }
    }
    
  }
  
  return text;
}

function getTax(timestamp){
  console.log("Last claimed: " + timestamp);
  var period = Date.now() / 1000 - timestamp;
  console.log("Minutes ago: " + (period / 60));
  var fee = -1;
  if(period <= 3 * 86400){
    fee = 70;
  } else if(period <= 4 * 86400){
    fee = 60;
  } else if(period <= 5 * 86400){
    fee = 50;
  } else if(period <= 6 * 86400){
    fee = 40;
  } else if(period <= 7 * 86400){
    fee = 30;
  } else if(period <= 9 * 86400){
    fee = 20;
  } else if(period <= 9 * 86400){
    fee = 10;
  } else if(period <= 10 * 86400) {
    fee = -1;
  } else {
    fee = 3;
  }

  return fee;
}

function ClaimBattles(props){
  const rbContext = useContext(RBContext);

  let [battles, setBattles] = useState([]);

  let match = useMatch();

  let [loading, setLoading] = useState(true);
  let [processing, setProcessing] = useState(false);
  const { Moralis, isInitialized, isAuthenticated, authenticate, logout, user } = useMoralis();
  let [lastClaimed, setLastClaimed] = useState(false);
  let [tax, setTax] = useState(0);
  let [rareTypes, setRareTypes] = useState([]);
  let [userWallet, setUserWallet] = useState('');
  let [userLimit, setUserLimit] = useState(1000);
  let [totalVerified, setTotalVerified] = useState(0);
  let [contractClaimable, setContractClaimable] = useState(false);
  let [claimableGOLD, setClaimableGOLD] = useState(0);

  // marketplace
  const getUnClaimedBattles = async () => {
      var wallet = userWallet.toLowerCase();
      if(wallet){
      try {
        setLoading(true);
        if(!userLimit) userLimit = 1000;
          var lastClaimed = await RBGOLDREWARD_CONTRACT.methods.LastClaimed(wallet).call();
          
          setLastClaimed(parseInt(lastClaimed));
          tax = getTax(parseInt(lastClaimed));
          setTax(tax);

          var goldClaim = await RBGOLDREWARD_CONTRACT.methods.UserRewards(wallet).call();

          setClaimableGOLD(goldClaim);

          var results = await Moralis.Cloud.run('admin_getUnClaimedBattles', {sender: wallet, limit: userLimit});
          console.log(results);
          if(results){
            setTotalVerified(results.unverifiedGold);

            contractClaimable = await RBRewardContract.methods.UserRewards(wallet).call();
            setContractClaimable(contractClaimable);

            // verify battles
            var allBattles = [];
            var roosterTypes = [];
            for(var i = 0; i < results.battles.length; i++){
              var obj = {};
              obj.battleId = results.battles[i].id;

              var rareType = 0;
              var roosterId = results.battles[i].get('Rooster1TokenId');
              obj.isOwner1 = true;
              if(results.battles[i].get('Rooster2Owner') == wallet){
                roosterId = results.battles[i].get('Rooster2TokenId');
                obj.isOwner1 = false;
              }

              allBattles.push(obj);

              var foundRooster = false;
              for(var j = 0; j < roosterTypes.length; j++){
                if(roosterTypes[j].id == roosterId){
                  foundRooster = true;
                  break;
                }
              }

              if(!foundRooster){
                rareType = await nftContract.methods.getRBType(roosterId).call();
                roosterTypes.push({id: roosterId, type: rareType});
              }
              
            }

            console.log(roosterTypes);

            setRareTypes(roosterTypes);
            
            setBattles(results.battles);
          }
            
          setLoading(false);
      } catch (error) {
          const code = error.code;
          const message = error.message;
          console.log(code + ':' + message);
          setLoading(false);
      }
    }
  }

  useEffect( () => {
      if(user){
        setLoading(false);
      }
    }, [user, rbContext]
  );

  if (!rbContext) {
      return null;
  }

  let { web3, nftContract, RBRewardContract, RBGOLDREWARD_CONTRACT } = rbContext;

  return(
    <>
      <p>
      <input id="userWallet" placeholder='Enter user wallet' type="text" onChange={event => setUserWallet(event.target.value)}/>
      <input id="userLimit" placeholder='Number' type="number" onChange={event => setUserLimit(parseInt(event.target.value))}/>
      {loading &&
      <button className="button btn-block" >Loading...</button>
      }
      {!loading &&
      <button className="button btn-block" onClick={getUnClaimedBattles}>Get User Unclaimed Battles</button>
      }
      </p>
      <h3 id="claims-total-heading">GOLD claimable: {claimableGOLD ? claimableGOLD - 1 : 0} RGOLDs</h3>
      <p className="battleclaimed-info">{lastClaimed > 0 ? "Last claimed time is " + new Date(lastClaimed * 1000).toLocaleDateString("en-US") + " " + new Date(lastClaimed * 1000).toLocaleTimeString("en-US") + (". Tax is applied at " + tax + "% today.") : 'This is the first claim. You can only claim the first 200 RGOLDs'}. Your claimed rewards are under tax as defined <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">here</a></p>
      
      <h3 id="claims-total-heading">After tax: GOLDs {claimableGOLD - (claimableGOLD * tax / 100)}</h3>
      <h3 id="claims-total-heading">Total Verified: {totalVerified} RGOLDs</h3>
      <h3 id="claims-total-heading">RICE claimable: {contractClaimable.availableRice} RICEs - {contractClaimable.availableItem} ITEMs</h3>
      {battles.length > 0 && !processing &&
      <div className="centered paragraph">
        {tax == -1 && 
        <p className="battleclaimed-info">Cool down day! (ie. You cannot claim reward today)</p>}
        {tax != -1 &&
        <button className="button primary">Can be claimed. Please use the "Reset Reward" if necessary</button>
        }
        <p className="battleclaimed-info">{lastClaimed > 0 ? "Your last claimed time is " + new Date(lastClaimed * 1000).toLocaleDateString("en-US") + (". Tax is applied at " + tax + "% if you claim today. You need to claim minimum 200 RGOLDs one time") : 'This is your first claim. You can only claim the first 200 RGOLDs'}. Your claimed rewards are under tax as defined <a target="_blank" href="https://roosterbattle.substack.com/p/claim-rewards-mechanism-and-marketplace">here</a></p>
      </div>
      }
      <div className="table table-battles">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">Index</p>
          </div>
          <div className="table-header-column">
            <p className="table-header-title">Battle Info</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">LEVEL</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">RESULT</p>
          </div>
          <div className="table-header-column centered padded-medium">
            <p className="table-header-title">AWARDS</p>
          </div>
          <div className="table-header-column padded-big-left">
            <p className="table-header-title">STATUS</p>
          </div>
        </div>
        <div className="table-body">
          {battles.length > 0 ?
            battles.map((item, index) => TableRow([], item, rareTypes, userWallet, lastClaimed, index + 1)) : ''
          }
        </div>
    </div>
    <textarea value={GetTableCSV(battles, rareTypes, userWallet, lastClaimed)}/>
    
    {battles.length > 0 && processing &&
    <div className="centered paragraph">
      <button className="button primary">processing...</button>
    </div>
    }
    {
      loading &&

      <div className="centered paragraph white no-battles">checking for unclaimed battles...</div>
    }
    {
      !loading && battles.length == 0 &&

      <div className="centered paragraph white no-battles">NO BATTLES SO FAR, START FIGHTING NOW!</div>
    }
    </>
  )
}

export default ClaimBattles;