import { useTranslation } from "react-i18next";

function ProfileSidebar(props){
  const { t, i18n } = useTranslation();  
  
    return (
        <div className="sidebar-box no-padding">
              <div className="sidebar-menu round-borders">
                <div className="sidebar-menu-item">
                  <div className='sidebar-menu-header accordion-trigger-linked'>
                    <svg className='sidebar-menu-header-icon icon-profile'>
                      <use xlinkHref="#svg-profile"></use>
                    </svg>
                    <p className="sidebar-menu-header-title"><a href="/profile/settings">{t("profile.profile_settings")}</a></p>
                    <p className="sidebar-menu-header-text">{t("profile.profile_settings_sub")}</p>
                  </div>
                </div>
                <div className="sidebar-menu-item">
                  <div className='sidebar-menu-header accordion-trigger-linked'>
                    <svg className='sidebar-menu-header-icon icon-settings'>
                      <use xlinkHref="#svg-settings"></use>
                    </svg>
                    <p className="sidebar-menu-header-title"><a href="/profile/game">{t("profile.game_management")}</a></p>
                    <p className="sidebar-menu-header-text">{t("profile.game_management_sub")}</p>
                  </div>
                </div>
              </div>
            </div>
    );
}

export default ProfileSidebar;