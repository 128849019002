import React, { useState, useEffect, useContext } from 'react';
import NFTListingItem from './NFTListingItem';
import { useMoralis } from "react-moralis";
import { useLocation } from "react-router-dom";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function NFTListing(props){
  let query = useQuery();
  let [offers, updateOffers] = useState([]);
  let [sort, setSort] = useState('latest');
  let [filter, setFilter] = useState('');
  const rbContext = useContext(RBContext);
  let [loading, setLoading] = useState(false);
  const { user, Moralis, isInitialized } = useMoralis();
  let [currentIndex, setCurrentIndex] = useState(0);
  let [allLoaded, setAllLoaded] = useState(false);
  let [stats, setStats] = useState(false);
  const itemsPerPage = 15;
  const { t, i18n } = useTranslation();

  // marketplace
  const getMarketOffers = async (skip, filter = '') => {
    setLoading(true);

    const params =  {
      skip: skip,
      sort: sort,
      filter: filter,
      lang: i18n.language
    };

    try {
        const results = await Moralis.Cloud.run(process.env.REACT_APP_MORALIS_CLOUD_GETMARKETOFFERS, params);
        if(skip > 0){
          if(results.length > 0){
            updateOffers(offers.concat(results));
          }         
        } else{
          updateOffers(results);
        }
        
        setCurrentIndex(skip);

        if(results.length < itemsPerPage){
          setAllLoaded(true);
        }
        
        setLoading(false);
    } catch (error) {
        const code = error.code;
        const message = error.message;
        console.log(code + ':' + message);
        setLoading(false);
    }
  }

  const getMarketStats = async()=>{
    try{
      var stats = await Moralis.Cloud.run('getMarketStats', {lang: i18n.language});
      
      var totalSold = 0;
      var totalValue = 0;
      for(var i = 0; i < stats.sold.length; i++){
        totalSold += stats.sold[i].totalOffer;
        totalValue += stats.sold[i].totalValue;
      }

      var currentOffer = 0;
      
      for(var i = 0; i < stats.current.length; i++){
        currentOffer += stats.current[i].totalOffer;
      }

      setStats({totalSold, totalValue, currentOffer, stats});
    } catch(err){
      console.log(err);
    }
  }

  const filterItems = async(event) => {
    var filter = event.target.value;
    setFilter(filter);
    getMarketOffers(0, filter);
  }

  const sortItems = async (event)=>{
    sort = event.target.value;
    setSort(sort);
    getMarketOffers(0, filter);
  }

  const loadmoreOffers = async (evt)=>{
    evt.stopPropagation();
    evt.preventDefault();
    getMarketOffers(currentIndex + itemsPerPage, filter);
  }

  useEffect(()=>{
    async function getData(){
      getMarketOffers(0);
      getMarketStats();
    }
    
    getData();
    }, [props, Moralis, isInitialized, rbContext] // make sure useEffect run only once, we need this empty parameter
  );

  if (!rbContext) {
      return null;
  }

  return(
    <>

      <div className="section-filters-bar v4">
        <div className='section-filters-bar-actions'>
          <div className='form'>
            <div className='form-item split medium'>
              <div className='form-select small'>
                <label>{t("marketplace.filter_show")}</label>
                <select name="filter" onChange={filterItems} value={filter}>
                  <option value="">{t("marketplace.filter_all")}</option>
                  <option value="rooster">{t("marketplace.filter_roosters")}</option>
                  <option value="skill">{t("marketplace.filter_skills")}</option>
                  <option value="hen">{t("marketplace.filter_hens")}</option>
                  <option value="gameitem">{t("marketplace.filter_gameitem")}</option>
                  <option value="egg">{t("marketplace.filter_eggs")}</option>
                </select> 
                <svg className="form-select-icon icon-small-arrow">
                  <use xlinkHref="#svg-small-arrow"></use>
                </svg>
              </div>
              <div className='form-select small'>
                <label>{t("marketplace.sortby")}</label>
                <select name="order" onChange={sortItems} value={sort}>
                  <option value="latest">{t("marketplace.sortby_latest")}</option>
                  <option value="oldest">{t("marketplace.sortby_oldest")}</option>
                  <option value="cheapest">{t("marketplace.sortby_cheapest")}</option>
                  <option value="expensive">{t("marketplace.sortby_expensive")}</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use xlinkHref="#svg-small-arrow"></use>
                </svg>
              </div>
              {loading && 
              <div className='form-select small'>
                <div className="page-loader-indicator loader-bars">
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                  <div className="loader-bar"></div>
                </div>
              </div>

              }
            </div>
          </div>
          
        </div>
      </div>
      <div className='grid grid-3-9 small-space'>
        <div className='marketplace-sidebar'>
              <div className='sidebar-box'>
                <p className='sidebar-box-title'>{t("marketplace.marketstats")}</p>
                {stats ? 
                <div className='sidebar-box-items'>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-big-arrow">
                        <use xlinkHref="#svg-big-arrow"></use>
                      </svg>
                      <label>{t("marketplace.totaloffers")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.currentOffer.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-big-arrow">
                        <use xlinkHref="#svg-big-arrow"></use>
                      </svg>
                      <label>{t("general.nft_roosters")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.stats.current[0].totalOffer.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-big-arrow">
                        <use xlinkHref="#svg-big-arrow"></use>
                      </svg>
                      <label>{t("general.nft_eggs")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.stats.current[3].totalOffer.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-big-arrow">
                        <use xlinkHref="#svg-big-arrow"></use>
                      </svg>
                      <label>{t("general.nft_skills")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.stats.current[2].totalOffer.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-big-arrow">
                        <use xlinkHref="#svg-big-arrow"></use>
                      </svg>
                      <label>{t("general.nft_hens")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.stats.current[4].totalOffer.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-check">
                        <use xlinkHref="#svg-check"></use>
                      </svg>
                      <label>{t("marketplace.sold")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.number_nfts", {count: stats.totalSold.toLocaleString()})}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                      <svg className="icon icon-earnings">
                        <use xlinkHref="#svg-earnings"></use>
                      </svg>
                      <label>{t("marketplace.value")}</label>
                    </div>
                    <p className="checkbox-line-text">{t("marketplace.price_rices", {price: stats.totalValue.toLocaleString()})}</p>
                  </div>
                </div>
                :<>...</>}

                <p className='sidebar-box-title'>{t("marketplace.recentsales")}</p>
                {stats ? 
                <div className='sidebar-box-items'>
                  {stats.stats.recent.map((sale, index) => {
                    return (
                      <div key={"sale-" + index} className='checkbox-line'>
                        <div className='checkbox-wrap'>
                        <svg className="icon icon-check">
                        <use xlinkHref="#svg-check"></use>
                      </svg>
                          <label>{
                          
                          sale.type == 1 && t("general.nft_rooster")
                          
                          }
                          {
                          
                          sale.type == 2 && t("general.nft_gameitem")
                          
                          }
                          {
                          
                          sale.type == 3 && t("general.nft_skill")
                          
                          }
                          {
                          
                          sale.type == 4 && t("general.nft_egg")
                          
                          }
                          {
                          
                          sale.type == 5 && t("general.nft_hen")
                          
                          }
                          &nbsp; #{sale.id}<br/>{t("marketplace.hoursago", {hours: sale.ago})}</label>
                        </div>
                        <p className="checkbox-line-text">{t("marketplace.price_rices", {price: sale.price})}</p>
                      </div>)
                  })}
                    
                  </div>
                  :<>...</>
                }
                <p className='sidebar-box-title'>{t("marketplace.global_rooster_stats")}</p>
                {stats ? 
                <div className='sidebar-box-items'>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                        <svg className="icon icon-check">
                        <use xlinkHref="#svg-check"></use>
                      </svg>
                          <label>{t("marketplace.total_roosters")}</label>
                    </div>
                    <p className="checkbox-line-text">{stats.stats.nft.totalRoosters.toLocaleString()}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                        <svg className="icon icon-check">
                        <use xlinkHref="#svg-check"></use>
                      </svg>
                          <label>{t("marketplace.armored_roosters")}</label>
                         
                    </div>
                    <p className="checkbox-line-text">{stats.stats.nft.armors.toLocaleString()}</p>
                  </div>
                  <div className='checkbox-line'>
                    <div className='checkbox-wrap'>
                        <svg className="icon icon-check">
                        <use xlinkHref="#svg-check"></use>
                      </svg>
                          <label>{t("marketplace.total_battles")}</label>
                         
                    </div>
                    <p className="checkbox-line-text">{stats.stats.nft.totalBattles.toLocaleString()}</p>
                  </div>
                  </div>
                  :<>...</>
                }
              </div>
        </div>
        <div className='marketplace-content'>
          <div className="grid grid-3-3-3 centered">
          {offers.length > 0 ? offers.map((item, index) => (
                <NFTListingItem key={"NFT-" + index} token={item} onRemoved={getMarketOffers}/>
              )) : <></>
          }
          {loading && 
            <div className="centered white comingsoon">
              {t("general.loading")}
            </div>
          }
        </div>
        { !allLoaded && 
        <div id="btnLoadMore" className="block centered">
          {
            !loading &&
            <a href="#" className="button btn-primary" onClick={loadmoreOffers}>{t("general.loadmore")}</a>
          }
          {
            loading &&
            <a href="#" className="button btn-primary loading">{t("general.loading")}</a>
          }    
        </div>
        }
        </div>
      </div>
    </>
  );
}

export default NFTListing;
