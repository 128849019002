import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from "react-moralis";
import RBContext from '../../RBContext';
import { useTranslation } from "react-i18next";
import { showError, toLocalDateTime } from './../../utils/RBUtils.js';

function UserStakes8(props) {
  let [isLoading, setIsLoading] = useState(false);
  
  let [stakes, setStakes] = useState([]);

  const { Moralis, user } = useMoralis();
  
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation(); 


  useEffect( () => {
    async function initData(){
      if(user){
        setStakes(props.stakes);
      }
    }
    initData();
  }, [user, props, rbContext])

  const redeem = async (index, henId, roosterId = 0) => {
    if(!isLoading){
      try{
        setIsLoading(true);
        var result = await RBPool8Contract.methods.redeem(henId).send({from: user.get("ethAddress")});

        if(roosterId > 0){
          var sync = await Moralis.Cloud.run('syncRoosterType', {roosterId: roosterId,lang: i18n.language});
          
        }
        alert(t("staking.error_redeem_completed"));
        setIsLoading(false);
        stakes.splice(index, 1);
        setStakes(stakes);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  const redeemRice = async (index, henId) => {
    if(!isLoading){
      try{
        setIsLoading(true);
        var result = await RBPool8Contract.methods.redeemRice(henId).send({from: user.get("ethAddress")});

        alert(t("staking.error_redeem_completed"));
        stakes[index].redeemCount += 1;
        setStakes(stakes);
        setIsLoading(false);
      } catch(err){
        showError(err);
        setIsLoading(false);
      }
    }
  }

  if (!rbContext) {
    return;
  }


  let { RBPool8Contract } = rbContext;

  return (
    <>
      {stakes.map((stake, index)=> {
        return (
          <div className="badge-item-stat" key={"Stake8-" + index}>
            <p className="text-sticker">
                <svg className="text-sticker-icon icon-plus-small">
                  <use xlinkHref="#svg-plus-small"></use>
                </svg>
                {stake.reward == 3 && 
                  t("general.epic_rooster")
                }
                {stake.reward == 2 && 
                  t("staking.amount_rice", {rice: 15000})
                }
                {stake.reward == 1 && 
                    t("staking.amount_rice", {rice: 5000})
                }
            </p>
            <p className="badge-item-stat-title">{t("staking.pool_8_you_are_staking_hen", {hen: stake.henId})}</p>
            {stake.reward == 3 && 
                  <p className="badge-item-stat-title">{t("staking.pool_8_and_rooster_", {id: stake.roosterId})}</p>
                }
            {stake.reward == 2 && 
                <p className="badge-item-stat-title">{t("staking.pool_8_and_5000_rices")}</p>
              }
            <div className="badge-item-stat-text table table-quests split-rows">
              <div className="table-body same-color-rows">
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.earn")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {stake.reward == 3 && 
                      t("general.epic_rooster")
                    }
                    {stake.reward == 2 && 
                      t("staking.amount_rice", {rice: 15000})
                    }
                    {stake.reward == 1 && 
                        t("staking.amount_rice", {rice: 5000})
                    }
                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.period")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {stake.reward == 3 && 
                        t("staking.period_days", {period_days: 15})
                      }
                      {stake.reward == 2 && 
                        t("staking.period_days", {period_days: 45})
                      }
                      {stake.reward == 1 && 
                        t("staking.period_days", {period_days: 45})
                      }

                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.staked_at")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {toLocalDateTime(stake.timestamp)}

                    </p>
                  </div>
                </div>
                <div className="table-row small">
                  <div className="table-column">
                    <div className="table-information">
                      <p className="table-title">{t("staking.redeem_at")}</p>
                    </div>
                  </div>
                  <div className="table-column">
                    <p className="table-text align-right">
                    {toLocalDateTime(stake.redeemAt)}

                    </p>
                  </div>
                </div>
              </div>
            </div>
            {
            <p className="badge-item-stat-text">

              { (stake.redeemAt <= Date.now()) && <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); redeem(index, stake.henId, stake.roosterId);}}>{!isLoading? t("staking.button_redeem") : t("general.loading")}</button> }
              { (stake.redeemAt > Date.now()) &&
                <>
                  {stake.riceRedeemAt > 0 &&
                   <>
                    <button className="button" onClick={(evt)=>{evt.stopPropagation(); evt.preventDefault(); redeemRice(index, stake.henId);}}>{!isLoading? t("staking.redeem_rices", {rice: stake.riceRedeemAmount}) : t("general.loading")}</button>
                   </>
                  }
                  {
                    (stake.reward == 1 || stake.reward == 2) && stake.riceRedeemAt == 0 &&
                    <>
                      {
                        (Date.now() < stake.timestamp + 15 * 86400000) ?
                        <>
                          {t("staking.pool_8_rice_claimable_at", {rice: stake.reward == 1 ? "1500" : "3000", time: toLocalDateTime(stake.timestamp + 15 * 86400000)})}
                        </> : 
                        ((Date.now() < stake.timestamp + 45 * 86400000) ?
                        <>
                          {t("staking.pool_8_rice_claimable_at", {rice: stake.reward == 1 ? (stake.redeemCount == 1 ? "1500" : "3000") : (stake.redeemCount == 1 ? "3000" : "6000"), time: toLocalDateTime(stake.timestamp + 30 * 86400000)})}
                        </>
                        : <></>)
                      }
                      
                    </>
                  }
                </>
              }
            </p>
            }
          </div>
        );
      })}
      
    </>
  );
}
export default UserStakes8;
