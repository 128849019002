import React, {useEffect, useContext, useState} from 'react';
import { useMoralis } from "react-moralis";
import RBContext from './../../RBContext';
import { useTranslation } from "react-i18next";

function NFTFood(props){
  const rbContext = useContext(RBContext);
  const { t, i18n } = useTranslation();

  let [loading, setLoading] = useState(true);
  let [saleStatus, setSaleStatus] = useState(0); // 0: closed; 1: active; 2: soldout today; 3: max bought; 4: not valid for presale
  let [discountPrice, setDiscountPrice] = useState(35);
  let [price, setPrice] = useState(35);
  let [pack, setPack] = useState(50);

  const { user } = useMoralis();

  const checkForDiscount = async () => {
    if(user){
      let status = await minterContract.methods.FoodOpen().call();
      if(status){
        let price = await minterContract.methods.FoodPrice().call();
        let pack = await minterContract.methods.FoodPack().call();

        price = price * props.count;

        setPrice(price);
        setDiscountPrice(price);
        setSaleStatus(status);
        setPack(pack);
      } else {
        setSaleStatus(0);
      }

      setLoading(false);
    }
  }

  const buyNow = async () => {
    setLoading(true);
    const currentUser = user.get('ethAddress');
    try{

      const allowance = await riceContract.methods.allowance(currentUser, process.env.REACT_APP_RB_MINTERV4_ADDRESS).call();

      const balanceOfUser = await riceContract.methods.balanceOf(currentUser).call();

      let okToGo = false;

      if(allowance == 0 || (parseInt(allowance.substring(0, allowance.length - 18)) < discountPrice)) {
        okToGo = false;

        alert(t("error.rice_approval"));

        var result = await riceContract.methods.approve(process.env.REACT_APP_RB_MINTERV4_ADDRESS, new web3.utils.BN(web3.utils.toWei((discountPrice * 10) + ""))).send({from: currentUser});

        if(result.status){
          okToGo = true;
        }
      } else {
        okToGo = true;
      }

      if(okToGo){
        if(balanceOfUser > 0 && parseInt(balanceOfUser.substring(0, balanceOfUser.length - 18)) > discountPrice){
          
          await minterContract.methods.buyFood(1,props.count).send({from: currentUser});
          alert(t("error.offer_bought"));
        } else {
          alert(t("error.not_enough_rice"));
        }
      }
      setLoading(false);
      checkForDiscount();
    } catch(err){
      alert(err.message);
      setLoading(false);
      checkForDiscount();
      return false;
    }

  }

  const buyButton = () => {
    if(!user){
      return <><a className="button loading btn-block btn-buyPresale centered" href="#">{t("error.please_connect_wallet")}</a></>;
    }
    if(loading){
      return (<a className="button loading btn-block btn-buyPresale centered" href="#">{t("general.processing")}</a>);
    }

    if(saleStatus == 0){
      return (<span className="info-tex centered">{t("marketplace.out_of_stock")}</span>);
    }

    return (<a className="button btn-block btn-buyPresale" href="#" onClick={(e) => {e.stopPropagation();e.preventDefault();buyNow();}}>{t("general.button_purchase")}</a>);
  }

  useEffect( () => {
    async function fetchData(){
      checkForDiscount();
    }

    fetchData();    
  }, [rbContext, user]);

  if (!rbContext) {
      return null;
  }

  let { web3, riceContract, minterContract } = rbContext;

  return (
      <>
        <div className="product-preview">
          <div>
            <img src={process.env.REACT_APP_HOME_URL + "assets/gameitems/gameitem_2.png"} width="186px" alt={t("food.category_title")}/>
          </div>
          <div className="product-preview-info">
            <p className="text-sticker"><span className="highlighted">RICE</span> {discountPrice} {discountPrice < price ? <del>{price}</del> : ""}</p>
            <p className="product-preview-title">{t("food.crickets_title", {count: pack * props.count})}</p>
            <p className="product-preview-category">{t("food.category_title")}</p>
            {discountPrice < price &&
            <p className="product-preview-category">{t("marketplace.you_have_discounted_price")}</p>
            }
            <p className="product-preview-text">{t("food.cricket_description")}</p>
            <p className="product-preview-text">{t("food.ingame_item_only")}</p>
          </div>
          <div className="product-preview-meta">{buyButton()}</div>
        </div>
      </>
    );
}
export default NFTFood;
