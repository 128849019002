import Footer from './components/Footer';
import Header from './components/Header';
import Floaty from './components/Floaty';
import {
    BrowserRouter as Router,
} from "react-router-dom";

function MainError() {
    return <Router>
        <Header />
        <Floaty />
        <div className="content-grid">
            <div className="top-space centered" style={{ margin: "100px 0px" }}>
                <h2 style={{ color: "#fff", margin: ".5em 0" }}>Non ethereum enabled browser</h2>
            </div>
        </div>
        <Footer />
    </Router>
}

export default MainError;