import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Extra from './Extra';
import LeaderboardItems from './components/LeaderBoardItems';
import LeaderboardHistories from './components/LeaderBoardHistories';
function Leaderboard(props) {

  useEffect(()=>{
    Extra.updateContentGridPos();
  }, []);

  return (
      <div className="content-grid">
        <div className="section-banner">
          <p className="section-banner-title">Rooster Battle Heavyweight Championship
 - Tournament 01</p>
          <p className="section-banner-text">The best warriors owner in the battlefield!</p>
        </div>
        <div className="centered">
          
          <Routes>
            <Route path="histories/:wallet" element={<LeaderboardHistories/>} />
            <Route path="*" element={<LeaderboardItems/>} />
          </Routes>          
        </div>
      </div>
    )
}
export default Leaderboard;